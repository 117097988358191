import {reactive, toRefs} from 'vue';
import paymentTypesApiController from '../../../../api/accounts/paymentTypesApiController';

const useDeletePaymentType = () => {
	const state = reactive({
		loading: false,
		error: false,
	});

	const deletePaymentType = async (id) => {
		state.loading = true;

		try {
			const response = await paymentTypesApiController.deletePaymentType(id);
			return response
		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	return {
		...toRefs(state),
		deletePaymentType,
	};
};

export default useDeletePaymentType;
