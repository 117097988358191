import GeneralApiController from './GeneralApiController';

class AuthApiController extends GeneralApiController {
	login = (data) => this.http.post('login', data);

	logout = (data) => this.http.post('logout', data);

  	getUserProfile = () => this.http.get('profile');

  	resetPassword = (data) => this.http.patch('profile', data);
}

const authApiController = new AuthApiController();

export default authApiController;
