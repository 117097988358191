import GeneralApiController from './GeneralApiController';

class ReportsApiController extends GeneralApiController {
	createReport = (data) => this.http.post('reports', data);

	getReports = (query = '') => this.http.get(`reports?${query}`);

	updateReport = (id, data) => this.http.patch(`reports/${id}`, data);

	deleteReport = (id) => this.http.delete(`reports/${id}`);

	getEmployeesReports = (query = '') => this.http.get(`management/reports?${query}`);

	deleteAllReports = (password) => this.http.delete('reports-annihilation', {data: password})

	deletePeriodReports = (data) => this.http.delete('reports-annihilation', {data})
}

const reportsApiController = new ReportsApiController({
	returnOnlyData: false,
});

export default reportsApiController;
