<template>
	<div class="page-layout">
		<Preloader v-if="loading" :transparent="transparentPreloader" />
		<div class="page-layout__head">
			<h1 v-if="title && !isMobile" class="page-layout__title">{{ title }}</h1>
			<div v-if="$slots.controls" class="page-layout__controls">
				<slot name="controls"></slot>
			</div>
		</div>
		<div v-if="desc" class="page-layout__desc">{{ desc }}</div>
		<div v-if="countText" class="page-layout__count">{{ countText }}</div>
		<div class="page-layout__content" :class="{ desktop: onlyDesktop }">
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
import Preloader from "../base/Preloader.vue";
import useWindowResize from '@/hooks/useWindowResize.js';
import { computed } from 'vue';

defineProps({
	title: {
		type: String,
		default: "",
	},
	desc: {
		type: String,
		default: "",
	},
	countText: {
		type: String,
		default: "",
	},
	loading: {
		type: Boolean,
		default: false,
	},
	onlyDesktop: {
		type: Boolean,
		default: false,
	},
	transparentPreloader: {
		type: Boolean,
		default: false,
	},
});


const { width } = useWindowResize();

const isMobile = computed(() => width.value < 1280)
</script>

<style lang="scss" scoped>
.page-layout {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 54px 32px;
	overflow-x: auto;
	@media (max-width: 1279px) {
		padding: 16px 16px;
	}
}

.page-layout__head {
	display: flex;
	align-items: center;
}

.page-layout__controls {
	flex: 1;
	margin-left: 16px;
	font-size: 14px;
	@media (max-width: 1279px) {
		margin-left: 0;
	}
}

.page-layout__desc {
	margin-top: 16px;
}

.page-layout__count {
	margin-top: 16px;
	font-size: 16px;
	color: $primary;
}

.page-layout__content {
	margin-top: 34px;
	flex: 1;
	overflow-x: auto;
	padding-bottom: 80px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	@media screen and (max-width: 600px) {
		margin-top: 16px;
	}
}
.page-layout__content.desktop {
	min-width: 1200px;
	width: auto;
}

.page-layout__desc {
	margin-top: 16px;
}

.page-layout__count {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	margin-top: 16px;
	color: #2d5be3;
}
</style>
