import { ADMIN_ROUTE_NAMES } from '@/constants/route-names';
import EmployeesList from '@/views/admin/employees/views/list/EmloyeesList.vue';
import AddEmployee from '@/views/admin/employees/views/add/AddEmployee.vue';
import EditEmployee from '@/views/admin/employees/views/edit/EditEmployee.vue';
import { USER_ROLES } from '../../constants/userRoles';

// const defaultMeta = {
// 	requiresAuth: true,
// 	userRoles: [USER_ROLES.ADMIN],
// };

/** @type {import('vue-router').RouteRecordRaw[]} */
const employeesRoutes = [
	{
		path: '/employees',
		name: ADMIN_ROUTE_NAMES.EMLOYEES_LIST,
		component: EmployeesList,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Employees'
		}
	},
	{
		path: '/add-employee',
		name: ADMIN_ROUTE_NAMES.EMPLOYEES_NEW,
		component: AddEmployee,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Add new employee'
		}
	},
	{
		path: '/employees/:id',
		name: ADMIN_ROUTE_NAMES.EMLOYEES_EDIT,
		component: EditEmployee,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Edit employee'
		}
	},
];

export default employeesRoutes;
