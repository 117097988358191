<template>
	<PageLayout
		title="Your cloud storage"
		transparent-preloader
		:loading="loading"
	>
		<CloudStorageHeader />
		<div class="content">
			<router-view></router-view>
		</div>
	</PageLayout>
</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout.vue";
import CloudStorageHeader from "./components/CloudStorageHeader.vue";
import useStorageController from "../../hooks/useStorageController";
import { provide, watch } from "vue";
import { STORAGE_CONTROLLER_KEY } from "../../constants/injectionKeys";
import { useRoute } from "vue-router";

const storageController = useStorageController();

const route = useRoute();

watch(
	() => route.path,
	() => {
		storageController.resetSearch();
	}
);

const { loading } = storageController;

provide(STORAGE_CONTROLLER_KEY, storageController);
</script>
