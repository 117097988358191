import GeneralApiController from "./GeneralApiController";

class ExportApiController extends GeneralApiController {
	exportReports = (query = "") =>
		this.http.get(`/export/management/reports?${query}`, {
			responseType: "blob",
		});

	exportRepotsHistory = (query = "") =>
		this.http.get(`/export/management/reports-history?${query}`, {
			responseType: "blob",
		});
}

const exportApiController = new ExportApiController();

export default exportApiController;
