<template>
	<PageLayout
		title="Employees"
		:count-text="employees && getEmployeesCount(employees.length)"
		:loading="loading || isDeleting"
	>
		<template v-slot:controls>
			<RouterLink
				custom
				:to="
					authStore.onboardingActive
						? { name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_NEW }
						: { name: ADMIN_ROUTE_NAMES.EMPLOYEES_NEW }
				"
				v-slot="{ href, navigate }"
				v-if="path === '/employees'"
			>
				<AppButton
					:href="href"
					text="Add employee"
					outline
					medium
					@click="navigate"
				>
					<template v-slot:icon>
						<PlusIcon />
					</template>
				</AppButton>
			</RouterLink>

			<AppButton
				v-if="path !== '/employees'"
				text="FINISH"
				@click="openModalFinish = true"
				medium
				style="float: right"
			></AppButton>
		</template>
		<div class="layout">
			<div class="layout__col--4of4">
				<div v-if="employees" class="employees-grid">
					<Preview
						v-for="employee in employees"
						:key="`employee-${employee.id}`"
						:title="employee.name"
						:info="[
							capitalize(employee.roles[0]),
							getCompaniesCount(employee.companies.length),
						]"
						show-edit
						show-delete
						@delete="confirmDelete(employee.id)"
						@edit="goToEdit(employee.id)"
					></Preview>
				</div>
			</div>
		</div>
		<BaseModal :open="openModalFinish" :modal-close="false">
			<h3 style="margin-top: 0; margin-bottom: 24px">Onboarding is done!</h3>
			<p class="desc" style="font-weight: 500">
				You can add or edit companies and employees from your workspace
			</p>

			<AppButton
				style="width: 110px; height: 48px; margin-top: 22px"
				text="OK"
				@click="finishOnboarding"
			></AppButton>
		</BaseModal>
	</PageLayout>
</template>

<script setup>
import PageLayout from "../../../../../components/layouts/PageLayout.vue";
import AppButton from "../../../../../components/base/AppButton.vue";
import PlusIcon from "@/assets/icons/plus.svg";
import useEmployeesList from "../../../../../hooks/employees/useEmployeesList";
import Preview from "../../../../../components/preview/VPreview.vue";
import { capitalize, getCompaniesCount } from "@/helpers/strings";
import useDeleteEmployee from "../../../../../hooks/employees/useDeleteEmployee";
import useConfirmation from "../../../../../store/confirmation";
import {
	ADMIN_ROUTE_NAMES,
	ONBOARDING_ROUTE_NAMES,
} from "../../../../../constants/route-names";
import { useRoute, useRouter } from "vue-router";
import useNotifications from "../../../../../store/notifications";
import { computed, ref } from "vue";
import BaseModal from "@/components/modals/BaseModal";
import { deactivateOnboarding } from "@/router/helpers";
import { useAuth } from "@/store/auth";
import { getEmployeesCount } from "@/helpers/strings";

const { employees, loading, refetch: refetchEmployees } = useEmployeesList();
const { deleteEmployee, loading: isDeleting } = useDeleteEmployee();
const confirm = useConfirmation();
const notifications = useNotifications();
const router = useRouter();
const route = useRoute();
const path = computed(() => route.path);
const authStore = useAuth();

const openModalFinish = ref(false);

const confirmDelete = (id) => {
	confirm.showConfirmation({
		title: "Delete employee?",
		onSubmit: async () => {
			loading.value = true;
			await deleteEmployee(id);
			notifications.showNotification({ message: "User deleted!" });
			refetchEmployees();
		},
	});
};

const goToEdit = (id) => {
	if (authStore.onboardingActive) {
		router.push({
			name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_EDIT,
			params: { id },
		});
	} else {
		router.push({ name: ADMIN_ROUTE_NAMES.EMLOYEES_EDIT, params: { id } });
	}
};

const finishOnboarding = () => {
	openModalFinish.value = false;
	deactivateOnboarding(true);
	authStore.onboardingActive = false;
};
</script>

<style lang="scss" scoped>
.employees-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
	gap: 30px;
}
</style>
