import {reactive, toRefs} from 'vue';
import accountsApiController from '../../../api/accounts/accountsApiController';

const useDeleteAccount = () => {
	const state = reactive({
		loading: false,
		error: false,
	});

	const deleteAccount = async (id) => {
		state.loading = true;

		try {
			const response = await accountsApiController.deleteAccount(id);
			return response
		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	return {
		...toRefs(state),
		deleteAccount,
	};
};

export default useDeleteAccount;
