<template>
		<div style="border-top-left-radius: 8px;
    border-top-right-radius: 8px; overflow-x: auto;padding-bottom: 80px;flex:1;">
		<table class="table">
			<thead class="table-head">
			<tr>
				<TableHeadCell title="Number" />
				<TableHeadCell title="Date" :filtered="activeFilters.period" />
				<TableHeadCell title="Expense/<br />Income" :filtered="activeFilters.type" />
				<TableHeadCell title="Company name" :filtered="activeFilters.company" />
				<TableHeadCell title="Account" :filtered="activeFilters.account" />
				<TableHeadCell title="Amount" />
				<TableHeadCell title="Currency" />
				<TableHeadCell title="Type of<br />payment" :filtered="activeFilters.paymentType" />
				<TableHeadCell title="Expenses type" />
				<TableHeadCell title="Description" style="width: 25%;" />
				<TableHeadCell />
			</tr>
			</thead>
			<tbody ref="tableEl" class="table-body">
			<ReportFormRow v-if="showForm" @submit="handleNewReport" />
			<UserReportsTableRow
					v-for="report in reports"
					:key="report.id"
					:report="report"
					@delete="(id) => $emit('delete', id)"
					@edit="(opt) => $emit('edit', opt)"
			/>
			</tbody>
		</table>
		</div>

</template>

<script setup>
import UserReportsTableRow from "./UserReportsTableRow.vue";
import ReportFormRow from "./ReportFormRow.vue";
import { inject } from "vue";
import { TABLE_EL_KEY } from "../../../constants/injectionKeys";
import { FILTER_CONTROLLER_KEY } from "@/views/user/constants/injectionKeys";
import TableHeadCell from "@/views/user/components/TableHeadCell.vue";

defineProps({
	reports: {
		type: Array,
		required: true,
	},
	showForm: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["create-report", "delete", "edit"]);

const tableEl = inject(TABLE_EL_KEY);
const { activeFilters } = inject(FILTER_CONTROLLER_KEY);

const handleNewReport = (report) => {
	emit("create-report", report);
};
</script>
