<template>
	<tr class="row">
		<template v-if="companiesLoading">
			<TableCell />
			<Preloader />
		</template>
		<template v-else>
			<TableCell>###</TableCell>

			<TableCell>
				<input type="date" v-model="formState.reported_at" />
			</TableCell>

			<TableCell>
				<select v-model="formState.type" class="form-group__select">
					<option value="expense" selected>Expense</option>
					<option value="income">Income</option>
					<option value="movement">Movement</option>
				</select>
			</TableCell>

			<TableCell :error="$v.company_id.$error">
				<template v-if="activeCompanies && activeCompanies.length">
					<select
						v-if="activeCompanies.length > 1"
						v-model="formState.company_id"
						class="form-group__select form-group__select--company"
					>
						<option :value="''" disabled>Select company</option>
						<option
							v-for="company in activeCompanies"
							:key="`company-${company.id}`"
							:value="company.id"
						>
							{{
								company.name.length > 25
									? trimString(company.name, 25)
									: company.name
							}}
						</option>
					</select>
					<div v-else>{{ activeCompanies[0].name }}</div>
				</template>
			</TableCell>

			<TableCell :error="$v.account_id.$error">
				<div v-if="!selectedCompany">Choose account</div>
				<select
					v-else-if="selectedCompany && activeAccounts.length > 1"
					v-model="formState.account_id"
				>
					<option :value="''" disabled>Select account</option>
					<option
						v-for="account in activeAccounts"
						:key="`account-${account.id}`"
						:value="account.id"
					>
						{{
							account.name.length > 20
								? trimString(account.name, 20)
								: account.name
						}}
					</option>
				</select>
				<div v-else>{{ activeAccounts[0].name }}</div>
			</TableCell>

			<TableCell :error="$v.amount.$error">
				<input
					type="number"
					placeholder="0"
					v-model="formState.amount"
					style="text-align: center"
				/>
			</TableCell>

			<TableCell :error="$v.currency_id.$error">
				<div v-if="!selectedCompany">Choose company</div>
				<div v-else-if="!selectedAccount">Choose account</div>
				<select
					v-else-if="selectedAccount && activeCurrencies.length > 1"
					v-model="formState.currency_id"
				>
					<option :value="''" disabled>Select currency</option>
					<option
						v-for="currency in activeCurrencies"
						:key="`currency-${selectedAccount.id}-${currency.id}`"
						:value="currency.id"
					>
						{{ currency.name }}
					</option>
				</select>
				<div v-else>{{ activeCurrencies[0].name }}</div>
			</TableCell>

			<TableCell :error="$v.payment_type_id.$error">
				<div v-if="!selectedCompany">Choose company</div>
				<div v-else-if="!selectedAccount">Choose account</div>
				<select
					v-else-if="selectedAccount && activePaymentTypes.length > 1"
					v-model="formState.payment_type_id"
				>
					<option :value="''" disabled>Select type</option>
					<option
						v-for="paymentType in activePaymentTypes"
						:key="`currency-${selectedAccount.id}-${paymentType.id}`"
						:value="paymentType.id"
					>
						{{ paymentType.name }}
					</option>
				</select>
				<div v-else>{{ activePaymentTypes[0].name }}</div>
			</TableCell>

			<TableCell :error="$v.expense_id.$error" :class="{disabled: formState.type !== 'expense'}">
				<div v-if="formState.type !== 'expense'">---</div>
				<div v-else-if="!selectedCompany">Choose company</div>
				<div v-else-if="!selectedAccount">Choose account</div>
				<select
					v-else-if="selectedAccount && activeExpenseTypes.length > 1"
					v-model="formState.expense_id"
				>
					<option :value="''">No type</option>
					<option
						v-for="expenseType in activeExpenseTypes"
						:key="`currency-${selectedAccount.id}-${expenseType.id}`"
						:value="expenseType.id"
					>
						{{ expenseType.name }}
					</option>
				</select>
				<div v-else-if="activeExpenseTypes.length === 0">---</div>
				<div v-else>{{ activeExpenseTypes[0].name }}</div>
			</TableCell>

			<TableCell clamp :error="$v.description.$error">
				<textarea
					v-model="formState.description"
					placeholder="Type your text here..."
				></textarea>
			</TableCell>

			<TableCell>
				<AppButton text="Add" @click="submitHandler" />
			</TableCell>
		</template>
	</tr>
</template>

<script setup>
import TableCell from "../../../components/TableCell.vue";
import AppButton from "../../../../../components/base/AppButton.vue";
import useReportFormRow from "../../../hooks/useReportForm";
import Preloader from "../../../../../components/base/Preloader.vue";

const props = defineProps({
	report: {
		type: Object,
		default: null,
	},
});

const emit = defineEmits(["submit"]);

const {
	activeCompanies,
	activeAccounts,
	activeCurrencies,
	activePaymentTypes,
	activeExpenseTypes,
	companiesLoading,
	formState,
	selectedCompany,
	selectedAccount,
	$v,
	getFormattedValues,
} = useReportFormRow(props.report);

const submitHandler = () => {
	$v.value.$touch();
	if (!$v.value.$invalid) {
		emit("submit", getFormattedValues());
	}
};
const trimString = (string, max) => {
	let trimmedString = string.substr(0, max);
	trimmedString = trimmedString.substr(
		0,
		Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
	);
	return trimmedString + "...";
};
</script>

<style scoped>
.row {
	position: relative;
}
input[type="number"] {
	-moz-appearance: textfield;
}

@media (max-width: 1500px) {
	.form-group__select--company {
		max-width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
</style>
