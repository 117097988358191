<template>
	<div style="border-top-left-radius: 8px;
    border-top-right-radius: 8px; overflow-x: auto;padding-bottom: 80px;flex:1;">
	<table class="table">
		<thead class="table-head">
			<tr>
				<TableHeadCell title="Number" />
				<TableHeadCell title="Date" :filtered="activeFilters.period" />
				<TableHeadCell
					title="Expense/<br />Income"
					:filtered="activeFilters.type"
				/>
				<TableHeadCell title="Employee" :filtered="activeFilters.employees" />
				<TableHeadCell title="Company name" :filtered="activeFilters.company" />
				<TableHeadCell title="Account" :filtered="activeFilters.account" />
				<TableHeadCell title="Amount" />
				<TableHeadCell title="Currency" :filtered="activeFilters.currency" />
				<TableHeadCell
					title="Type of<br />payment"
					:filtered="activeFilters.paymentType"
				/>
				<TableHeadCell
					title="Expenses type"
				/>
				<TableHeadCell title="Description" style="width: 20%" />
				<TableHeadCell title="Changes" style="width: 20%" />
			</tr>
		</thead>
		<tbody ref="tableEl" class="table-body">
			<EmployeeHistoryTableRow
				v-for="report in reports"
				:key="`report-${report.id}`"
				:report="report"
			/>
		</tbody>
	</table>
	</div>
</template>

<script setup>
import {
	FILTER_CONTROLLER_KEY,
	TABLE_EL_KEY,
} from "@/views/user/constants/injectionKeys";
import { inject } from "vue";
import EmployeeHistoryTableRow from "../components/EmployeeHistoryTableRow";
import TableHeadCell from "@/views/user/components/TableHeadCell.vue";

const tableEl = inject(TABLE_EL_KEY);
const { activeFilters } = inject(FILTER_CONTROLLER_KEY);

defineProps({
	reports: {
		type: Array,
		required: true,
	},
});
</script>
