<template>
	<teleport to="#modal-container">
		<template v-if="isFormOpen">
			<div class="dimmer" @click="closeFilterForm"></div>
			<div ref="contentEl" class="filter">
				<template v-if="companiesLoading || employeesLoading">
					<Preloader />
				</template>
				<div v-if="isMobile" class="modal__close" @click="isFormOpen = false">
					<span>Close</span>
					<CrossIcon />
				</div>
				<template v-if="companies">
					<div class="filter__head">
						<FilterIcon class="filter__head-icon" />
						<h1 class="filter__head-text">Filters</h1>
					</div>
					<div class="filter__body">
						<div class="filter-item">
							<VCheckBox
								label="Period"
								expand
								v-model="filterState.period"
								:disabled="!filterState.period"
								@change="periodChangeHandler"
							/>

							<div class="filter-item__date">
								<FormGroup label="From" stroke>
									<PrimaryInput
										type="date"
										placeholder="Date from"
										:max="objToInputDate(new Date())"
										v-model="filterState.periodFrom"
										@change="datesChangeHandler"
									/>
								</FormGroup>
								<FormGroup label="To" stroke>
									<PrimaryInput
										type="date"
										placeholder="Date to"
										:min="filterState.periodFrom"
										:max="objToInputDate(new Date())"
										v-model="filterState.periodTo"
										@change="datesChangeHandler"
									/>
								</FormGroup>
							</div>
						</div>

						<div class="filter-item">
							<div class="filter-item__head">
								<h3 class="filter-item__head-title">Expense/Income</h3>
								<button class="filter-item__head-button" @click="resetType">
									<DeleteIcon />
								</button>
							</div>
							<ul class="filter-item__list">
								<li class="filter-item__list-item">
									<VCheckBox
										type="radio"
										label="Expense"
										expand
										value="expense"
										v-model="filterState.type"
									/>
								</li>
								<li class="filter-item__list-item">
									<VCheckBox
										type="radio"
										label="Income"
										expand
										value="income"
										v-model="filterState.type"
									/>
								</li>
								<li class="filter-item__list-item">
									<VCheckBox
										type="radio"
										label="Movement"
										expand
										value="movement"
										v-model="filterState.type"
									/>
								</li>
							</ul>
						</div>

						<div class="filter-item">
							<div class="filter-item__head">
								<h3 class="filter-item__head-title">
									Companies accounts and types of payment
								</h3>
								<button
									class="filter-item__head-button"
									@click="resetCompanies"
								>
									<DeleteIcon />
								</button>
							</div>
							<ul class="filter-item__list">
								<li
									v-for="company in companies"
									:key="`company-${company.id}`"
									class="filter-item__list-item"
								>
									<AppAccordion>
										<template v-slot:header="{ toggle }">
											<div class="filter-item__accordion-head">
												<VCheckBox
													expand
													:label="company.name"
													:value="company.id"
													v-model="filterState.companies"
													@change="
														({ target }) =>
															companyChangeHandler({
																val: target.checked,
																company: company,
															})
													"
												/>
												<button
													class="filter-item__accordion-toggle"
													@click="toggle"
												>
													<ArrowDownIcon />
												</button>
											</div>
										</template>
										<template #body>
											<ul class="filter-item__list">
												<li
													v-for="account in company.accounts"
													:key="`account-${account.id}`"
													class="filter-item__list-item filter-item__list-item--bordered"
												>
													<VCheckBox
														expand
														:label="account.name"
														:value="account.id"
														v-model="filterState.accounts"
														@change="
															({ target }) =>
																accountChangeHandler({
																	val: target.checked,
																	company: company,
																	account: account,
																})
														"
													/>

													<ul class="filter-item__list">
														<li
															v-for="paymentType in account.payment_types"
															:key="`payment-type-${paymentType.id}`"
															class="filter-item__list-item"
														>
															<VCheckBox
																expand
																:label="paymentType.name"
																:value="paymentType.id"
																v-model="filterState.paymentTypes"
																@change="
																	({ target }) =>
																		paymentTypeChangeHandler({
																			val: target.checked,
																			company: company,
																			account: account,
																		})
																"
															/>
														</li>
													</ul>
												</li>
											</ul>
										</template>
									</AppAccordion>
								</li>
							</ul>
						</div>

						<div v-if="employeesActive" class="filter-item">
							<div class="filter-item__head">
								<h3 class="filter-item__head-title">Employees</h3>
								<button
									class="filter-item__head-button"
									@click="resetEmployees"
								>
									<DeleteIcon />
								</button>
							</div>
							<div class="filter-item__search">
								<FormGroup stroke>
									<PrimaryInput
										placeholder="Search employee"
										v-model="searchTerm"
									/>
								</FormGroup>
							</div>
							<ul
								v-if="employees && employees.length > 0"
								class="filter-item__list filter-item__list--scrollable"
							>
								<li
									v-for="employee in employees"
									:key="`employee-${employee.id}`"
									class="filter-item__list-item"
								>
									<VCheckBox
										expand
										:label="employee.name"
										:value="employee.id"
										v-model="filterState.employees"
									/>
								</li>
							</ul>
							<div v-else class="filter-item__list">No employees...</div>
						</div>
					</div>
					<div class="filter__footer">
						<AppButton
							text="Reset Filters"
							outline
							block
							@click="resetFilter()"
						/>
						<AppButton text="Apply Filters" block @click="applyFilter()" />
					</div>
				</template>
			</div>
		</template>
	</teleport>
</template>

<script setup>
import FilterIcon from "@/assets/icons/filter.svg";
import { inject, ref } from "vue";
import VCheckBox from "../../../components/form-components/VCheckBox.vue";
import FormGroup from "../../../components/form-components/FormGroup.vue";
import PrimaryInput from "../../../components/form-components/PrimaryInput.vue";
import useCompaniesList from "../../../hooks/companies/useCompaniesList";
import Preloader from "../../../components/base/Preloader.vue";
import AppAccordion from "../../../components/base/AppAccordion.vue";
import ArrowDownIcon from "@/assets/icons/arrow-down.svg";
import DeleteIcon from "@/assets/icons/delete.svg";
import { objToInputDate } from "@/helpers/strings";
import AppButton from "@/components/base/AppButton.vue";
import {
	FILTER_CONTROLLER_KEY,
	TABLE_CONFIG_KEY,
} from "../constants/injectionKeys.js";
import useWindowResize from "../../../hooks/useWindowResize";
import CrossIcon from "@/assets/icons/cross.svg";
import useBodyScroll from "../../../hooks/useBodyScroll";
import useEmployeeFormSelect from "../hooks/useEmployeeFormSelect";

const { employeesActive } = inject(TABLE_CONFIG_KEY);
const { companies, loading: companiesLoading } = useCompaniesList();
const { employees, employeesLoading, searchTerm } =
	useEmployeeFormSelect(employeesActive);
const { isMobile } = useWindowResize();
const contentEl = ref(null);

const {
	isFormOpen,
	filterState,
	applyFilter,
	resetFilter,
	resetType,
	resetCompanies,
	resetEmployees,
	closeFilterForm,
	companyChangeHandler,
	accountChangeHandler,
	paymentTypeChangeHandler,
	datesChangeHandler,
	periodChangeHandler,
} = inject(FILTER_CONTROLLER_KEY);

useBodyScroll(contentEl, isFormOpen);
</script>

<style scoped>
.dimmer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 198;

	background: #0c0c0c;
	opacity: 0.8;
}
.filter-item__date .form-group:not(:last-of-type) {
	margin-bottom: 0;
}
</style>
