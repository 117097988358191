<template>
	<div class="controls">
		<div class="controls__col">
			<AppButton outline square @click="refetchWithConfirmation">
				<template #icon><RefreshIcon /></template>
			</AppButton>
			<AppButton
				v-if="showCreate && !isMobile"
				text="Create new"
				@click="$emit('create-new')"
			>
				<template #icon><PlusIcon /></template>
			</AppButton>
			<AppButton
				v-if="showCreate && isMobile"
				text="New"
				@click="$emit('create-new')"
			>
				<template #icon><PlusIcon /></template>
			</AppButton>
		</div>
		<div class="controls__col">
			<AppButton v-if="showExport" text="Export" outline expand @click="handleExportClick">
				<template #icon><ExportIcon /></template>
			</AppButton>
			<AppButton text="filter" outline expand @click="showFilterForm">
				<template #icon><FilterIcon /></template>
			</AppButton>
		</div>
	</div>
</template>

<script setup>
import AppButton from "@/components/base/AppButton.vue";
import RefreshIcon from "@/assets/icons/refresh.svg";
import PlusIcon from "@/assets/icons/plus.svg";
import FilterIcon from "@/assets/icons/filter.svg";
import ExportIcon from "@/assets/icons/export.svg";
import { inject, computed } from "vue";
import {
	EXPORT_CONTROLLER_KEY,
	FILTER_CONTROLLER_KEY,
	TABLE_CONTROLLER_KEY,
} from "../constants/injectionKeys";
import useWindowResize from '@/hooks/useWindowResize.js';

defineProps({
	showCreate: {
		type: Boolean,
		default: false,
	},
	showExport: {
		type: Boolean,
		default: false,
	},
});

defineEmits(["create-new"]);

const exportController = inject(EXPORT_CONTROLLER_KEY);
const { showFilterForm, filterState } = inject(FILTER_CONTROLLER_KEY);
const { refetchWithConfirmation } = inject(TABLE_CONTROLLER_KEY);
const { width } = useWindowResize();

const isMobile = computed(() => width.value < 1280)

const handleExportClick = () => {
	exportController.openExportForm(filterState);
};
</script>

<style scoped>
.controls {
	display: flex;
	justify-content: space-between;
	gap: 16px;
}

.controls__col {
	display: flex;
	align-items: center;
	gap: 16px;
}
</style>
