import { throttle } from "@/helpers/decorators";
import { onMounted, onUnmounted, reactive, ref } from "vue";

const useTablePagination = ({ loading, onPageRequest, meta }) => {
	const paginationState = reactive({
		page: 1,
	});

	const tableEl = ref(null);

	const handleScroll = throttle(() => {
		if (!tableEl.value || !meta.value) return;

		if (meta.value.current_page === meta.value.last_page) return;

		if (!loading.value) {
			const bottom = tableEl.value.getBoundingClientRect().bottom;

			if (window.innerHeight - bottom < 50) return;

			paginationState.page += 1;

			onPageRequest();
		}
	}, 200);

	const resetPage = () => {
		paginationState.page = 1;
	};

	onMounted(() => {
		window.addEventListener("scroll", handleScroll);
	});

	onUnmounted(() => {
		window.removeEventListener("scroll", handleScroll);
	});

	return {
		paginationState,
		tableEl,
		resetPage,
	};
};

export default useTablePagination;
