export const normalizeReports = (reports) => {
	if (Array.isArray(reports) && reports.length > 0) {
		return reports.map((report) => {
			return {
				...report,
				amount: parseFloat(report.amount),
			};
		});
	}

	return [];
}
