<template>
  <PageLayout>
    <template v-if="step === 'date'">
      <h1>Delete reports</h1>
      <p>
        In case if you want to delete reports in the system all employees, accounts and companies will be saved.
      </p>     
      <h4>Do you want to delete all reports in the system or for a certain period of time?</h4>
      <div class="make-choose">
        <VCheckBox
          type="radio"
          label="Delete all reports"
          name="choose"
          @click="router.push('/delete-reports')"
          expand
        />
        <VCheckBox
          type="radio"
          label="Delete reports for a time period"
          name="choose"
          @click="router.push('/delete-reports/period')"
          checked
          expand
        />        
      </div>
      <h3>Period</h3>
      <div class="filter-item__date">
        
        <FormGroup 
          label="From" 
          stroke 
          :error="$pv.fromPeriod.$error"
          :error-message="getErrorMessages($pv.fromPeriod.$errors)"
        >
          <PrimaryInput
            type="date"
            placeholder="Date from"
            :max="objToInputDate(new Date())"
            v-model="periodState.fromPeriod"
          />
        </FormGroup>
        <FormGroup 
          label="To" 
          stroke
          :error="$pv.toPeriod.$error"
          :error-message="getErrorMessages($pv.toPeriod.$errors)"
        >
          <PrimaryInput
            type="date"
            placeholder="Date to"
            :min="periodState.fromPeriod"
            :max="objToInputDate(new Date())"
            v-model="periodState.toPeriod"
          />
        </FormGroup>
      </div>
      <h3>Are you sure you want to delete reports during this period?</h3>
      <div class="buttons">
        <RouterLink
          custom
          :to="{ name: ADMIN_ROUTE_NAMES.COMPANIES_LIST }"
          v-slot="{ href, navigate }"
        >
          <Button small text="NO" :href="href" @click="navigate" />
        </RouterLink>       
        <Button @click="confirmPeriod" small outline text="YES" />
      </div>
    </template>
    <template v-if="step === 'password'">
      <h1>Delete reports</h1>
      <p>
        In case if you want to delete the reports in the system all employees,
        accounts and companies will be saved.
      </p>
      <h3>For start the process you have to enter password and confirm it.</h3>
      <div class="layout">
        <div class="layout__col--1of4">
          <FormGroup
            label="Password"
            stroke
            :error="$passV.password.$error"
            :error-message="getErrorMessages($passV.password.$errors)"
          >
            <PrimaryInputVue
              type="password"
              placeholder="******"
              v-model="passState.password"
            />
          </FormGroup>
          <FormGroup
            label="Confirm Password"
            stroke
            :error="$passV.confirmPassword.$error"
            :error-message="getErrorMessages($passV.confirmPassword.$errors)"
          >
            <PrimaryInputVue
              type="password"
              placeholder="******"
              v-model="passState.confirmPassword"
            />
          </FormGroup>
          <div class="buttons">
            <AppButton
              :disabled="$passV.$error ? true : false"
              expand
              text="CONFIRM"
              @click="submitHandler"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="step === 'finish'">
      <h1>Delete reports</h1>
      <p>
        In case if you want to delete reports in the system all employees, accounts and companies will be saved.
      </p>
      <div class="img">
        <img src="/img/success.svg" alt />
      </div>
      <h3>You are successfully delete reports during selected time period.</h3>
      <div class="buttons">
        <RouterLink
          custom
          :to="{ name: ADMIN_ROUTE_NAMES.COMPANIES_LIST }"
          v-slot="{ href, navigate }"
        >
          <AppButton
            expand
            text="OK, CLOSE THIS TAB"
            :href="href"
            @click="navigate"
          />
        </RouterLink>
      </div>
    </template>
  </PageLayout>
</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout.vue";
import Button from "@/components/base/AppButton";
import { ADMIN_ROUTE_NAMES } from "@/constants/route-names";
import VCheckBox from "@/components/form-components/VCheckBox.vue";
import { useRouter } from "vue-router";
import FormGroup from "@/components/form-components/FormGroup.vue";
import PrimaryInput from "@/components/form-components/PrimaryInput.vue";
import { objToInputDate } from "@/helpers/strings";
import { reactive } from "@vue/reactivity";
import { ref, toRef } from "vue";
import { required, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { getErrorMessages } from "@/helpers/strings";
import PrimaryInputVue from "@/components/form-components/PrimaryInput";
import AppButton from "@/components/base/AppButton.vue";
import useConfirmation from "@/store/confirmation";
import useDeletePeriodReports from "@/hooks/reports/useDeletePeriodReports";
import { inputToQueryStringDate } from "@/helpers/strings";




const router = useRouter();
const step = ref("date");
const periodState = reactive({ fromPeriod: "", toPeriod: "" });
const { deletePeriodReports } = useDeletePeriodReports();
const periodRules = {
  fromPeriod: { required },
  toPeriod: { required },
};

const confirm = useConfirmation();
const $pv = useVuelidate(periodRules, periodState);
const passState = reactive(
  { password: "", confirmPassword: "" });

const confirmPeriod = () => {
  $pv.value.$touch();

  if (!$pv.value.$invalid) {
    step.value = "password";
  }
};

const passRules = {
  password: {
    required: required,
  },
  confirmPassword: {
    required: required,
    sameAs: sameAs(toRef(passState, "password")),
  },
};

const $passV = useVuelidate(passRules, passState);

const submitHandler = async () => {
  $passV.value.$touch();
  if (!$pv.value.$invalid) {
    confirm.showConfirmation({
      title: "Delete reports",
      text: "By press “Yes” button, reports will be deleted. Are you sure?",
      onSubmit: async () => {
        const data = {
          password: passState.password,
          password_confirmation: passState.confirmPassword,
          from_period: `${inputToQueryStringDate(
            periodState.fromPeriod
          )}`,
          to_period: `${inputToQueryStringDate(
            periodState.toPeriod
          )}T23:59:59Z`,
        };
        // console.log(data)
        await deletePeriodReports(data);
        step.value = "finish";
      },
    });
  }
};
</script>


<style scoped>
h1 {
  margin-bottom: 16px;
  font-size: 34px;
  font-weight: 500;
}
p {
  margin-bottom: 54px;
  max-width: 470px;
}
h3 {
  margin-bottom: 24px;
}
h4 {
  max-width: 320px;
  margin-bottom: 24px;
}
.buttons > :not(:last-child) {
  margin-right: 30px;
}
.checkbox .checkmark {
  margin-left: auto;
}
.make-choose {
  max-width: 250px;
}
.make-choose .checkbox {
  margin-bottom: 34px;
}
.filter-item__date {
  max-width: 450px;
  margin-bottom: 54px;
}
.form-group:not(:last-of-type) {
  margin-bottom: 0;
}

.layout__col--1of4 .form-group {
  margin-bottom: 34px;
}
</style>
