<template>
	<keep-alive>
		<FilterForm v-if="isFormOpen" />
	</keep-alive>
	<TableActiveFilters />
</template>

<script setup>
import { inject } from "vue";
import { FILTER_CONTROLLER_KEY } from "../constants/injectionKeys";
import FilterForm from "./FilterForm.vue";
import TableActiveFilters from "./TableActiveFilters.vue";

const { isFormOpen } = inject(FILTER_CONTROLLER_KEY);
</script>
