<template>
	<div class="layout">
		<div class="layout__col--1of4">
			<div v-if="storageSpace.percent > 90" class="full-error">
				<WarningIcon class="full-error__icon" />
				<span>Your cloud storage almost full</span>
			</div>
			<ProgressBar :min-text="storageSpace.text" max-text="Max 5 GB" :value="storageSpace.percent" />
		</div>
		<div class="layout__col--1of4">
			<Search />
		</div>
	</div>
</template>

<script setup>
import ProgressBar from "@/components/form-components/ProgressBar.vue";
import Search from "@/components/form-components/Search.vue";
import { inject } from "vue";
import { STORAGE_CONTROLLER_KEY } from "../../../constants/injectionKeys";
import WarningIcon from "@/assets/icons/warning.svg"

const { storageSpace } = inject(STORAGE_CONTROLLER_KEY);
</script>

<style lang="scss" scoped>
.layout {
	margin-bottom: 44px;
}

.full-error {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 16px;
	color: $red;

	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
}

@media (max-width: 1200px) {
	.layout {
		grid-template-columns: repeat(1, 1fr);
	}
}
</style>
