import { ref } from 'vue';

const useEmployeeCompanyState = (employee) => {
	const selectedCompanies = ref([]);

	const selectedAccounts = ref([]);

	const selectedEmployees = ref([]);

	if (employee) {
		employee.companies.forEach((company) => {
			selectedCompanies.value.push(company.id);

			company.accounts.forEach((account) => {
				selectedAccounts.value.push(account.id);
			});
		});
		employee.employees.forEach((emp) => {
			selectedEmployees.value.push(emp.id);
		});
	}

	return {
		selectedCompanies,
		selectedAccounts,
		selectedEmployees,
	};
};

export default useEmployeeCompanyState;
