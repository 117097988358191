import GeneralApiController from "./GeneralApiController";

class StorageApiController extends GeneralApiController {
	getFolders = () => this.http.get("virtual-folders");

	createFolder = (data) => this.http.post("virtual-folders", data);

	updateFolder = (id, data) => this.http.patch(`virtual-folders/${id}`, data);

	deleteFolder = (id) => this.http.delete(`virtual-folders/${id}`);

	uploadFile = (id, data) =>
		this.http.post(`virtual-folders/${id}/upload`, data);

	updateFile = (id, data) =>
		this.http.patch(`files/${id}`, data);

	downloadFile = (id) =>
		this.http.get(`files/${id}`, {
			responseType: "blob",
		});

	deleteFile = (id) => this.http.delete(`files/${id}`);
}

const storageApiController = new StorageApiController();

export default storageApiController;
