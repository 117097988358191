import {ONBOARDING_ROUTE_NAMES} from "@/constants/route-names";
import EmployeesList from '@/views/admin/employees/views/list/EmloyeesList.vue';
import AddEmployee from '@/views/admin/onboarding/step2.vue';
import EditEmployee from '@/views/admin/employees/views/edit/EditEmployee.vue';
import {USER_ROLES} from '@/constants/userRoles';

/** @type {import('vue-router').RouteRecordRaw[]} */
const step2Routes = [
	{
		path: '/onboarding/step2/employees',
		name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_LIST,
		component: EmployeesList,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Step 2 - employees'
		}
	},
	{
		path: '/onboarding/step2/add-employee',
		name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_NEW,
		component: AddEmployee,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Step 2 - Add new employee'
		}
	},
	{
		path: '/onboarding/step2/employees/:id',
		name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_EDIT,
		component: EditEmployee,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Step 2 - Edit employee'
		}
	},
];

export default step2Routes;
