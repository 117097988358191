<template>
	<PageLayout
		title="Employee reports history"
		transparent-preloader
		:loading="reportsLoading"
	>
		<template #controls>
			<ReportsControls show-export />
		</template>

		<TableFilter />
		<ExportForm />

		<EmployeeHistoryTable v-if="reports" :reports="reports" />
	</PageLayout>
</template>

<script setup>
import historyApiController from "@/api/historyApiController";
import PageLayout from "@/components/layouts/PageLayout.vue";
import ReportsControls from "../../components/ReportsControls.vue";
import useReportTable from "../../hooks/useReportTable";
import EmployeeHistoryTable from "./components/EmployeeHistoryTable.vue";
import TableFilter from "../../components/TableFilter.vue";
import exportApiController from "../../../../api/exportApiController";
import ExportForm from "../../components/ExportForm.vue";

const { loading: reportsLoading, reports } = useReportTable(
	historyApiController.getEmployeeHistory,
	exportApiController.exportRepotsHistory,
	{ employeesActive: true }
);
</script>
