<template>
	<BaseModal :open="open" @close="$emit('close')">
		<div class="wrapper">
			<div class="modal__content-title">Add companies</div>
			<div class="modal__content-desc">Select and add companies to this role</div>
			<div class="modal__content-list">
				<VCheckBox
					v-for="company in companiesList"
					class="modal__content-list__item"
					:key="`checkbox-${company.id}`"
					:label="company.name"
					:value="company.id"
					v-model="selected"
				/>
			</div>
			<div class="modal__buttons">
				<AppButton text="Add" expand @click="submit" />
			</div>
		</div>
	</BaseModal>
</template>

<script setup>
import BaseModal from "../../../../components/modals/BaseModal.vue";
import VCheckBox from "../../../../components/form-components/VCheckBox.vue";
import { ref } from "vue";
import AppButton from "../../../../components/base/AppButton.vue";

defineProps({
	open: {
		type: Boolean,
		default: false,
	},
	companiesList: {
		type: Array,
		required: true,
	},
});

const emit = defineEmits(["change", "close"]);

const selected = ref([]);

const submit = () => {
	emit("change", selected.value);
};
</script>
