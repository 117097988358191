import GeneralApiController from '../GeneralApiController';

class AccountsApiController extends GeneralApiController {
	createAccount = (id, data) => this.http.post(`companies/${id}/accounts`, data);

	updateAccount = (id, data) => this.http.patch(`accounts/${id}`, data);

	deleteAccount = (id) => this.http.delete(`accounts/${id}`);

	deleteUserFromAccount = (id, user_ids) => this.http.delete(`accounts/${id}/employees`, {data: user_ids})

	addUserToAccount = (id, data) => this.http.post(`accounts/${id}/employees`, data);
}

const accountsApiController = new AccountsApiController();

export default accountsApiController;
