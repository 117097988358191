import GeneralApiController from '../GeneralApiController';

class CurrenciesApiController extends GeneralApiController {
  createCurrency = (id, data) => this.http.post(`accounts/${id}/currencies`, data);

  updateCurrency = (id, data) => this.http.patch(`currencies/${id}`, data);

  deleteCurrency = (id) => this.http.delete(`currencies/${id}`);
}

const currenciesApiController = new CurrenciesApiController();

export default currenciesApiController;
