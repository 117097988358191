import {reactive, toRefs} from 'vue';
import paymentTypesApiController from '../../../../api/accounts/paymentTypesApiController';

const useCreatePaymentType = () => {
  const state = reactive({
    loading: false,
    error: false,
  });

  const createPaymentType = async (id, data) => {
    state.loading = true;

    try {
      const response = await paymentTypesApiController.createPaymentType(id, data);
      return response
    } catch (error) {
      state.error = error;
    }

    state.loading = false;

  };


  return {
    ...toRefs(state),
    createPaymentType,
  };
};

export default useCreatePaymentType;
