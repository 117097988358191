import { ref } from "vue"
import reportsApiController from "../../api/reportsApiController"
import useNotifications from "../../store/notifications"


const useDeletePeriodReports = () => {
	const loading = ref(false)

	const { showNotification } = useNotifications();

	const deletePeriodReports = async (data) => {
		loading.value = true
		const response = await reportsApiController.deletePeriodReports(data)

		if (response.data.success) {
			showNotification({
				message: `Reports from current period successfully deleted.`
			})
		}

		loading.value = false
		return response
	}

	return {
		loading,
		deletePeriodReports,
	}
}

export default useDeletePeriodReports;
