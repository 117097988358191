import { reactive, ref, computed } from "vue";
import { initialFilterState } from "../constants/initialFilterState";
import { inputToQueryStringDate } from "../../../helpers/strings";
import QueryString from "qs";

const initialExportFilterState = {
	...initialFilterState,
	fileType: "xlsx",
};

const useExportController = (fetcher) => {
	if (!fetcher) return;
	const isExportFormOpen = ref(false);

	const exportFilterState = reactive({ ...initialExportFilterState });

	const resetType = () => {
		exportFilterState.type = null;
	};

	const resetCompanies = () => {
		exportFilterState.companies = [];
		exportFilterState.accounts = [];
		exportFilterState.paymentTypes = [];
	};

	const resetEmployees = () => {
		exportFilterState.employees = [];
	};

	const openExportForm = (state) => {
		if (state) {
			Object.assign(exportFilterState, state);
		}

		isExportFormOpen.value = true;
	};

	const closeExportForm = () => {
		isExportFormOpen.value = false;
	};

	const resetExportFilterState = () => {
		Object.assign(exportFilterState, { ...initialExportFilterState });
	};

	const currentFilter = computed(() => {
		const result = {
			companies: exportFilterState.companies,
			accounts: exportFilterState.accounts,
			payment_types: exportFilterState.paymentTypes,
			employees: exportFilterState.employees,
			file_type: exportFilterState.fileType,
		};

		if (
			exportFilterState.period &&
			exportFilterState.periodFrom &&
			exportFilterState.periodTo
		) {
			result.from_period = inputToQueryStringDate(exportFilterState.periodFrom);
			result.to_period = `${inputToQueryStringDate(exportFilterState.periodTo)} 23:59:59`;
		}

		if (exportFilterState.type) {
			result.type = exportFilterState.type;
		}

		return result;
	});

	const submitExport = async () => {
		const response = await fetcher(
			QueryString.stringify(currentFilter.value, { arrayFormat: "brackets" })
		);

		if (response) {
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `report.${exportFilterState.fileType}`);
			document.body.appendChild(link);
			link.click();
		} 

	};

	

	const companyChangeHandler = ({ val: isAddding, company } = {}) => {
		if (isAddding) {
			exportFilterState.accounts = [
				...new Set([
					...exportFilterState.accounts,
					...company.accounts.map((account) => account.id),
				]),
			];
		}

		if (!isAddding) {
			const companyAccounts = company.accounts.map((account) => account.id);

			exportFilterState.accounts = exportFilterState.accounts.filter(
				(accountId) => !companyAccounts.includes(accountId)
			);
		}

		company.accounts.forEach((account) =>
			accountChangeHandler({ val: isAddding, company, account })
		);
	};

	const accountChangeHandler = ({ val: isAddding, company, account } = {}) => {
		if (isAddding) {
			if (!exportFilterState.companies.includes(company.id)) {
				exportFilterState.companies = [
					...exportFilterState.companies,
					company.id,
				];
			}

			exportFilterState.paymentTypes = [
				...new Set([
					...exportFilterState.paymentTypes,
					...account.payment_types.map((type) => type.id),
				]),
			];
		}
		if (!isAddding) {
			const accountPaymentTypes = account.payment_types.map((type) => type.id);
			exportFilterState.paymentTypes = exportFilterState.paymentTypes.filter(
				(id) => !accountPaymentTypes.includes(id)
			);

			const companyAccounts = company.accounts.map((account) => account.id);
			if (
				!exportFilterState.accounts.some((accountId) =>
					companyAccounts.includes(accountId)
				)
			) {
				exportFilterState.companies = exportFilterState.companies.filter(
					(companyId) => companyId !== company.id
				);
			}
		}
	};

	const paymentTypeChangeHandler = ({
		val: isAddding,
		company,
		account,
	} = {}) => {
		if (isAddding) {
			if (!exportFilterState.companies.includes(company.id)) {
				exportFilterState.companies = [
					...exportFilterState.companies,
					company.id,
				];
			}
			if (!exportFilterState.accounts.includes(account.id)) {
				exportFilterState.accounts = [
					...exportFilterState.accounts,
					account.id,
				];
			}
		}

		if (!isAddding) {
			const accountPaymentTypes = account.payment_types.map((type) => type.id);
			if (
				!exportFilterState.paymentTypes.some((paymentTypeId) =>
					accountPaymentTypes.includes(paymentTypeId)
				)
			) {
				exportFilterState.accounts = exportFilterState.accounts.filter(
					(accountId) => accountId !== account.id
				);
				accountChangeHandler({ val: false, company, account });
			}
		}
	};

	const datesChangeHandler = () => {
		if (exportFilterState.periodFrom && exportFilterState.periodTo) {
			exportFilterState.period = true;
		}
	};

	const periodChangeHandler = () => {
		exportFilterState.periodFrom = "";
		exportFilterState.periodTo = "";
	};

	return {
		exportFilterState,

		isExportFormOpen,

		openExportForm,
		closeExportForm,

		resetType,
		resetCompanies,
		resetEmployees,

		submitExport,
		resetExportFilterState,
		accountChangeHandler,
		companyChangeHandler,
		paymentTypeChangeHandler,

		datesChangeHandler,
		periodChangeHandler,
	};
};

export default useExportController;
