<template>
	<teleport to="#modal-container">
		<div v-if="open" class="modal">
			<div class="modal__backdrop" @click="$emit('close')"></div>
			<div class="modal__content" ref="content">
				<div class="modal__close" @click="$emit('close')" v-if="modalClose">
					<span>Close</span>
					<CrossIcon />
				</div>
				<div class="modal__inner">
					<slot></slot>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script setup>
import CrossIcon from "@/assets/icons/cross.svg";
import { computed, ref } from "vue";
import useBodyScroll from "../../hooks/useBodyScroll";

const props = defineProps({
	open: {
		type: Boolean,
		default: false,
	},
	modalClose: {
		type: Boolean,
		default: true,
	},
});

defineEmits(["close"]);

const content = ref(null);

useBodyScroll(content, computed(() => props.open));
</script>

<style lang="scss" src="@/assets/scss/components/modal.scss"></style>
