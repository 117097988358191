import usersApiController from '../../api/usersApiController';
import { onMounted, reactive, toRefs } from 'vue';

const useGetUserData = (userId) => {
  if (!userId) return;

	const state = reactive({
		loading: false,
		userData: null,
		error: null,
	});

	const fetchUser = async (id = userId) => {
		state.loading = true;

		try {
			const response = await usersApiController.getUser(id);
			state.userData = response.data;
		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	const refetch = () => {
		state.userData = null;
		fetchUser();
	};

	onMounted(() => {
		fetchUser();
	});

	return {
		...toRefs(state),
    refetch,
		fetchUser,
	};
};

export default useGetUserData;
