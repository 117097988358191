import {
	ADMIN_ROUTE_NAMES,
	ONBOARDING_ROUTE_NAMES,
	USER_ROUTE_NAMES,
} from "../../constants/route-names";
import { USER_ROLES } from "../../constants/userRoles";
import EmployeeListIcon from "@/assets/icons/employee-list.svg";
import CompanyIcon from "@/assets/icons/company.svg";
import EployeeIcon from "@/assets/icons/employee.svg";
import ReportsIcon from "@/assets/icons/reports.svg";
import HistoryIcon from "@/assets/icons/history.svg";
import CompaniesIcon from "@/assets/icons/company.svg";
import EmployeeHistoryIcon from "@/assets/icons/employee-history.svg";
import StorageIcon from "@/assets/icons/storage.svg";
import { useAuth } from "../../store/auth";
import { computed } from "vue";

const useNavigationItems = () => {
	const auth = useAuth();

	return computed(() => {
		const navigationItems = {
			[USER_ROLES.ADMIN]: [
				{
					name: ADMIN_ROUTE_NAMES.COMPANIES_LIST,
					text: "Companies",
					icon: CompanyIcon,
				},
				{
					name: ADMIN_ROUTE_NAMES.EMLOYEES_LIST,
					text: "Employees",
					icon: EployeeIcon,
				},
			],
			[USER_ROLES.ACCOUNTANT]: [
				{
					name: USER_ROUTE_NAMES.USER_REPORTS,
					text: "My reports",
					icon: ReportsIcon,
				},
				{
					name: USER_ROUTE_NAMES.USER_HISTORY,
					text: "My History",
					icon: HistoryIcon,
				},
				{
					name: USER_ROUTE_NAMES.EMPLOYEE_REPORTS,
					text: "Employee Reports",
					icon: EmployeeListIcon,
				},
				{
					name: USER_ROUTE_NAMES.EMPLOYEE_HISTORY,
					text: "Employee History",
					icon: EmployeeHistoryIcon,
				},
				{
					name: USER_ROUTE_NAMES.USER_COMPANIES,
					text: "Companies",
					icon: CompaniesIcon,
				},
				{
					name: USER_ROUTE_NAMES.CLOUD_STORAGE,
					text: "Cloud Storage",
					icon: StorageIcon,
					disabled: !auth.profile.has_access_cloud_storage,
				},
			],
			[USER_ROLES.MANAGER]: [
				{
					name: USER_ROUTE_NAMES.USER_REPORTS,
					text: "My reports",
					icon: ReportsIcon,
				},
				{
					name: USER_ROUTE_NAMES.USER_HISTORY,
					text: "My History",
					icon: HistoryIcon,
				},
				{
					name: USER_ROUTE_NAMES.EMPLOYEE_REPORTS,
					text: "Employee Reports",
					icon: EmployeeListIcon,
				},
				{
					name: USER_ROUTE_NAMES.EMPLOYEE_HISTORY,
					text: "Employee History",
					icon: EmployeeHistoryIcon,
				},
			],
			onboarding: [
				{
					name: ONBOARDING_ROUTE_NAMES.COMPANIES_NEW,
					text: "STEP 1-CREATE COMPANIES",
					icon: CompanyIcon,
				},
				{
					name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_NEW,
					text: "STEP 2-CREATE EMPLOYEES",
					icon: EployeeIcon,
				},
			],
			[USER_ROLES.EMPLOYEE]: [
				{
					name: USER_ROUTE_NAMES.USER_REPORTS,
					text: "My reports",
					icon: ReportsIcon,
				},
				{
					name: USER_ROUTE_NAMES.USER_HISTORY,
					text: "My History",
					icon: HistoryIcon,
				},
			],
		};

		const filtered = {
			...navigationItems,
			[USER_ROLES.ACCOUNTANT]: navigationItems[USER_ROLES.ACCOUNTANT].filter(
				(item) => !item.disabled
			),
		};

		return filtered;
	});
};

export default useNavigationItems;
