import {ref} from 'vue';
import accountsApiController from '../../../api/accounts/accountsApiController';

const useUpdateAccount = () => {
  const loading = ref(false);

  const updateAccount = async (id, data) => {
    loading.value = true;

    const response = await accountsApiController.updateAccount(id, data);

    loading.value = false;
    return response;
  };

  return {
    loading,
    updateAccount,
  };
};

export default useUpdateAccount;
