import GeneralApiController from './GeneralApiController.js';

class CompaniesApiController extends GeneralApiController {

	getCompanies = () => this.http.get('companies');

	getCompany = (id) => this.http.get(`companies/${id}`);

	createCompany = (data) => this.http.post('companies', data);

	updateCompany = (id, data) => this.http.patch(`companies/${id}`, data);

	deleteCompany = (id) => this.http.delete(`companies/${id}`);
}

const companiesApiController = new CompaniesApiController();

export default companiesApiController;
