<template>
	<NotificationsManager />
	<ConfirmationManager />
	<Preloader v-if="auth.isProfileLoading" />
	<AppLayout v-else>
		<router-view></router-view>
	</AppLayout>
</template>

<script setup>
import NotificationsManager from "./components/notifications/NotificationsManager.vue";
import AppLayout from "./components/layouts/AppLayout.vue";
import { useAuth } from "./store/auth";
import ConfirmationManager from "./components/base/ConfirmationManager.vue";
import Preloader from "./components/base/Preloader.vue";
import { watch } from "vue";
import { useRoute } from "vue-router";
import useWindowResize from "./hooks/useWindowResize";

const route = useRoute();
const auth = useAuth();
useWindowResize();

watch(
	() => route.meta.pageTitle,
	() => {
		document.title = route.meta.pageTitle;
	}
);
</script>
