import { computed, reactive, toRefs } from "vue";
import storageApiController from "../../../api/storageApiController";
import useNotifications from "../../../store/notifications";

const MAX_STORAGE_SIZE = 5368709120;

const useStorageController = () => {
	const { showNotification } = useNotifications();

	const state = reactive({
		loading: false,
		folders: null,
		createFolderModalOpen: false,
		isCreating: false,
		currentFolderId: null,
		editFolderNameModalOpen: false,
		isUpdatingFolderName: false,
		isUploading: false,
		downloadingFileId: null,
		searchText: "",
		currentFilesSort: "fn",

		editingFile: null,
		editingFileName: '',
		editingFileNameModalOpen: false,
		isFileEditing: false,
	});

	const resetSearch = () => (state.searchText = "");

	const getFoldersList = async () => {
		state.loading = true;
		const response = await storageApiController.getFolders();
		if (response.success) {
			state.folders = response.data;
		}
		state.loading = false;
	};

	getFoldersList();

	const hanldeCreateFolderModalClose = () => {
		if (!state.isCreating) {
			state.createFolderModalOpen = false;
		}
	};

	const createFolder = async (data) => {
		state.isCreating = true;
		const response = await storageApiController.createFolder(data);
		if (response.success) {
			getFoldersList();
		}
		state.isCreating = false;
		state.createFolderModalOpen = false;
	};

	const deleteFolder = async (id) => {
		state.loading = true;
		const response = await storageApiController.deleteFolder(id);
		if (response.success) {
			getFoldersList();
			return;
		}
		state.loading = false;
	};

	const setCurrentFolder = (id) => {
		state.currentFolderId = id;
	};

	const currentFolder = computed(() => {
		if (!state.currentFolderId || !state.folders) return null;

		const res = state.folders.find(
			(folder) => folder.id === Number(state.currentFolderId)
		);

		return res;
	});

	const updateFolderName = async (data) => {
		state.isUpdatingFolderName = true;
		const response = await storageApiController.updateFolder(
			state.currentFolderId,
			data
		);
		if (response.success) {
			getFoldersList();
			state.editFolderNameModalOpen = false;
		}
		state.isUpdatingFolderName = false;
	};

	const closeEditFolderNameModal = () => {
		if (!state.isUpdatingFolderName) {
			state.editFolderNameModalOpen = false;
		}
	};

	/**
	 *
	 * @param {InputEvent} param0
	 */
	const uploadFile = async ({ target }) => {
		const file = target.files?.[0];

		if (file.size > 1e7) {
			showNotification({
				type: "warning",
				message: "File is too large!",
			});
			return;
		}

		const formData = new FormData();
		formData.set("file", file);
		state.isUploading = true;

		const response = await storageApiController.uploadFile(
			currentFolder.value.id,
			formData
		);
		if (response.success) {
			showNotification({ message: `File ${file.name} uploaded!` });
		}

		target.value = "";
		state.isUploading = false;
		getFoldersList();
	};

	const deleteFile = async (file) => {
		state.loading = true;
		const response = await storageApiController.deleteFile(file.id);
		if (response.success) {
			showNotification({ message: `File ${file.name} deleted!` });
		}
		getFoldersList();
	};

	const downloadFile = async (file) => {
		state.downloadingFileId = file.id;
		const response = await storageApiController.downloadFile(file.id);

		if (response) {
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", file.name);
			document.body.appendChild(link);
			link.click();
			state.downloadingFileId = null;
		}
	};

	const storageSpace = computed(() => {
		const result = {
			percent: 0,
			text: "",
		};
		if (state.folders) {
			const size = state.folders.reduce((acc, folder) => acc + folder.size, 0);
			result.percent = (size / MAX_STORAGE_SIZE) * 100;
			result.text =
				size > MAX_STORAGE_SIZE
					? `${parseInt(size / 1073741824)} GB`
					: `${parseInt(size / 1048576)} MB`;
		}
		return result;
	});

	const processedFolders = computed(() => {
		if (state.searchText && state.folders) {
			return state.folders.filter((folder) =>
				folder.name.toLowerCase().includes(state.searchText.toLowerCase())
			);
		}
		return state.folders;
	});

	const processedFiles = computed(() => {
		if (currentFolder.value.files) {
			let result = [...currentFolder.value.files];

			if (state.searchText) {
				result = result.filter((file) =>
					file.name.toLowerCase().includes(state.searchText.toLowerCase())
				);
			}

			result.sort((fileA, fileB) => {
				if (state.currentFilesSort === "fn") {
					return new Date(fileB.created_at) - new Date(fileA.created_at);
				} else if (state.currentFilesSort === "fo") {
					return new Date(fileA.created_at) - new Date(fileB.created_at);
				}
			});

			return result;
		}
		return currentFolder.value.files;
	});

	const updateFile = async (data) => {
		state.isFileEditing = true;

		const result = {
			name: data.name + "." + state.editingFile.name.split('.').pop()
		}

		const response = await storageApiController.updateFile(state.editingFile.id, result);
		if (response.success) {
			getFoldersList();
			state.editingFileNameModalOpen = false;
			state.editingFile = null;
		}
		state.isFileEditing = false;
	}

	const closeEditFileNameModal = () => {
		if (!state.isFileEditing) {
			state.editingFileNameModalOpen = false;
			state.editingFile = null;
		}
	}

	const editFileName = (file) => {
		state.editingFile = file;
		state.editingFileName = file.name.split('.').slice(0, -1).join('')
		state.editingFileNameModalOpen = true;
	}

	return {
		...toRefs(state),
		getFoldersList,
		createFolder,
		deleteFolder,
		updateFolderName,

		hanldeCreateFolderModalClose,
		closeEditFolderNameModal,

		currentFolder,
		setCurrentFolder,

		uploadFile,
		deleteFile,
		downloadFile,

		storageSpace,

		resetSearch,
		processedFolders,
		processedFiles,

		updateFile,
		closeEditFileNameModal,
		editFileName,
	};
};

export default useStorageController;
