<template>
	<div style="border-top-left-radius: 8px;
    border-top-right-radius: 8px; overflow-x: auto;padding-bottom: 80px;flex:1;">
	<table class="table">
		<thead class="table-head">
			<tr>
				<TableHeadCell title="Number" />
				<TableHeadCell title="Date" :filtered="activeFilters.period" />
				<TableHeadCell title="Expense/<br />Income" :filtered="activeFilters.type" />
				<TableHeadCell title="Employee" :filtered="activeFilters.employees" />
				<TableHeadCell title="Company name" :filtered="activeFilters.company" />
				<TableHeadCell title="Account" :filtered="activeFilters.account" />
				<TableHeadCell title="Amount" />
				<TableHeadCell title="Currency" :filtered="activeFilters.currency" />
				<TableHeadCell title="Type of<br />payment" :filtered="activeFilters.paymentType" />
				<TableHeadCell title="Expenses type" />
				<TableHeadCell title="Description" style="width: 20%;" />
			</tr>
		</thead>
		<tbody ref="tableEl" class="table-body">
			<tr v-for="report in reports" :key="`report-${report.id}`">
				<TableCell>{{ report.id }}</TableCell>
				<TableCell>{{ responseDateToDisplay(report.reported_at) }}</TableCell>
				<TableCell>{{ capitalize(report.type) }}</TableCell>
				<TableCell>{{ report.reporter.name }}</TableCell>
				<TableCell>{{ report.company.name }}</TableCell>
				<TableCell>{{ report.account.name }}</TableCell>
				<TableCell>{{ report.amount }}</TableCell>
				<TableCell>{{ report.currency.name }}</TableCell>
				<TableCell>{{ report.payment_type.name }}</TableCell>
				<TableCell>{{ report.expense?.name ? report.expense.name : '---' }}</TableCell>
				<TableCell clamp popover>{{ report.description }}</TableCell>
			</tr>
		</tbody>
	</table>
	</div>
</template>

<script setup>
import TableCell from "../../../components/TableCell.vue";
import { responseDateToDisplay, capitalize } from "@/helpers/strings";
import { inject } from "vue";
import {
	FILTER_CONTROLLER_KEY,
	TABLE_EL_KEY,
} from "@/views/user/constants/injectionKeys";
import TableHeadCell from "@/views/user/components/TableHeadCell.vue";

const tableEl = inject(TABLE_EL_KEY);
const { activeFilters } = inject(FILTER_CONTROLLER_KEY);

defineProps({
	reports: {
		type: Array,
		required: true,
	},
});
</script>
