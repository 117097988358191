<template>
  <div class="layout">
    <div class="layout__col layout__col--2of4">
      <div class="company-name">
        <FormGroup stroke label="Company name">
          <PrimaryInput
              :value="company.name"
              v-model="company.name"
              placeholder="Type company name"
              type="text"
              id="companyName"
          ></PrimaryInput>
        </FormGroup>
        <div class="text-hints">
          <p>Add the company`s main account and additional accounts if needed.</p>
          <p>Additional accounts are necessary to limit access of some users to certain financial operations of the
            company.</p>
          <p>Reports for managers and accountants are available only for the accounts to which they have access.</p>
        </div>
        <AppButton @click="submitHandlerEmit" text="CONFIRM" expand></AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/base/AppButton";
import FormGroup from "@/components/form-components/FormGroup";
import PrimaryInput from "@/components/form-components/PrimaryInput";

export default {
  emits: ['submit'],
	props: {
		companyData: {
			type: Object,
			default: null,
		},
	},
  components: {
		AppButton,
		FormGroup, PrimaryInput,
	},
  created() {
		if (this.companyData) {
			this.company = this.companyData;
		} 
	},
  methods: {
		submitHandlerEmit() {
			this.$emit('submit')
		}
	},
} 
</script>