import { ref } from 'vue';
import companiesApiController from '../../api/companiesApiController';

const useUpdateCompany = () => {
  const loading = ref(false);

  const updateCompany= async (id, data) => {
    loading.value = true;

    const response = await companiesApiController.updateCompany(id, data);

    loading.value = false;
    return response;
  };

  return {
    loading,
    updateCompany,
  };
};

export default useUpdateCompany;
