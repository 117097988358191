<template>
	<div class="form">
		<div class="layout">
			<div class="layout__col layout__col--1of4">
				<FormGroup
					label="Full Name"
					stroke
					:error="$v.name.$error"
					:error-message="getErrorMessages($v.name.$errors)"
				>
					<PrimaryInputVue placeholder="John Smith" v-model="formState.name" />
				</FormGroup>
				<FormGroup
					label="Username"
					stroke
					:error="$v.username.$error"
					:error-message="getErrorMessages($v.username.$errors)"
				>
					<PrimaryInputVue
						placeholder="username"
						v-model="formState.username"
					/>
				</FormGroup>
				<FormGroup
					label="Password"
					stroke
					:error="$v.password.$error"
					:error-message="getErrorMessages($v.password.$errors)"
				>
					<PrimaryInputVue
						type="password"
						placeholder="******"
						v-model="formState.password"
					/>
				</FormGroup>
				<FormGroup
					label="Confirm Password"
					stroke
					:error="$v.password_confirmation.$error"
					:error-message="getErrorMessages($v.password_confirmation.$errors)"
				>
					<PrimaryInputVue
						type="password"
						placeholder="******"
						v-model="formState.password_confirmation"
					/>
				</FormGroup>
				<FormGroup
					label="Role"
					stroke
					:error="$v.role.$error"
					:error-message="getErrorMessages($v.role.$errors)"
				>
					<PrimarySelect
						v-model="formState.role"
						:options="[
							USER_ROLES.MANAGER,
							USER_ROLES.ACCOUNTANT,
							USER_ROLES.EMPLOYEE,
						]"
					/>
				</FormGroup>
				<div class="hold-switch" v-if="employee">
					<div class="hold-switch__text">Give access</div>
					<Switcher
						class="hold-switch__switch"
						show
						:is-checked="!formState.is_on_hold"
						@submit="(val) => (formState.is_on_hold = !val)"
					/>
				</div>
				<p class="desc" v-if="employee">
					If you deactivate the toggle switch, the user can not log in or use
					the service.
				</p>
				<div
					class="storage-switch"
					v-if="formState.role === USER_ROLES.ACCOUNTANT"
				>
					<div class="storage-switch__title">
						<StorageIcon class="storage-switch__icon" />
						<div class="storage-switch__text">
							Provide access to cloud storage
						</div>
					</div>
					<Switcher
						class="storage-switch__switch"
						show
						:is-checked="formState.has_access_cloud_storage"
						@submit="(val) => (formState.has_access_cloud_storage = val)"
					/>
				</div>
				<p class="tooltio">All fields are required</p>
				<AppButton
					text="Confirm"
					expand
					@click="submitHandler"
					class="form__submit"
					:loading="loading"
				/>
			</div>
			<div class="layout__col layout__col--3of4">
				<EmployeeCompaniesForm
					:employee-id="employee && employee.id"
					:companies="companies"
					v-model:selected-companies="selectedCompanies"
					v-model:selected-accounts="selectedAccounts"
					v-model:selected-employees="selectedEmployees"
					:user-role="formState.role"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import FormGroup from "@/components/form-components/FormGroup.vue";
import PrimaryInputVue from "@/components/form-components/PrimaryInput.vue";
import useSettingsForm from "../hooks/useSettingsForm";
import AppButton from "../../../../components/base/AppButton.vue";
import { getErrorMessages } from "@/helpers/strings";
import PrimarySelect from "../../../../components/form-components/PrimarySelect.vue";
import { USER_ROLES } from "../../../../constants/userRoles";
import EmployeeCompaniesForm from "./EmployeeCompaniesForm.vue";
import useEmployeeCompanyState from "../hooks/useEmployeeCompanyState";
import Switcher from "../../../../components/form-components/Switcher.vue";
import StorageIcon from "@/assets/icons/storage.svg";
import useNotifications from "../../../../store/notifications";

const props = defineProps({
	companies: {
		type: Array,
		required: true,
	},
	employee: {
		type: Object,
		default: null,
	},
	loading: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["submit"]);
const { showNotification } = useNotifications();
const { formState, $v } = useSettingsForm(props.employee);
const { selectedCompanies, selectedAccounts, selectedEmployees } =
	useEmployeeCompanyState(props.employee);

const submitHandler = () => {
	$v.value.$touch();
	if (!$v.value.$invalid) {
		const accountsAdded = selectedCompanies.value.every((companyId) => {
			const currentCompany = props.companies.find(
				(company) => company.id === companyId
			);
			const currentCompanyAccounts = currentCompany.accounts.map(
				(account) => account.id
			);
			return selectedAccounts.value.some((accountId) =>
				currentCompanyAccounts.includes(accountId)
			);
		});

		if (!accountsAdded) {
			showNotification({
				type: "warning",
				message: "You should add accounts to selected companies!",
			});
			return;
		}

		const fields = {};

		fields.name = formState.name;
		fields.username = formState.username;
		fields.role = formState.role;
		fields.has_access_cloud_storage = formState.has_access_cloud_storage;

		if (props.employee) {
			fields.is_on_hold = formState.is_on_hold;

			if (formState.password) {
				fields.password = formState.password;
				fields.password_confirmation = formState.password_confirmation;
			}
		} else {
			fields.password = formState.password;
			fields.password_confirmation = formState.password_confirmation;
		}

		emit("submit", {
			...fields,
			accounts: [...selectedAccounts.value],
			employees: [...selectedEmployees.value],
		});
	}
};
</script>

<style lang="scss" scoped>
.form {
	margin-top: 32px;
}
.form__submit {
	margin-top: 32px;
}

.hold-switch {
	display: flex;
	align-items: center;
	margin-top: 32px;

	&__text {
		font-weight: 500;
		font-size: 16px;
		margin-right: 16px;
	}
}

.storage-switch {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 66px;
	padding: 0 24px;
	margin-top: 32px;

	border: 1px solid $primary;
	border-radius: 10px;

	&__title {
		display: flex;
		align-items: center;
	}

	&__icon {
		width: 24px;
		height: 24px;
		color: $primary;
	}

	&__text {
		margin-left: 8px;
		font-weight: 500;
		font-size: 16px;
		color: $primary;
		margin-right:15px;
	}
}
.tooltio {
	color: #f93b3b;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	margin-top: 16px;
}
.desc {
	margin-top: 20px;
	color: $grayDark;
}
</style>
