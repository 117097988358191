<template>
	<PageLayout
		title="My financial reports"
		transparent-preloader
		:loading="isCreating || reportsLoading || isDeleting || isEditing"
	>
		<template #controls>
			<ReportsControls
				show-create
				show-filter
				@create-new="handleCreateNew"
			/>
		</template>

		<TableFilter />

		<UserReportsTable
			v-if="reports"
			:reports="reports"
			:show-form="showCreateReportForm"
			@create-report="handleCreateReport"
			@delete="handleReportDelete"
			@edit="handleReportEdit"
		/>
	</PageLayout>
</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout.vue";
import { ref } from "vue";
import useCreateReport from "../../../../hooks/reports/useCreateReport";
import useNotifications from "../../../../store/notifications";
import ReportsControls from "../../components/ReportsControls.vue";
import UserReportsTable from "./components/UserReportsTable.vue";
import useDeleteReport from "../../../../hooks/reports/useDeleteReport";
import useEditReport from "../../../../hooks/reports/useEditReport";
import useReportTable from "../../hooks/useReportTable";
import TableFilter from "../../components/TableFilter.vue";
import reportsApiController from "@/api/reportsApiController";
import useWindowResize from "@/hooks/useWindowResize";
import { useRouter } from "vue-router";
import { USER_ROUTE_NAMES } from "../../../../constants/route-names";


const { showNotification } = useNotifications();
const { isMobile } = useWindowResize();
const router = useRouter();

const {
	loading: reportsLoading,
	reports,
	refetchReports,
} = useReportTable(reportsApiController.getReports);

const showCreateReportForm = ref(false);

const { loading: isCreating, createReport } = useCreateReport();
const { loading: isDeleting, deleteReport } = useDeleteReport();
const { loading: isEditing, editReport } = useEditReport();

const handleCreateReport = async (report) => {
	const response = await createReport(report);
	if (response.data.success) {
		showNotification({
			message: "New financial report successfully added!",
		});
		showCreateReportForm.value = false;
		await refetchReports();
	}
};

const handleReportDelete = async (id) => {
	const response = await deleteReport(id);
	if (response.data.success) {
		await refetchReports();
	}
};

const handleReportEdit = async (options) => {
	const response = await editReport(options.id, options.data);
	if (response.data.success) {
		await refetchReports();
	}
};

const handleCreateNew = () => {
	if (isMobile.value) {
		router.push({ name: USER_ROUTE_NAMES.NEW_REPORT })
	} else {
		showCreateReportForm.value = true
	}
}

</script>
