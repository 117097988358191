<template>
  <input
    :value="modelValue"
    :type="currentType"
    @input="event => $emit('update:modelValue', event.target.value)"
    :placeholder="placeholder"
    v-bind="$attrs"
  />
  <div v-if="props.type === 'password'" class="pass-icon" @click="showPass = !showPass">
    <passIcon v-if="showPass"></passIcon>
    <passIconOpen v-else></passIconOpen>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import passIcon from "/public/img/pass-icon.svg";
import passIconOpen from "/public/img/pass-open.svg";

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  }
})

const showPass = ref(true)

const currentType = computed(() => {
  if (props.type === 'password') {
    return showPass.value ? 'password' : 'text'
  }
  return props.type
})

</script>
