import { provide } from "vue";
import qs from "qs";
import useFilterController from "./useFilterController";
import {
	EXPORT_CONTROLLER_KEY,
	FILTER_CONTROLLER_KEY,
	TABLE_CONTROLLER_KEY,
	TABLE_EL_KEY,
	TABLE_CONFIG_KEY,
} from "../constants/injectionKeys";
import useReports from "./useReports";
import useTablePagination from "./useTablePagination";
import useConfirmation from "@/store/confirmation";
import useExportController from "./useExportController";

const useReportTable = (fetcher, exportFetcher, { employeesActive } = {}) => {
	const { showConfirmation } = useConfirmation();
	const { loading, reports, fetch, meta } = useReports(fetcher);
	let currentFilter = null;

	const onFilterSubmit = () => {
		const stringified = JSON.stringify(filterController.currentFilter.value);
		if (currentFilter !== stringified) {
			currentFilter = stringified;
			refetchReports();
		}
	};

	const onPageRequest = () => {
		fetchReports();
	};

	const { paginationState, tableEl, resetPage } = useTablePagination({
		loading,
		onPageRequest,
		meta,
	});

	const filterController = useFilterController(onFilterSubmit);

	const fetchReports = (options) => {
		const query = { page: paginationState.page };

		if (filterController.isFiltered.value) {
			Object.assign(query, { ...filterController.currentFilter.value });
		}

		const queryString = qs.stringify(query, { arrayFormat: "brackets" });

		return fetch(queryString, options);
	};

	const refetchReports = () => {
		resetPage();
		fetchReports({ update: true });
	};

	const refetchWithConfirmation = () => {
		showConfirmation({
			title: "Update data?",
			onSubmit: refetchReports,
		});
	};

	const tableController = {
		refetchWithConfirmation,
	};

	const exportController = useExportController(exportFetcher);

	provide(FILTER_CONTROLLER_KEY, filterController);
	provide(TABLE_EL_KEY, tableEl);
	provide(TABLE_CONTROLLER_KEY, tableController);
	provide(EXPORT_CONTROLLER_KEY, exportController);
	provide(TABLE_CONFIG_KEY, { employeesActive });

	fetchReports();

	return {
		loading,
		reports,
		filterController,
		refetchReports,
		refetchWithConfirmation,
		tableEl,
	};
};

export default useReportTable;
