import useVuelidate from "@vuelidate/core";
import {
	not,
	required,
	numeric,
	minValue,
	sameAs,
} from "@vuelidate/validators";
import { reactive, watch, computed } from "vue";
import { inputToRequestDate, objToInputDate } from "../../../helpers/strings";
import useCompaniesList from "../../../hooks/companies/useCompaniesList";

const useReportFormRow = (initialReport) => {
	const { companies, loading: companiesLoading } = useCompaniesList();

	const formState = reactive({
		reported_at: objToInputDate(new Date()),
		type: "expense",
		company_id: "",
		account_id: "",
		amount: "",
		currency_id: "",
		payment_type_id: "",
		expense_id: "",
		description: "",
	});

	if (initialReport) {
		formState.reported_at = objToInputDate(new Date(initialReport.reported_at));
		formState.type = initialReport.type;
		formState.company_id = initialReport.company.id;
		formState.account_id = initialReport.account.id;
		formState.amount = initialReport.amount;
		formState.currency_id = initialReport.currency.id;
		formState.payment_type_id = initialReport.payment_type.id;
		formState.expense_id = initialReport.expense?.id || "";
		formState.description = initialReport.description;
	}

	const selectedCompany = computed(() => {
		if (companies.value) {
			return companies.value.find(
				(company) => company.id === formState.company_id
			);
		}
		return null;
	});

	const rules = {
		reported_at: required,
		type: required,
		company_id: {
			required,
			selected: not(sameAs("")),
		},
		account_id: {
			required,
			selected: not(sameAs("")),
		},
		amount: {
			required,
			numeric,
			minValue: minValue(0.00000001),
		},
		currency_id: {
			required,
			selected: not(sameAs("")),
		},
		payment_type_id: {
			required,
			selected: not(sameAs("")),
		},
		expense_id: {
			selected: not(sameAs("")),
		},
		description: {
			required,
		},
	};

	const $v = useVuelidate(rules, formState);

	const selectedAccount = computed(() => {
		if (formState.account_id && selectedCompany.value) {
			return selectedCompany.value.accounts.find(
				(account) => account.id === formState.account_id
			);
		}
		return null;
	});

	const getFormattedValues = () => {
		const result = {
			reported_at: inputToRequestDate(
				formState.reported_at,
				initialReport ? initialReport.reported_at : null
			),
			amount: formState.amount.toFixed(8),
			type: formState.type,
			description: formState.description,
			currency_id: formState.currency_id,
			payment_type_id: formState.payment_type_id,
			expense_id: formState.expense_id,
		};

		if (formState.description) {
			result.description = formState.description;
		}

		return result;
	};

	const activeCompanies = computed(() => {
		if (companies.value) {
			const result = companies.value.filter((company) => !!company.is_active);
			if (
				initialReport &&
				!result.find((company) => company.id === initialReport.company.id)
			) {
				result.push(initialReport.company);
			}

			return result;
		}
		return null;
	});

	const activeAccounts = computed(() => {
		if (selectedCompany.value) {
			const result = selectedCompany.value.accounts.filter(
				(account) => !!account.is_active
			);

			if (
				initialReport &&
				!result.find((account) => account.id === initialReport.account.id) &&
				selectedCompany.value.accounts.find(
					(account) => account.id === initialReport.account.id
				)
			) {
				result.push(initialReport.account);
			}

			return result;
		}
		return null;
	});

	const activeCurrencies = computed(() => {
		if (selectedAccount.value) {
			const result = selectedAccount.value.currencies.filter(
				(currency) => !!currency.is_active
			);

			if (
				initialReport &&
				selectedAccount.value.currencies.find(
					(currency) => currency.id === initialReport.currency.id
				) &&
				!result.find((currency) => currency.id === initialReport.currency.id)
			) {
				result.push(initialReport.currency);
			}

			return result;
		}
		return null;
	});

	const activePaymentTypes = computed(() => {
		if (selectedAccount.value) {
			const result = selectedAccount.value.payment_types.filter(
				(payment_type) => !!payment_type.is_active
			);

			if (
				initialReport &&
				selectedAccount.value.payment_types.find(
					(payment_type) => payment_type.id === initialReport.payment_type.id
				) &&
				!result.find(
					(payment_type) => payment_type.id === initialReport.payment_type.id
				)
			) {
				result.push(initialReport.payment_type);
			}

			return result;
		}
		return null;
	});

	const activeExpenseTypes = computed(() => {
		if (selectedAccount.value) {
			const result = selectedAccount.value.expenses.filter(
				(expense_type) => !!expense_type.is_active
			);
			if (
				initialReport &&
				selectedAccount.value.expenses.find(
					(expense_type) => expense_type.id === initialReport.expense?.id
				) &&
				!result.find(
					(expense_type) => expense_type.id === initialReport.expense?.id
				)
			) {
				result.push(initialReport.expense);
			}

			return result;
		}
		return null;
	});

	watch(companies, () => {
		if (companies.value && companies.value.length === 1) {
			formState.company_id = companies.value[0].id;
		}
	});

	watch(
		() => formState.company_id,
		() => {
			if (selectedCompany.value) {
				if (activeAccounts.value.length === 1) {
					formState.account_id = activeAccounts.value[0].id;
				} else {
					formState.account_id = "";
				}
				formState.currency_id = "";
				formState.payment_type_id = "";
				formState.expense_id = "";
			}
		}
	);

	watch(
		() => formState.account_id,
		() => {
			if (selectedAccount.value) {
				formState.currency_id = "";
				formState.payment_type_id = "";
				formState.expense_id = "";

				if (activeCurrencies.value.length === 1) {
					formState.currency_id = activeCurrencies.value[0].id;
				}
				if (activePaymentTypes.value.length === 1) {
					formState.payment_type_id = activePaymentTypes.value[0].id;
				}
				if (activeExpenseTypes.value.length === 1) {
					formState.expense_id = activeExpenseTypes.value[0].id;
				}
			}
		}
	);

	return {
		formState,
		$v,

		companies,
		companiesLoading,

		activeCompanies,
		activeAccounts,
		activeCurrencies,
		activePaymentTypes,
		activeExpenseTypes,

		selectedCompany,

		selectedAccount,
		getFormattedValues,
	};
};

export default useReportFormRow;
