<template>
	<PageLayout
			title="Employees"
			desc="Create employees, add companies and accounts to them"
			:loading="companiesLoading"
	>
	  <RouterLink custom :to="{ name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_LIST }" v-slot="{ href, navigate }" v-if="employees && employees.length > 0">
		  <Button back text="BACK TO EMPLOYEES LIST" :href="href" @click="navigate" style = "margin-top:-20px;margin-bottom: 20px;">
			  <template v-slot:icon>
				  <BackIcon/>
			  </template>
		  </Button>
	  </RouterLink>
		<h2>Add a new employee</h2>

		<EmployeeSettingsForm
				v-if="companies"
				:companies="companies"
				:loading="isSaving"
				@submit="submitHandler"
		/>
	</PageLayout>
</template>

<script setup>
import {useRouter} from 'vue-router';
import PageLayout from '@/components/layouts/PageLayout.vue';
import Button from "@/components/base/AppButton";
import BackIcon from "@/assets/icons/back.svg";
import {ONBOARDING_ROUTE_NAMES} from '@/constants/route-names';
import useCompaniesList from '@/hooks/companies/useCompaniesList';
import useCreateEmployee from '@/hooks/employees/useCreateEmployee';
import useNotifications from '@/store/notifications';
import EmployeeSettingsForm from '@/views/admin/employees/components/EmployeeSettingsForm.vue';
import useEmployeesList from "@/hooks/employees/useEmployeesList";

const {companies, loading: companiesLoading} = useCompaniesList();
const {employees} = useEmployeesList();
const {loading: isSaving, createEmployee} = useCreateEmployee();
const notifications = useNotifications();
const router = useRouter();


const submitHandler = async (data) => {
	const response = await createEmployee(data);
	if (response.success) {
		notifications.showNotification({
			message: 'Emloyee created!',
		});
		await router.push({name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_LIST});
	}
};
</script>
