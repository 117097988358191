import GeneralApiController from '../GeneralApiController';

class ExpenseTypesApiController extends GeneralApiController {
  createExpenseType = (id, data) => this.http.post(`accounts/${id}/expenses`, data);

  updateExpenseType = (id, data) => this.http.patch(`expenses/${id}`, data);

  deleteExpenseType = (id) => this.http.delete(`expenses/${id}`);
}

const expenseTypesApiController = new ExpenseTypesApiController();

export default expenseTypesApiController;
