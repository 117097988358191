import { reactive, toRef } from "vue";

const state = reactive({
	report: null,
});

const setCurrentReport = (report) => {
	state.report = report;
};

const removeCurrentReport = () => {
	state.report = null;
};

const useCurrentReport = () => ({
	report: toRef(state, "report"),
	setCurrentReport,
	removeCurrentReport,
});

export default useCurrentReport;
