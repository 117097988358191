const { ADMIN_ROUTE_NAMES } = require("../../constants/route-names");
import CompaniesList from "@/views/admin/companies/views/list/CompaniesList";
import CompaniesNew from "@/views/admin/companies/views/add/AddCompany";
import EditCompany from "@/views/admin/companies/views/edit/EditCompany";
import Delete from "@/views/admin/delete-reports/Delete";
import DeletePeriod from "@/views/admin/delete-reports/DeletePeriod";
import DeleteConfirm from "@/views/admin/delete-reports/DeleteConfirm";
import DeleteFinish from "@/views/admin/delete-reports/DeleteFinish";
import { USER_ROLES } from "../../constants/userRoles";

/** @type {import('vue-router').RouteRecordRaw[]} */
const companiesRoutes = [
	{
		path: "/companies",
		name: ADMIN_ROUTE_NAMES.COMPANIES_LIST,
		component: CompaniesList,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Companies",
		},
	},
	{
		path: "/companies/new",
		name: ADMIN_ROUTE_NAMES.COMPANIES_NEW,
		component: CompaniesNew,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Add new company",
		},
	},
	{
		path: "/companies/edit/:id",
		name: ADMIN_ROUTE_NAMES.COMPANIES_EDIT,
		component: EditCompany,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Edit company",
		},
	},
	{
		path: "/delete-reports",
		name: ADMIN_ROUTE_NAMES.REPORTS_DELETE,
		component: Delete,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Delete reports'
		}
	},
	{
		path: "/delete-reports/period",
		name: ADMIN_ROUTE_NAMES.REPORTS_DELETEPERIOD,
		component: DeletePeriod,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Delete reports'
		}
	},
	{
		path: "/delete-reports/confirm",
		name: ADMIN_ROUTE_NAMES.REPORTS_DELETEPASSWORDS,
		component: DeleteConfirm,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Confirm delete reports"
		}
	},
	{
		path: "/delete-reports/finish",
		name: ADMIN_ROUTE_NAMES.REPORTS_FINISH,
		component: DeleteFinish,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Reports was deleted'
		}
	},
];

export default companiesRoutes;
