export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getCompaniesCount = (count) =>
  `${count} ${count > 1 ? "companies" : "company"}`;

export const getEmployeesCount = (count) =>
  `${count} ${count > 1 ? "employees" : "employee"}`;

export const getErrorMessages = (errors) => {
  if (!errors) return "";
  return errors.map((error) => error.$message).join(", ");
};

/**
 *
 * @param { Date } date
 * @returns { String }
 */
export const objToInputDate = (date) => {
  return date.toISOString().substring(0, 10);
};

/**
 * @param { String } dateString
 * @param { String } initial
 * @returns { String }
 */
export const inputToRequestDate = (dateString, initial) => {
  const [year, month, day] = dateString.split("-");

  const { hours, minutes, seconds } = getTime(initial);

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

const getTime = (initial) => {

	const options = {
		timeZone: "Europe/Kiev",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	};

	const formatter = new Intl.DateTimeFormat([], options);

	const date = formatter.format(initial ? new Date(initial) : new Date());

	return {
		hours: date.split(":")[0],
		minutes: date.split(":")[1],
		seconds: date.split(":")[2],
	};

};

/**
 *
 * @param { String } dateString
 * @returns { String }
 */
export const inputToQueryStringDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

/**
 *
 * @param { String } dateString
 * @returns { String }
 */
export const responseDateToDisplay = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};
