import { reactive, toRef } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import { USER_ROLES } from "../../../../constants/userRoles";

const useSettingsForm = (employee) => {
	const formState = reactive({
		name: "",
		username: "",
		role: USER_ROLES.EMPLOYEE,
		password: "",
		password_confirmation: "",
		has_access_cloud_storage: false,
		is_on_hold: false,
	});

	if (employee) {
		formState.name = employee.name;
		formState.username = employee.username;
		formState.role = employee.roles[0];
		formState.is_on_hold = !!employee.is_on_hold;
		formState.has_access_cloud_storage = !!employee.has_access_cloud_storage;
	}

	const rules = {
		name: {
			required,
			minLength: minLength(2),
		},
		username: {
			required,
			minLength: minLength(6),
		},
		role: required,
		password: {
			required: employee ? false : required,
			minLength: minLength(8),
		},
		password_confirmation: {
			required: employee ? false : required,
			sameAs: sameAs(toRef(formState, "password")),
		},
	};

	const $v = useVuelidate(rules, formState);

	return {
		formState,
		$v,
	};
};

export default useSettingsForm;
