import {ONBOARDING_ROUTE_NAMES} from "@/constants/route-names";
import {USER_ROLES} from '@/constants/userRoles';

const CompaniesList = () => import ('@/views/admin/companies/views/list/CompaniesList.vue');
const CompaniesNew = () => import (  '@/views/admin/onboarding/step1.vue');
const EditCompany = () => import ( "@/views/admin/companies/views/edit/EditCompany.vue");

/** @type {import('vue-router').RouteRecordRaw[]} */
const step1Routes = [
	{
		path: '/onboarding/step1/companies',
		name: ONBOARDING_ROUTE_NAMES.COMPANIES_LIST,
		component: CompaniesList,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Step 1 - Companies'
		}
	},
	{
		path: '/onboarding/step1/companies/new',
		name: ONBOARDING_ROUTE_NAMES.COMPANIES_NEW,
		component: CompaniesNew,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Step 1 - Add new company'
		}
	},
	{
		path: '/onboarding/step1/companies/edit/:id',
		name: ONBOARDING_ROUTE_NAMES.COMPANIES_EDIT,
		component: EditCompany,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: 'Step 1 - Edit company'
		}
	},
]

export default step1Routes
