<template>
	<PageLayout>
		<h1>Error</h1>
		<h3 style="margin-bottom:15px;">You can't fill out reports because you don't have any companies. <br></h3>
		<h3 style="margin-top:15px;">For correct work of the service contact your administrator.</h3>
		<div class="btns">
			<RouterLink custom :to="{name: USER_ROUTE_NAMES.USER_REPORTS}" v-slot="{href, navigate}">
				<AppButton :href="href" @click="navigate" medium text="Back"/>
			</RouterLink>
			<AppButton outline text="Log out" medium style="margin-left:20px;" @click="logout"/>
		</div>

	</PageLayout>

</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout";
import AppButton from "@/components/base/AppButton";
import {useAuth} from "@/store/auth";
import {USER_ROUTE_NAMES} from "@/constants/route-names";
import useConfirmation from "@/store/confirmation";

const auth = useAuth();
const confirm = useConfirmation();

const logout = () => {
	confirm.showConfirmation({
		title: 'Log out?',
		text: 'Are you sure you want to log out?',
		onSubmit: () => {
			auth.logout()
		},
	});
};

</script>
