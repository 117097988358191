import GeneralApiController from "./GeneralApiController";

class UsersApiController extends GeneralApiController {
	getUsers = () => this.http.get("users");

	getUser = (id) => this.http.get(`users/${id}`);

	createUser = (data) => this.http.post("users", data);

	updateUser = (id, data) => this.http.patch(`users/${id}`, data);

	deleteUser = (id) => this.http.delete(`users/${id}`);

	getEmployees = () => this.http.get("employees");
}

const usersApiController = new UsersApiController();

export default usersApiController;
