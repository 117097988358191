<template>
	<label class="form-group" :class="classNames">
		<div class="label">
			{{ label }}
			<div v-if="error" class="form-group__error">
				<span>{{ errorMessage }}</span>
			</div>
		</div>
		<slot></slot>
	</label>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
	stroke: {
		type: Boolean,
		default: false,
	},
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: ''
  }
});

const classNames = computed(() => {
	return [
    props.stroke && 'form-group--stroke',
    props.error && 'form-group--error'
  ];
});
</script>


<style
	lang="scss"
	src="@/assets/scss/components/form-components/form-group.scss"
></style>
