import { computed, reactive, ref } from "vue";
import { inputToQueryStringDate } from "@/helpers/strings";
import { initialFilterState } from "../constants/initialFilterState";

/**
 * @param {(string) => {}} onSubmit
 */
const useFilterController = (onSubmit) => {
	const isFormOpen = ref(false);

	const isFiltered = ref(false);

	const filterState = reactive({ ...initialFilterState });

	const submittedFilterState = reactive({ ...initialFilterState });

	const showFilterForm = () => {
		isFormOpen.value = true;
	};

	const closeFilterForm = () => {
		Object.assign(filterState, { ...submittedFilterState });
		isFormOpen.value = false;
	};

	const currentFilter = computed(() => {
		const result = {
			companies: filterState.companies,
			accounts: filterState.accounts,
			payment_types: filterState.paymentTypes,
			employees: filterState.employees,
		};

		if (filterState.period && filterState.periodFrom && filterState.periodTo) {
			result.from_period = inputToQueryStringDate(filterState.periodFrom);
			result.to_period = `${inputToQueryStringDate(filterState.periodTo)} 23:59:59`;
		}

		if (filterState.type) {
			result.type = filterState.type;
		}

		return result;
	});

	const isFiltersSelected = () => {
		return (
			filterState.period ||
			filterState.type ||
			filterState.companies.length > 0 ||
			filterState.accounts.length > 0 ||
			filterState.paymentTypes.length > 0 ||
			filterState.employees.length > 0
		);
	};

	const submitFilter = () => {
		onSubmit();
	};

	const applyFilter = () => {
		isFiltered.value = isFiltersSelected();
		Object.assign(submittedFilterState, { ...filterState });
		submitFilter();
		isFormOpen.value = false;
	};

	const resetFilter = () => {
		isFiltered.value = false;
		Object.assign(filterState, { ...initialFilterState });
		Object.assign(submittedFilterState, { ...initialFilterState });
		submitFilter();
		isFormOpen.value = false;
	};

	const resetPeriod = () => {
		filterState.period = false;
		filterState.periodFrom = null;
		filterState.periodTo = null;
	};

	const resetType = () => {
		filterState.type = null;
	};

	const resetCompanies = () => {
		filterState.companies = [];
		filterState.accounts = [];
		filterState.paymentTypes = [];
	};

	const resetEmployees = () => {
		filterState.employees = [];
	};

	const activeFilters = computed(() => {
		return {
			period: !!(
				isFiltered.value &&
				submittedFilterState.period &&
				submittedFilterState.periodFrom &&
				submittedFilterState.periodTo
			),
			type: !!(isFiltered.value && submittedFilterState.type),
			company: !!(
				isFiltered.value && submittedFilterState.companies.length > 0
			),
			account: !!(isFiltered.value && submittedFilterState.accounts.length > 0),
			paymentType: !!(
				isFiltered.value && submittedFilterState.paymentTypes.length > 0
			),
			employees: !!(
				isFiltered.value && submittedFilterState.employees.length > 0
			),
		};
	});

	const companyChangeHandler = ({ val: isAddding, company } = {}) => {
		if (isAddding) {
			filterState.accounts = [
				...new Set([
					...filterState.accounts,
					...company.accounts.map((account) => account.id),
				]),
			];
		}

		if (!isAddding) {
			const companyAccounts = company.accounts.map((account) => account.id);

			filterState.accounts = filterState.accounts.filter(
				(accountId) => !companyAccounts.includes(accountId)
			);
		}

		company.accounts.forEach((account) =>
			accountChangeHandler({ val: isAddding, company, account })
		);
	};

	const accountChangeHandler = ({ val: isAddding, company, account } = {}) => {
		if (isAddding) {
			if (!filterState.companies.includes(company.id)) {
				filterState.companies = [...filterState.companies, company.id];
			}

			filterState.paymentTypes = [
				...new Set([
					...filterState.paymentTypes,
					...account.payment_types.map((type) => type.id),
				]),
			];
		}
		if (!isAddding) {
			const accountPaymentTypes = account.payment_types.map((type) => type.id);
			filterState.paymentTypes = filterState.paymentTypes.filter(
				(id) => !accountPaymentTypes.includes(id)
			);

			const companyAccounts = company.accounts.map((account) => account.id);
			if (
				!filterState.accounts.some((accountId) =>
					companyAccounts.includes(accountId)
				)
			) {
				filterState.companies = filterState.companies.filter(
					(companyId) => companyId !== company.id
				);
			}
		}
	};

	const paymentTypeChangeHandler = ({
		val: isAddding,
		company,
		account,
	} = {}) => {
		if (isAddding) {
			if (!filterState.companies.includes(company.id)) {
				filterState.companies = [...filterState.companies, company.id];
			}
			if (!filterState.accounts.includes(account.id)) {
				filterState.accounts = [...filterState.accounts, account.id];
			}
		}

		if (!isAddding) {
			const accountPaymentTypes = account.payment_types.map((type) => type.id);
			if (
				!filterState.paymentTypes.some((paymentTypeId) =>
					accountPaymentTypes.includes(paymentTypeId)
				)
			) {
				filterState.accounts = filterState.accounts.filter(
					(accountId) => accountId !== account.id
				);
				accountChangeHandler({ val: false, company, account });
			}
		}
	};

	const datesChangeHandler = () => {
		if (filterState.periodFrom && filterState.periodTo) {
			filterState.period = true;
		}
	};

	const periodChangeHandler = () => {
		filterState.periodFrom = "";
		filterState.periodTo = "";
	};

	return {
		isFormOpen,
		isFiltered,
		filterState,
		activeFilters,
		showFilterForm,
		closeFilterForm,
		currentFilter,

		applyFilter,

		resetFilter,
		resetPeriod,
		resetType,
		resetCompanies,
		resetEmployees,

		accountChangeHandler,
		companyChangeHandler,
		paymentTypeChangeHandler,

		datesChangeHandler,
		periodChangeHandler,
	};
};

export default useFilterController;
