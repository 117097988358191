export const ADMIN_ROUTE_NAMES = {
	COMPANIES_LIST: "companies",
	COMPANIES_NEW: "companies_new",
	COMPANIES_EDIT: "companies_edit",

	EMLOYEES_LIST: "employees",
	EMPLOYEES_NEW: "employees_new",
	EMLOYEES_EDIT: "employees_edit",

	REPORTS_DELETE: "delete",
	REPORTS_DELETEPASSWORDS: "delete_passwords",
	REPORTS_FINISH: "delete_passwords_finish",

	RESET_PASSWORD: 'reset_password',
	RESET_PASSWORD_SUCCESS: 'reset_password_success'
};

export const ONBOARDING_ROUTE_NAMES = {
	COMPANIES_LIST: "step1_companies",
	COMPANIES_NEW: "step1_companies_new",
	COMPANIES_EDIT: "step1_companies_edit",

	EMPLOYEES_LIST: "step2_employees",
	EMPLOYEES_NEW: "step2_employees_new",
	EMPLOYEES_EDIT: "step2_employees_edit",
}

export const USER_ROUTE_NAMES = {
	USER_REPORTS: "my-reports",
	USER_HISTORY: "my-history",
	EMPLOYEE_REPORTS: "employee-reports",
	EMPLOYEE_HISTORY: "employee-history",
	USER_COMPANIES: "my-companies",
	NEW_REPORT: "add-report",
	EDIT_REPORT: "edit-report",
	CLOUD_STORAGE: "cloud-storage",
	CLOUD_STORAGE_FOLDERS_LIST: "cloud-storage-folders-list",
	CLOUD_STORAGE_FOLDER: "clound-storage-folder",
};
