<template>
	<div class="page-login">
		<div class="page-login__picture">
			<picture>
				<source srcset="/img/login-picture-laptop.svg" media="(max-width:1540px)" />
				<img class="page-login__image" src="/img/login-picture.svg" alt />
			</picture>
			<div class="mobile-logo">
				<img src="/img/logo-picture.svg" alt="logo" />
			</div>
		</div>
		<div class="page-login__form">
			<h3>Log in</h3>
			<form id="loginForm" @submit.prevent="handle">
				<FormGroup label="Log in name" stroke>
					<primaryInput placeholder="Type your log in name" v-model="user.username"></primaryInput>
				</FormGroup>

				<FormGroup label="Password" stroke>
					<primaryInput placeholder="***********" v-model="user.password" type="password"></primaryInput>
				</FormGroup>

				<Button type="submit" text="Submit" expand :loading="auth.isAuthenticating" />
			</form>
		</div>
	</div>
</template>

<script setup>
import FormGroup from '@/components/form-components/FormGroup';
import Button from '@/components/base/AppButton';
import primaryInput from '@/components/form-components/PrimaryInput';
import { reactive } from 'vue';
import { useAuth } from '@/store/auth';

const auth = useAuth();

const user = reactive({
	username: '',
	password: '',
});

const handle = async () => {
	await auth.login(user);
};
</script>

<style lang="scss" scoped src="@/assets/scss/views/login.scss"></style>
