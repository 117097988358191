<template>
	<PageLayout
			title="Add Employee"
			desc="Create employees add companies and accounts to them"
			:loading="companiesLoading || userLoading"
	>
		<RouterLink custom
								:to="authStore.onboardingActive ?
								{ name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_LIST } :
								{ name: ADMIN_ROUTE_NAMES.EMLOYEES_LIST }"
								v-slot="{ href, navigate }">
			<Button
					back
					text="BACK TO EMLOYEES LIST"
					:href="href"
					@click="navigate"
					style="height:auto;margin-bottom: 34px;"
			>
				<template v-slot:icon>
					<BackIcon/>
				</template>
			</Button>
		</RouterLink>
		<h2>Edit employee information</h2>

		<EmployeeSettingsFormVue
				v-if="companies && userData"
				:companies="companies"
				:employee="userData"
				:loading="isSaving"
				@submit="submitHandler"
		/>
	</PageLayout>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import Button from "@/components/base/AppButton";
import BackIcon from "@/assets/icons/back.svg";
import PageLayout from "@/components/layouts/PageLayout.vue";
import {ADMIN_ROUTE_NAMES, ONBOARDING_ROUTE_NAMES} from "@/constants/route-names";
import useCompaniesList from "@/hooks/companies/useCompaniesList";
import useNotifications from "@/store/notifications";
import EmployeeSettingsFormVue from "../../components/EmployeeSettingsForm.vue";
import useGetUserData from "@/hooks/employees/useGetUserData";
import useUpdateEmployee from "@/hooks/employees/useUpdateEmployee";
import {useAuth} from "@/store/auth";


const route = useRoute();
const {companies, loading: companiesLoading} = useCompaniesList();
const {userData, loading: userLoading} = useGetUserData(route.params.id);
const {loading: isSaving, updateEmployee} = useUpdateEmployee();
const notifications = useNotifications();
const router = useRouter();
const authStore = useAuth();

const submitHandler = async (data) => {
	const response = await updateEmployee(route.params.id, data);

	if (response.success) {
		notifications.showNotification({
			message: "Emloyee updated!",
		});
		if (authStore.onboardingActive) {
			router.push({name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_LIST});
		} else {
			router.push({name: ADMIN_ROUTE_NAMES.EMLOYEES_LIST});
		}

	}
};
</script>
