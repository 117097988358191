import { USER_ROUTE_NAMES } from "../../constants/route-names";
import { USER_ROLES } from "../../constants/userRoles";
import UserReports from "@/views/user/views/reports/UserReports.vue";
import UserHistory from "@/views/user/views/history/UserHistory.vue";
import EmployeeReports from "@/views/user/views/employee-reports/EmployeeReports.vue";
import MobileReportForm from "@/views/user/components/MobileReportForm.vue";
import UserCompanies from "@/views/user/views/companies/UserCompanies.vue";
import EmployeeHistory from "@/views/user/views/employee-history/EmployeeHistory.vue";
import CloudStorage from "@/views/user/views/storage/CloudStorage.vue";
import FolderSettings from "@/views/user/views/storage/FolderSettings.vue";
import NoCompanies from "@/views/NoCompanies";
import FoldersList from "@/views/user/views/storage/FoldersList.vue";

/** @type {import('vue-router').RouteRecordRaw[]} */
const userRoutes = [
	{
		path: "/my-reports",
		name: USER_ROUTE_NAMES.USER_REPORTS,
		component: UserReports,
		meta: {
			requiresAuth: true,
			userRoles: [
				USER_ROLES.ACCOUNTANT,
				USER_ROLES.MANAGER,
				USER_ROLES.EMPLOYEE,
			],
			pageTitle: "My financial reports",
		},
	},
	{
		path: "/my-history",
		name: USER_ROUTE_NAMES.USER_HISTORY,
		component: UserHistory,
		meta: {
			requiresAuth: true,
			userRoles: [
				USER_ROLES.ACCOUNTANT,
				USER_ROLES.MANAGER,
				USER_ROLES.EMPLOYEE,
			],
			pageTitle: "My history of changes",
		},
	},
	{
		path: "/employee-reports",
		name: USER_ROUTE_NAMES.EMPLOYEE_REPORTS,
		component: EmployeeReports,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ACCOUNTANT, USER_ROLES.MANAGER],
			pageTitle: "Employee reports",
		},
	},
	{
		path: "/employee-history",
		name: USER_ROUTE_NAMES.EMPLOYEE_HISTORY,
		component: EmployeeHistory,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ACCOUNTANT, USER_ROLES.MANAGER],
			pageTitle: "Employee reports history",
		},
	},
	{
		path: "/my-companies",
		name: USER_ROUTE_NAMES.USER_COMPANIES,
		component: UserCompanies,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ACCOUNTANT],
			pageTitle: "Companies",
		},
	},
	{
		path: "/new-report",
		name: USER_ROUTE_NAMES.NEW_REPORT,
		component: MobileReportForm,
		meta: {
			requiresAuth: true,
			userRoles: [
				USER_ROLES.ACCOUNTANT,
				USER_ROLES.MANAGER,
				USER_ROLES.EMPLOYEE,
			],
			pageTitle: "Add new report",
		},
	},
	{
		path: "/edit-report",
		name: USER_ROUTE_NAMES.EDIT_REPORT,
		component: MobileReportForm,
		meta: {
			requiresAuth: true,
			userRoles: [
				USER_ROLES.ACCOUNTANT,
				USER_ROLES.MANAGER,
				USER_ROLES.EMPLOYEE,
			],
			pageTitle: "Edit report",
		},
	},
	{
		path: "/cloud-storage",
		name: USER_ROUTE_NAMES.CLOUD_STORAGE,
		redirect: { name: USER_ROUTE_NAMES.CLOUD_STORAGE_FOLDERS_LIST },
		component: CloudStorage,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ACCOUNTANT],
		},
		children: [
			{
				path: "/cloud-storage/folders",
				component: FoldersList,
				name: USER_ROUTE_NAMES.CLOUD_STORAGE_FOLDERS_LIST,
				meta: {
					requiresAuth: true,
					userRoles: [USER_ROLES.ACCOUNTANT],
					pageTitle: "Your cloud storage",
				},
			},
			{
				path: "/cloud-storage/folder/:id",
				name: USER_ROUTE_NAMES.CLOUD_STORAGE_FOLDER,
				component: FolderSettings,
				meta: {
					requiresAuth: true,
					userRoles: [USER_ROLES.ACCOUNTANT],
					pageTitle: "Folder Settings",
				},
			},
		],
	},
	{
		path: "/no-companies",
		name: "no-companies",
		component: NoCompanies,
		meta: {
			requiresAuth: true,
			userRoles: [
				USER_ROLES.ACCOUNTANT,
				USER_ROLES.MANAGER,
				USER_ROLES.EMPLOYEE,
			],
			pageTitle: "No companies",
		},
	},
];

export default userRoutes;
