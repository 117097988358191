import { toRefs, reactive } from "vue";
import { normalizeReports } from "../../../helpers/response";

const useReports = (fetcher) => {
	const state = reactive({
		loading: false,
		reports: null,
		meta: null,
	});

	const fetch = async (queryString, { update = false } = {}) => {
		state.loading = true;

		const response = await fetcher(queryString);
		if (response.data) {
			const reports = normalizeReports(response.data.data);
			if (state.reports && !update) {
				state.reports = state.reports.concat(reports);
			} else {
				state.reports = reports;
			}
			state.meta = response.data.meta;
		}

		state.loading = false;
	};

	return {
		...toRefs(state),
		fetch,
	};
};

export default useReports;
