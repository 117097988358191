<template>
	<PageLayout
		title="My history of changes"
		transparent-preloader
		:loading="reportsLoading"
	>
		<template #controls>
			<ReportsControls />
		</template>

		<TableFilter />

		<UserHistoryTable v-if="reports" :reports="reports" />
	</PageLayout>
</template>

<script setup>
import historyApiController from "@/api/historyApiController";
import PageLayout from "../../../../components/layouts/PageLayout.vue";
import ReportsControls from "../../components/ReportsControls.vue";
import useReportTable from "../../hooks/useReportTable";
import UserHistoryTable from "./components/UserHistoryTable.vue";
import TableFilter from "../../components/TableFilter.vue";

const { loading: reportsLoading, reports } = useReportTable(
	historyApiController.getUserHistory
);
</script>
