<template>
	<BaseModal :open="confirmation.isActive" @close="closeHandler">
		<h3 v-if="confirmation.options.title" class="title">
			{{ confirmation.options.title }}
		</h3>
		<p v-if="confirmation.options.text" class="desc">
			{{ confirmation.options.text }}
		</p>
		<span v-if="confirmation.options.hint" class="hint">{{
			confirmation.options.hint
		}}</span>
		<div class="btns">
			<AppButton text="No" block outline @click="closeHandler" />
			<AppButton text="Yes" block @click="submitHandler" />
		</div>
	</BaseModal>
</template>

<script setup>
import BaseModal from '../modals/BaseModal.vue';
import useConfirmation from '../../store/confirmation';
import AppButton from './AppButton.vue';

const confirmation = useConfirmation();

const closeHandler = () => {
	confirmation.options.onReject();
	confirmation.closeConfirmation();
};

const submitHandler = () => {
	confirmation.options.onSubmit();
	confirmation.closeConfirmation();
};
</script>

<style lang="scss" scoped>
.title {
	font-weight: 500;
	font-size: 24px;
}

.desc {
  margin-top: 24px;
  font-size: 16px;
	font-weight: 500;
}

.hint {
	display:block;
  margin-top: 16px;
  font-size: 14px;
  color: $primary;
}

.btns {
  margin: 34px auto 0;
	width: 240px;
  display: flex;
  gap: 16px;
}
</style>
