<template>
	<PageLayout
		title="Companies"
		desc="Edit company data, add more accounts and currencies"
		:loading="companyLoading || isSaving"
	>
		<RouterLink
			custom
			:to="
				authStore.onboardingActive
					? { name: ONBOARDING_ROUTE_NAMES.COMPANIES_LIST }
					: { name: ADMIN_ROUTE_NAMES.COMPANIES_LIST }
			"
			v-slot="{ href, navigate }"
		>
			<Button
				back
				text="BACK TO COMPANIES LIST"
				:href="href"
				@click="navigate"
				style="margin: -10px 0 -15px 0; line-height: initial"
			>
				<template v-slot:icon>
					<BackIcon />
				</template>
			</Button>
		</RouterLink>
		<h3>Edit company information</h3>

		<AppTabs :tabs="tabs" v-slot="{ index }">
			<div v-if="companyData && index === 0" class="layout">
				<CompanySettingsForm
					@submit="submitHandler({ name: companyData.name })"
					@add-account="createAccountHandler(companyData.id, $event)"
					@delete-account="deleteAccountHandler"
					@edit-account="updateAccountHandler"
					@switch-account="updateAccountHandler"
					@add-currency="addCurrencyHandler"
					@update-currency="updateCurrencyHandler"
					@delete-currency="deleteCurrencyHandler($event.currencyId)"
					@add-payment-type="createPaymentTypeHandler"
					@update-payment-type="updatePaymentTypeHandler"
					@delete-payment-type="deletePaymentTypeHandler($event.paymentId)"
					@add-expense-type="createExpenseTypeHandler"
					@update-expense-type="updateExpenseTypeHandler"
					@delete-expense-type="deleteExpenseTypeHandler($event.expenseId)"
					:company-data="companyData"
					show-switcher
				/>
			</div>
			<EmployeeList
				v-else-if="companyData && index === 1"
				:company-data="{ ...companyData }"
				@refetch="refetchCompany()"
				:employees="employees"
			/>
			<CompanyEdit
				v-else-if="companyData && index === 2"
				:company-data="companyData"
				@submit="submitHandler({ name: companyData.name })"
			/>
		</AppTabs>
	</PageLayout>
</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout.vue";
import Button from "@/components/base/AppButton";
import BackIcon from "@/assets/icons/back.svg";
import CompanySettingsForm from "@/views/admin/companies/components/CompanySettingsForm";
import useGetCompanyData from "@/hooks/companies/useGetCompanyData";
import AppTabs from "@/components/AppTabs";
import EmployeeList from "@/views/admin/companies/views/edit/components/EditCompanyEmployees";
import { useRoute, useRouter } from "vue-router";
import {
	ADMIN_ROUTE_NAMES,
	ONBOARDING_ROUTE_NAMES,
} from "@/constants/route-names";
import useUpdateCompany from "@/hooks/companies/useUpdateCompany";
import useCreateAccount from "@/hooks/companies/accounts/useCreateAccount";
import useDeleteAccount from "@/hooks/companies/accounts/useDeleteAccount";
import useUpdateAccount from "@/hooks/companies/accounts/useUpdateAccount";
import useCreateCurrency from "@/hooks/companies/accounts/currency/useCreateCurrency";
import useDeleteCurrency from "@/hooks/companies/accounts/currency/useDeleteCurrency";
import useCreatePaymentType from "@/hooks/companies/accounts/payment-type/useCreatePaymentType";
import useDeletePaymentType from "@/hooks/companies/accounts/payment-type/useDeletePaymentType";
import useUpdateCurrency from "@/hooks/companies/accounts/currency/useUpdateCurrency";
import useUpdatePaymentType from "@/hooks/companies/accounts/payment-type/useUpdatePaymentType";
import useNotifications from "@/store/notifications";
import useConfirmation from "@/store/confirmation";
import useEmployeesList from "@/hooks/employees/useEmployeesList";
import { useAuth } from "@/store/auth";
import CompanyEdit from "../../components/CompanyEdit.vue";
import useCreateExpenseType from "@/hooks/companies/accounts/expenses/useCreateExpenseType";
import useDeleteExpenseType from "@/hooks/companies/accounts/expenses/useDeleteExpenseType";
import useUpdateExpenseType from "@/hooks/companies/accounts/expenses/useUpdateExpenseType";

const authStore = useAuth();

const route = useRoute();
const router = useRouter();
const confirm = useConfirmation();
const notifications = useNotifications();
const {
	companyData,
	loading: companyLoading,
	refetch: refetchCompany,
} = useGetCompanyData(route.params.id);
const { deleteAccount, loading } = useDeleteAccount();
const { createAccount } = useCreateAccount();
const { updateAccount } = useUpdateAccount();
const { loading: isSaving, updateCompany } = useUpdateCompany();
const { createCurrency } = useCreateCurrency();
const { deleteCurrency } = useDeleteCurrency();
const { updateCurrency } = useUpdateCurrency();
const { createPaymentType } = useCreatePaymentType();
const { deletePaymentType } = useDeletePaymentType();
const { updatePaymentType } = useUpdatePaymentType();
const { createExpenseType } = useCreateExpenseType();
const { deleteExpenseType } = useDeleteExpenseType();
const { updateExpenseType } = useUpdateExpenseType();

const { employees } = useEmployeesList();

const defaultAccount = (name) => {
	return {
		name: name,
		currencies: [{ name: "USD" }, { name: "EUR" }],
		payment_types: [{ name: "Cash" }, { name: "Credit Card" }],
		expenses: [],
	};
};

const tabs = [{ name: "Accounts" }, { name: "Employees" }, { name: "Company" }];

const submitHandler = async (data) => {
	const response = await updateCompany(route.params.id, data);
	if (response.success) {
		notifications.showNotification({
			message: "Company updated!",
		});
		if (authStore.onboardingActive) {
			router.push({ name: ONBOARDING_ROUTE_NAMES.COMPANIES_LIST });
		} else {
			router.push({ name: ADMIN_ROUTE_NAMES.COMPANIES_LIST });
		}
	}
};
const createAccountHandler = async (id, name) => {
	const response = await createAccount(id, defaultAccount(name));
	if (response.success) {
		refetchCompany();
	}
};
const deleteAccountHandler = async (id) => {
	confirm.showConfirmation({
		title: "Delete account?",
		text: "Are you sure you want to delete this account?",
		onSubmit: async () => {
			loading.value = true;
			const response = await deleteAccount(id);
			if (response.success) {
				notifications.showNotification({ message: "Account deleted!" });
				refetchCompany();
			} 
		},
	});
};
const updateAccountHandler = async (accountId, { name, is_active }) => {
	const response = await updateAccount(accountId, { name, is_active });
	if (response.success) {
		notifications.showNotification({
			message: "Account updated!",
		});
		refetchCompany();
	}
};
const addCurrencyHandler = async ({ id, name }) => {
	const response = await createCurrency(id, name);
	if (response.success) {
		refetchCompany();
	}
};
const deleteCurrencyHandler = async (id) => {
	confirm.showConfirmation({
		title: "Delete currency?",
		text: "Are you sure you want to delete this currency?",
		hint: "(You can edit currencies later in the companies settings)",
		onSubmit: async () => {
			loading.value = true;
			const response = await deleteCurrency(id);
			if (response.success) {
				notifications.showNotification({ message: "Currency deleted!" });
				refetchCompany();
			} 
		},
	});
};
const updateCurrencyHandler = async (id, { name, is_active }) => {
	loading.value = true;
	await updateCurrency(id, { name, is_active });
	refetchCompany();
};
const createPaymentTypeHandler = async ({ id, name }) => {
	const response = await createPaymentType(id, name);
	if (response.success) {
		refetchCompany();
	}
};
const deletePaymentTypeHandler = async (id) => {
	confirm.showConfirmation({
		title: "Delete payment type?",
		text: "Are you sure you want to delete this payment type?",
		hint: "(You can edit payment types later in the companies settings)",
		onSubmit: async () => {
			loading.value = true;
			const response = await deletePaymentType(id);
			if (response.success) {
				notifications.showNotification({ message: "Payment type deleted!" });
				refetchCompany();
			}
		},
	});
};
const updatePaymentTypeHandler = async (id, { name, is_active }) => {
	loading.value = true;
	await updatePaymentType(id, { name, is_active });
	refetchCompany();
};

const createExpenseTypeHandler = async ({ id, name }) => {
	const response = await createExpenseType(id, name);
	if (response.success) {
		refetchCompany();
	}
};
const deleteExpenseTypeHandler = async (id) => {
	confirm.showConfirmation({
		title: "Delete expense type?",
		text: "Are you sure you want to delete this expense type?",
		hint: "(You can edit expense types later in the companies settings)",
		onSubmit: async () => {
			loading.value = true;
			const response = await deleteExpenseType(id);
			if (response.success) {
				notifications.showNotification({ message: "Expense type deleted!" });
				refetchCompany();
			} 
		},
	});
};
const updateExpenseTypeHandler = async (id, { name, is_active }) => {
	loading.value = true;
	await updateExpenseType(id, { name, is_active });
	refetchCompany();
};

// const switchAccountPayment = async (id, {is_active}) => {
// 	loading.value = true;
// 	await updateAccount(id, {is_active})
// }
</script>

<style lang="scss">
.company-form__item:not(:first-of-type) .preview {
	padding-right: 8px;
	padding-left: 10px;
}

.company-form {
	border-bottom: 1px solid #e0e0e0;

	&:not(:first-of-type) {
		.preview-btns {
			.switch {
				order: 2;
			}

			.delete-btn {
				order: 3;
			}

			.edit-btn {
				order: 1;
			}
		}
	}
}

.tabs__header {
	margin: 34px 0;
	border-bottom: none;
	padding-bottom: 0;
}

.company-name .form-group {
	margin-top: 0px;
}

.employee-wrapper {
	grid-column: span 3;
}

.employee-box {
	padding-bottom: 24px;
	border-bottom: 1px solid $gray;
	margin-bottom: 24px;

	&__title {
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 16px;
	}

	&__lists {
		display: flex;

		.employee-box__list {
			//max-width: 30%;
			&:not(:last-child) {
				margin-right: 116px;
			}

			&-title {
				margin-bottom: 14px;
				color: $primary;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
			}

			&-list {
				margin-bottom: 24px;

				.employee-box__list-item {
					display: flex;
					align-items: flex-start;

					span {
						margin-right: 8px;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
					}

					&:not(:last-child) {
						margin-bottom: 12px;
					}
				}
			}
		}
	}
}
</style>
