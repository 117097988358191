<template>
	<PageLayout
		title="Companies"
		count="3 companies"
		:loading="loading || isCreating"
	>
		<RouterLink
			custom
			:to="{ name: ADMIN_ROUTE_NAMES.COMPANIES_LIST }"
			v-slot="{ href, navigate }"
		>
			<Button back text="BACK TO COMPANIES LIST" :href="href" @click="navigate">
				<template v-slot:icon>
					<BackIcon />
				</template>
			</Button>
		</RouterLink>
		<h3>Add a new company</h3>
		<div class="steps">
			<div class="step" :class="{ active: step === 1 }" @click="step = 1">
				Step 1
			</div>
			<div class="step" :class="{ active: step === 2 }" @click="step = 2">
				Step 2
			</div>
		</div>
		<div class="layout">
			<template v-if="step === 1">
				<div class="layout__col layout__col--2of4">
					<div class="company-name">
						<FormGroup stroke label="Company name">
							<PrimaryInput
								:value="company.name"
								v-model="company.name"
								placeholder="Type company name"
								type="text"
								id="companyName"
							></PrimaryInput>
						</FormGroup>
						<div class="text-hints">
							<p>
								Add the company`s main account and additional accounts if
								needed.
							</p>
							<p>
								Additional accounts are necessary to limit access of some users
								to certain financial operations of the company.
							</p>
							<p>
								Reports for managers and accountants are available only for the
								accounts to which they have access.
							</p>
						</div>
						<AppButton @click="step = 2" text="Next step" expand></AppButton>
					</div>
				</div>
			</template>
			<template v-if="step === 2">
				<CompanySettingsForm
					@add-account="addAccount"
					@edit-account="editAccount"
					@delete-account="deleteAccount"
					@add-currency="addCurrency"
					@add-payment-type="addPaymentType"
					@add-expense-type="addExpenseType"
					@delete-currency="deleteCurrency"
					@delete-payment-type="deletePayment"
					@delete-expense-type="deleteExpense"
					:company-data="company"
					:show-switcher="false"
				/>
				<div class="btns-group">
					<AppButton
						@click="step = 1"
						text="Previous step"
						outline
						expand
					></AppButton>
					<AppButton
						@click="createNewCompany(company)"
						text="Confirm"
						expand
					></AppButton>
				</div>
			</template>
		</div>
	</PageLayout>
</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout.vue";
import Button from "@/components/base/AppButton";
import CompanySettingsForm from "@/views/admin/companies/components/CompanySettingsForm";
import BackIcon from "@/assets/icons/back.svg";
import { ADMIN_ROUTE_NAMES } from "@/constants/route-names";
import useCreateCompany from "@/hooks/companies/useCreateCompanie";
import { useRouter } from "vue-router";
import { reactive, ref } from "vue";
import useNotifications from "@/store/notifications";
import AppButton from "@/components/base/AppButton";
import FormGroup from "@/components/form-components/FormGroup";
import PrimaryInput from "@/components/form-components/PrimaryInput";

const step = ref(1);

const { createCompany, loading, loading: isCreating } = useCreateCompany();
const router = useRouter();
const notifications = useNotifications();

let company = reactive({
	name: "",
	accounts: [
		{
			name: "Account name",
			id: 0,
			currencies: [
				{ id: 0, name: "USD" },
				{ id: 1, name: "EUR" },
			],
			payment_types: [
				{ id: 0, name: "Cash" },
				{ id: 1, name: "Credit Card" },
			],
			expenses: [],
		},
	],
});

const checkAndPush = (id, name, arr) => {
	const res = company.accounts[id][arr].find(el => el.name.toLowerCase() === name.name.toLowerCase() );
	if(res) {
		notifications.showNotification({
			type: "warning",
			message: "The name in the list must have a unique value",
		});
	} else {
		const lastEl = company.accounts[id][arr].slice(-1);
		company.accounts[id][arr].push({
			id: arr === "expenses" ? (!lastEl[0] ? 0 : lastEl[0]?.id + 1) : lastEl[0].id + 1,
			name: name.name,
		});
	}
}

const createDefaultAccount = (id, name) => {
	return {
		name: name,
		id: id,
		currencies: [
			{ id: 0, name: "USD" },
			{ id: 1, name: "EUR" },
		],
		payment_types: [
			{ id: 0, name: "Cash" },
			{ id: 1, name: "Credit Card" },
		],
		expenses: [],
	};
};
const addAccount = (name) => {
	const k = company.accounts.length;
	const newAcc = createDefaultAccount(k, name);
	company.accounts.push(newAcc);
};
const editAccount = (id, name) => {
	company.accounts[id].name = name.name;
};
const deleteAccount = (accountId) => {
	company.accounts.splice(accountId, 1);
};
const addCurrency = ({id, name}) => {
	checkAndPush(id, name, 'currencies')
};
const deleteCurrency = ({ accountId, currencyId }) => {
	let ArrayOfCurrencies = company.accounts[accountId].currencies;
	company.accounts[accountId].currencies = ArrayOfCurrencies.filter(
		(item) => item.id !== currencyId
	);
};
const addPaymentType = ({ id, name }) => {
	checkAndPush(id, name, 'payment_types')

};
const deletePayment = ({ accountId, paymentId }) => {
	let ArrayOfPayments = company.accounts[accountId].payment_types;
	company.accounts[accountId].payment_types = ArrayOfPayments.filter(
		(item) => item.id !== paymentId
	);
};
const addExpenseType = ({ id, name }) => {
	checkAndPush(id, name, 'expenses')

};
const deleteExpense = ({ accountId, expenseId }) => {
	let ArrayOfExpense = company.accounts[accountId].expenses;
	company.accounts[accountId].expenses = ArrayOfExpense.filter(
		(item) => item.id !== expenseId
	);
};
const createNewCompany = async (data) => {
	loading.value = true;
	// data.accounts.forEach((item) => {
	// 	delete item['id']
	// })
	const response = await createCompany(data);

	if (response.success) {
		notifications.showNotification({
			message: "Company was created!",
		});
		router.push({ name: ADMIN_ROUTE_NAMES.COMPANIES_LIST });
	} else {
		loading.value = false;
	}
};
</script>
<style>
h3 {
	margin: 34px 0;
}
</style>

<style lang="scss">
.layout {
	margin-top: 34px;
}

.company-form {
	border-bottom: 1px solid #e0e0e0;

	&:not(:first-of-type) {
		.preview-btns {
			.delete-btn {
				margin-right: 0 !important;
			}

			.edit-btn {
				margin-right: 8px;
			}
		}
	}
}
.btns-group {
	display: flex;
	align-items: center;
	gap: 20px;
}

.steps {
	display: flex;
	flex-wrap: wrap;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	margin: 34px 0;
	border-bottom: none;
	padding-bottom: 0;
	gap: 20px;
}

.step {
	color: #2d5be3;
	padding-bottom: 8px;
	opacity: 0.7;
	cursor: pointer;
	margin-bottom: 10px;
	&.active {
		border-bottom: 2px solid #2d5be3;
		opacity: 1;
	}
}
</style>
