<template>
	<PageLayout
		title="Companies"
		:count-text="companies && getCompaniesCount(companies.length)"
		:loading="loading || isDeleting"
	>
		<template v-slot:controls>
			<RouterLink
				custom
				:to="{ name: ADMIN_ROUTE_NAMES.COMPANIES_NEW }"
				v-slot="{ href, navigate }"
				v-if="path === '/companies'"
			>
				<AppButton outline medium text="Add new" :href="href" @click="navigate">
					<template v-slot:icon>
						<PlusIcon />
					</template>
				</AppButton>
			</RouterLink>
			<RouterLink
				custom
				:to="{ name: ONBOARDING_ROUTE_NAMES.COMPANIES_NEW }"
				v-slot="{ href, navigate }"
				v-else
			>
				<AppButton outline medium text="Add new" :href="href" @click="navigate">
					<template v-slot:icon>
						<PlusIcon />
					</template>
				</AppButton>
			</RouterLink>

			<RouterLink
				custom
				:to="{ name: ONBOARDING_ROUTE_NAMES.EMPLOYEES_NEW }"
				v-slot="{ href, navigate }"
				v-if="path !== '/companies'"
			>
				<AppButton
					text="NEXT STEP"
					:href="href"
					@click="navigate"
					medium
					style="float: right"
				></AppButton>
			</RouterLink>
		</template>

		<div v-if="columns" class="companies-list">
			<div v-for="(column, columnI) in columns" :key="`columns-${columnI}`" class="companies-columns">
				<Preview
					v-for="item in column"
					:key="item.id"
					:title="item.name"
					:info="getCompanyInfo(item)"
					show-delete
					show-edit
					showAccordion
					@delete="onCompanyDelete(item.id)"
					@edit="onCompanyEdit(item.id)"
				>
					<AppTabs :tabs="item.accounts" v-slot="{ index }">
						<div v-if="item.accounts[index].employees.length === 0">
							No employees
						</div>

						<CompaniesEmployeesList
							v-else
							:employees="processEmployees(item.accounts[index].employees)"
						/>
					</AppTabs>
				</Preview>
			</div>
		</div>
	</PageLayout>
</template>

<script setup>
import Preview from "@/components/preview/VPreview";
import AppTabs from "@/components/AppTabs.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import AppButton from "@/components/base/AppButton.vue";
import PlusIcon from "@/assets/icons/plus.svg";
import useCompaniesList from "../../../../../hooks/companies/useCompaniesList";
import {
	ADMIN_ROUTE_NAMES,
	ONBOARDING_ROUTE_NAMES,
} from "@/constants/route-names";
import useDeleteCompany from "@/hooks/companies/useDeleteCompany";
import useNotifications from "@/store/notifications";
import useConfirmation from "@/store/confirmation";
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
import CompaniesEmployeesList from "./components/CompaniesEmployeesList.vue";
import { getCompaniesCount } from "@/helpers/strings";
import { shallowEqual } from "@/views/admin/companies/helpers/shallowEqual";

const { companies, loading, refetch: refetchCompanies } = useCompaniesList();
const { deleteCompany, loading: isDeleting } = useDeleteCompany();
const confirm = useConfirmation();
const router = useRouter();
const route = useRoute();
const path = computed(() => route.path);
const notifications = useNotifications();

const getCompanyInfo = (company) => {
	const employeesCount = company.accounts.reduce((acc, account) => {
		account.employees.forEach((item) => {
			if (!acc.some((k) => shallowEqual(k, { id: item.id }))) {
				acc.push({ id: item.id });
			}
		});

		return acc;
	}, []);

	const employeeText = employeesCount > 1 ? "employees" : "employee";

	const accountsCount = company.accounts.length;
	const accountText = accountsCount > 1 ? "accounts" : "account";

	return [
		employeesCount.length + " " + employeeText,
		accountsCount + " " + accountText,
	];
};



const columns = computed(()=> {
	if(!companies.value) return null;
	const allCompanies = companies.value;
	allCompanies.reverse();
	let subArrayCount = 3;
	return allCompanies.reduce((acc, item, index)=> {
		if (acc[index%subArrayCount] == undefined) acc.push([]);
		acc[index%subArrayCount].push(item);
		return acc;
	} , [])
})


const onCompanyDelete = async (id) => {
	confirm.showConfirmation({
		title: "Delete company?",
		onSubmit: async () => {
			loading.value = true;
			await deleteCompany(id);
			notifications.showNotification({
				message: "Company was deleted!",
			});
			refetchCompanies();
		},
	});
};

const onCompanyEdit = (id) => {
	router.push({ name: ADMIN_ROUTE_NAMES.COMPANIES_EDIT, params: { id } });
};

const processEmployees = (employees) => {
	return employees.reduce(
		(acc, employee) => {
			const role = employee.roles[0];
			acc[role].push(employee.name);
			return acc;
		},
		{
			accountant: [],
			manager: [],
			employee: [],
		}
	);
};
</script>

<style scoped lang="scss">
.companies-list {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	align-items: start;
	gap: 24px;
}
.companies-columns {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
</style>
