import { ref } from "vue"
import reportsApiController from "../../api/reportsApiController"
import useNotifications from "../../store/notifications"

const useDeleteReport = () => {
  const loading = ref(false)

  const { showNotification } = useNotifications();

  const deleteReport = async (id) => {
    loading.value = true
    const response = await reportsApiController.deleteReport(id)

    if (response.data.success) {
      showNotification({
        message: `Report ${id} successfully deleted.`
      })
    }

    loading.value = false
    return response
  }

  return {
    loading,
    deleteReport,
  }
}

export default useDeleteReport;
