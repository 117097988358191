export const initialFilterState = {
	period: false,
	periodFrom: null,
	periodTo: null,
	type: "",
	companies: [],
	accounts: [],
	paymentTypes: [],
	employees: [],
};
