<template>
	<!-- <div class="layout__col layout__col--1of4">
		<div class="company-name">
			<FormGroup stroke label="Company name">
				<PrimaryInput
						:value="company.name"
						v-model="company.name"
						placeholder="Type company name"
						type="text"
						id="companyName"
				></PrimaryInput>
			</FormGroup>
			<div class="text-hints">
				<p>Add the company`s main account and additional accounts if needed.</p>
				<p>Additional accounts are necessary to limit access of some users to certain financial operations of the
					company.</p>
				<p>Reports for managers and accountants are available only for the accounts to which they have access.</p>
			</div>
			<AppButton @click="submitHandlerEmit" text="CONFIRM" expand></AppButton>
		</div>
	</div> -->
	<div v-if="companyData" class="layout__col layout__col--4of4">
		<AccountSettingsForm
			v-for="(account, index) in companyData.accounts"
			:companyData="account"
			:account-name="account.name"
			:accountsLength="companyData.accounts.length"
			:key="index"
			:index="index"
			:show-switcher="showSwitcher"
			@add-account="addAccountEmit"
			@edit-account="editAccountEmit(account.id, $event)"
			@delete-account="deleteAccountEmit(account.id)"
			@switch-account="switchAccount(account.id, $event)"
			@add-currency="addCurrencyEmit(account.id, $event)"
			@update-currency="updateCurrencyEmit"
			@delete-currency="deleteCurrencyEmit"
			@add-payment-type="addPaymentTypeEmit(account.id, $event)"
			@update-payment-type="updatePaymentTypeEmit"
			@delete-payment-type="deletePaymentTypeEmit"
			@add-expense-type="addExpenseTypeEmit(account.id, $event)"
			@update-expense-type="updateExpenseTypeEmit"
			@delete-expense-type="deleteExpenseTypeEmit"
			>,
		</AccountSettingsForm>
	</div>
</template>

<script>
import AccountSettingsForm from "@/views/admin/companies/components/AccountsSettingsForm";
// import AppButton from "@/components/base/AppButton";
// import FormGroup from "@/components/form-components/FormGroup";
// import PrimaryInput from "@/components/form-components/PrimaryInput";

export default {
	emits: [
		"addAccount",
		"editAccount",
		"deleteAccount",
		"switchAccount",
		"addCurrency",
		"updateCurrency",
		"deleteCurrency",
		"addPaymentType",
		"updatePaymentType",
		"deletePaymentType",
		"addExpenseType",
		"updateExpenseType",
		"deleteExpenseType",
		"submit",
	],
	props: {
		companyData: {
			type: Object,
			default: null,
		},
		showSwitcher: Boolean,
	},
	components: {
		AccountSettingsForm,
		// AppButton,
		// FormGroup, PrimaryInput,
	},
	created() {
		if (this.companyData) {
			this.company = this.companyData;
		} else {
			this.addAccountEmit();
		}
	},

	methods: {
		addAccountEmit(name) {
			this.$emit("addAccount", name);
		},
		editAccountEmit(accountId, { name, is_active }) {
			this.$emit("editAccount", accountId, { name, is_active });
		},
		deleteAccountEmit(accountId) {
			this.$emit("deleteAccount", accountId);
		},
		switchAccount(accountId, { name, is_active }) {
			this.$emit("switchAccount", accountId, { name, is_active });
		},
		addCurrencyEmit(id, name) {
			this.$emit("addCurrency", { id: id, name: name });
		},
		updateCurrencyEmit(currencyId, { name: name, is_active: is_active }) {
			this.$emit("updateCurrency", currencyId, { name, is_active });
		},
		deleteCurrencyEmit({ accountId, currencyId }) {
			this.$emit("deleteCurrency", { accountId, currencyId });
		},
		addPaymentTypeEmit(id, name) {
			this.$emit("addPaymentType", { id: id, name: name });
		},
		updatePaymentTypeEmit(paymentId, { name: name, is_active: is_active }) {
			this.$emit("updatePaymentType", paymentId, { name, is_active });
		},
		deletePaymentTypeEmit({ accountId, paymentId }) {
			this.$emit("deletePaymentType", { accountId, paymentId });
		},
		addExpenseTypeEmit(id, name) {
			this.$emit("addExpenseType", { id: id, name: name });
		},
		updateExpenseTypeEmit(expenseId, { name: name, is_active: is_active }) {
			this.$emit("updateExpenseType", expenseId, { name, is_active });
		},
		deleteExpenseTypeEmit({ accountId, expenseId }) {
			this.$emit("deleteExpenseType", { accountId, expenseId });
		},
		submitHandlerEmit() {
			this.$emit("submit");
		},
	},
};
</script>
