<template>
	<div class="employee-box__list">
		<div
				class="employee-box__list-title"
				v-if="employeesList.accountant.length > 0 || processEmployeesList('accountant').length > 0"
		>Accountant:
		</div>
		<div class="employee-box__list-box">
			<ul class="employee-box__list-list" v-if="employeesList.accountant.length > 0">
				<li
						class="employee-box__list-item"
						v-for="accountant in employeesList.accountant"
						:key="accountant.name"
				>
					<span>{{ accountant.name }}</span>
					<button class="delete-btn" @click="deleteUser({ user_ids: [accountant.id] })">
						<iconDelete></iconDelete>
					</button>
				</li>
			</ul>
			<AppButton
					text="Add new"
					outline
					medium
					@click="addAccountantModal"
					v-if="processEmployeesList('accountant').length > 0"
			>
				<template v-slot:icon>
					<iconPlus/>
				</template>
			</AppButton>
		</div>
	</div>
	<div class="employee-box__list">
		<div
				class="employee-box__list-title"
				v-if="employeesList.manager.length > 0 || processEmployeesList('manager').length > 0"
		>Manager:
		</div>
		<div class="employee-box__list-box">
			<ul class="employee-box__list-list" v-if="employeesList.manager.length > 0">
				<li
						class="employee-box__list-item"
						v-for="manager in employeesList.manager"
						:key="manager.name"
				>
					<span>{{ manager.name }}</span>
					<button class="delete-btn" @click="deleteUser({ user_ids: [manager.id] })">
						<iconDelete></iconDelete>
					</button>
				</li>
			</ul>
			<AppButton
					text="Add new"
					outline
					medium
					@click="addManagerModal"
					v-if="processEmployeesList('manager').length > 0"
			>
				<template v-slot:icon>
					<iconPlus/>
				</template>
			</AppButton>
		</div>
	</div>
	<div class="employee-box__list">
		<div
				class="employee-box__list-title"
				v-if="employeesList.employee.length > 0 || processEmployeesList('employee').length > 0"
		>Employee:
		</div>
		<div class="employee-box__list-box">
			<ul class="employee-box__list-list" v-if="employeesList.employee.length > 0">
				<li
						class="employee-box__list-item"
						v-for="employee in employeesList.employee"
						:key="employee.name"
				>
					<span>{{ employee.name }}</span>
					<button class="delete-btn" @click="deleteUser({ user_ids: [employee.id] })">
						<iconDelete></iconDelete>
					</button>
				</li>
			</ul>
			<AppButton
					text="Add new"
					outline
					medium
					@click="addEmployeeModal"
					v-if="processEmployeesList('employee').length > 0"
			>
				<template v-slot:icon>
					<iconPlus/>
				</template>
			</AppButton>
		</div>
	</div>
	<AddAccountantModal
			v-if="addAccountantModal"
			:open="addAccountantModalOpen"
			@close="addAccountantModalOpen = false"
			:employees-list="modalEmployeeList"
			@change="processAddEmployees"
	/>
	<AddManagerModal
			v-if="addManagerModal"
			:open="addManagerModalOpen"
			@close="addManagerModalOpen = false"
			:employees-list="modalEmployeeList"
			@change="processAddEmployees"
	/>

	<AddEmployeeModal
			v-if="addEmployeeModal"
			:open="addEmployeeModalOpen"
			@close="addEmployeeModalOpen = false"
			:employees-list="modalEmployeeList"
			@change="processAddEmployees"
	/>
</template>

<script setup>
import AppButton from "@/components/base/AppButton"
import iconPlus from "@/assets/icons/plus.svg"
import iconDelete from "@/assets/icons/delete-small.svg"
import useConfirmation from '@/store/confirmation';
import useNotifications from '@/store/notifications';
import useDeleteEmployeeFromAccount from "@/hooks/companies/accounts/employees/useDeleteEmployeeFromAccount";
import AddEmployeeModal from "../../../components/AddEmployeeModal"
import AddAccountantModal from "../../../components/AddAccountantModal"
import AddManagerModal from "../../../components/AddManagerModal"
import {shallowEqual} from "@/views/admin/companies/helpers/shallowEqual";
import useAddUserToAccount from "@/hooks/companies/accounts/employees/useAddEmployeeToAccount";

import {ref} from "vue";

const confirm = useConfirmation();
const {deleteEmployeeFromAccount} = useDeleteEmployeeFromAccount();
const notifications = useNotifications();
const {addUserToAccount} = useAddUserToAccount();


const props = defineProps({
	employeesList: {
		type: Object,
		required: true,
	},
	employeesFullList: {
		type: Object,
		required: true,
	},
	accountId: Number,
	companyId: Number,
})

const addEmployeeModalOpen = ref(false);
const addAccountantModalOpen = ref(false);
const addManagerModalOpen = ref(false);
const modalEmployeeList = ref([]);
const emit = defineEmits(['refetch']);

const processEmployeesList = (type) => {
	return props.employeesFullList.reduce((list, employee) => {
				const search = {id: employee.id, name: employee.name};
				if (employee.roles[0] === type
						&& !list.some(k => shallowEqual(k, search))
						&& !props.employeesList[type].some(k => shallowEqual(k, search))) {
					list.push({id: employee.id, name: employee.name})
				}
				return list.sort((a, b) => a.name.localeCompare(b.name))
			},
			[]
	)
}

const processAddEmployees = async (newEmployee) => {
	await addUserToAccount(props.accountId, {user_ids: newEmployee})
	emit('refetch');
	notifications.showNotification({message: 'Users was added to account!'});
	modalEmployeeList.value = [];
	
};

const addEmployeeModal = () => {
	processEmployeesList('employee');
	modalEmployeeList.value = processEmployeesList('employee');
	addEmployeeModalOpen.value = true;
}
const addAccountantModal = () => {
	processEmployeesList('accountant');
	modalEmployeeList.value = processEmployeesList('accountant');
	addAccountantModalOpen.value = true;
}
const addManagerModal = () => {
	processEmployeesList('manager');
	modalEmployeeList.value = processEmployeesList('manager');
	addManagerModalOpen.value = true;
}

const deleteUser = (user_id) => {
	confirm.showConfirmation({
		title: "Delete user?",
		text: 'Are you sure you want to delete this user?',
		onSubmit: async () => {
			await deleteEmployeeFromAccount(props.accountId, user_id);
			notifications.showNotification({message: 'User was deleted from account!'});
			emit('refetch');
		},
	});
}
</script>
