import { reactive, toRefs } from "vue";
import usersApiController from "../../api/usersApiController";

const useGetUserEmployees = ({ immidiate }) => {
	const state = reactive({
		loading: false,
		employees: null,
		error: false,
	});

	const fetchEmployees = async () => {
		state.loading = true;
		if (!state.employees) {
			try {
				const response = await usersApiController.getEmployees();
				state.employees = response.data;
			} catch (error) {
				state.error = error;
			}
		}
		state.loading = false;
	};

	const refetch = async () => {
		state.employees = null;
		fetchEmployees();
	};

	if (immidiate) {
		fetchEmployees();
	}

	return {
		...toRefs(state),
		refetch,
	};
};

export default useGetUserEmployees;
