import { ref } from 'vue';
import authApiController from '@/api/authApiController';

const useResetPassword = () => {
	const loading = ref(false);

	const resetPassword = async (data) => {
		loading.value = true;

		const response = await authApiController.resetPassword(data);

		loading.value = false;
		return response;
	};

	return {
		loading,
		resetPassword,
	};
};

export default useResetPassword;
