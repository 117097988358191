import {reactive, toRefs} from 'vue';
import expenseTypesApiController from '../../../../api/accounts/expenseTypesApiController';

const useCreateExpenseType = () => {
  const state = reactive({
    loading: false,
    error: false,
  });

  const createExpenseType = async (id, data) => {
    state.loading = true;

    try {
      const response = await expenseTypesApiController.createExpenseType(id, data);
      return response
    } catch (error) {
      state.error = error;
    }

    state.loading = false;

  };


  return {
    ...toRefs(state),
    createExpenseType,
  };
};

export default useCreateExpenseType;
