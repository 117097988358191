<template>
	<PageLayout
		title="Employee reports"
		transparent-preloader
		:loading="reportsLoading"
	>
		<template #controls>
			<ReportsControls show-export />
		</template>

		<TableFilter />
		<ExportForm />

		<EmployeeReportsTable v-if="reports" :reports="reports" />
	</PageLayout>
</template>

<script setup>
import PageLayout from "@/components/layouts/PageLayout.vue";
import ReportsControls from "../../components/ReportsControls.vue";
import EmployeeReportsTable from "./components/EmployeeReportsTable.vue";
import TableFilter from "../../components/TableFilter.vue";
import useReportTable from "../../hooks/useReportTable";
import reportsApiController from "@/api/reportsApiController";
import ExportForm from "../../components/ExportForm.vue";
import exportApiController from "@/api/exportApiController";

const { loading: reportsLoading, reports } = useReportTable(
	reportsApiController.getEmployeesReports,
	exportApiController.exportReports,
	{ employeesActive: true }
);
</script>
