import { ref } from 'vue';
import usersApiController from '../../api/usersApiController';

const useCreateEmployee = () => {
	const loading = ref(false);

	const createEmployee = async (data) => {
		loading.value = true;

		const response = await usersApiController.createUser(data);

		loading.value = false;
		return response;
	};

	return {
		loading,
		createEmployee,
	};
};

export default useCreateEmployee;
