import { createWebHistory, createRouter, START_LOCATION } from "vue-router";
import Login from "../views/Login";
import Companies from "@/views/admin/companies/views/list/CompaniesList.vue";
import ResetPassword from "@/views/admin/reset-password/ResetPassword";
import ResetPasswordSuccess from "@/views/admin/reset-password/ResetPasswordSuccess";
import { useAuth } from "../store/auth";
import { USER_ROLES } from "../constants/userRoles";
import employeesRoutes from "./admin/employees-routes";
import companiesRoutes from "@/router/admin/companies-routes";
import userRoutes from "./user/user-routes";
import {ADMIN_ROUTE_NAMES} from "@/constants/route-names";

/**
 * @type {import('vue-router').RouteRecordRaw[]}
 */
const routes = [
	{
		path: "/",
		name: "Home",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			notAuthenticatedOnly: true,
			pageTitle: "Login",
		},
	},
	{
		path: "/companies",
		name: "companies",
		component: Companies,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Companies",
		},
	},
	{
		path: "/reset-password",
		name: ADMIN_ROUTE_NAMES.RESET_PASSWORD,
		component: ResetPassword,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Reset password",
		},
	},
	{
		path: "/reset-password/success",
		name: ADMIN_ROUTE_NAMES.RESET_PASSWORD_SUCCESS,
		component: ResetPasswordSuccess,
		meta: {
			requiresAuth: true,
			userRoles: [USER_ROLES.ADMIN],
			pageTitle: "Reset password",
		},
	},
	...employeesRoutes,
	...companiesRoutes,
	...userRoutes,
	{
		path: "/:catchAll(.*)",
		name: "notDefined",
		redirect: "/",
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const auth = useAuth();

	if (from === START_LOCATION) {
		if (!auth.isAuthenticated && auth.token) {
			await auth.getProfile();
		}
	}

	if (to.meta.requiresAuth) {
		if (!auth.isAuthenticated) {
			next("/login");
			return;
		}
	}

	if (to.meta.notAuthenticatedOnly) {
		if (auth.isAuthenticated) {
			next("/");
			return;
		}
	}

	if (to.meta.userRoles) {
		if (!to.meta.userRoles.includes(auth.userRole)) {
			next("/");
			return;
		}
	}

	next();
});

export default router;
