import {ref} from 'vue';
import expenseTypesApiController from '../../../../api/accounts/expenseTypesApiController';

const useUpdateExpenseType = () => {
    const loading = ref(false);

    const updateExpenseType = async (id, data) => {
        loading.value = true;

        const response = await expenseTypesApiController.updateExpenseType(id, data);

        loading.value = false;
        return response;
    };

    return {
        loading,
        updateExpenseType,
    };
};

export default useUpdateExpenseType;
