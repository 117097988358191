<template>
	<tr class="history">
		<TableCell>{{ report.id }}</TableCell>
		<TableCell :highlighted="history.changed.includes('reported_at')">
			{{ responseDateToDisplay(report.reported_at) }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('type')">
			{{ capitalize(report.type) }}
		</TableCell>
		<TableCell>
			{{ report.reporter.name }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('company')">
			{{ report.company.name }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('account')">
			{{ report.account.name }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('amount')">
			{{ report.amount }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('currency')">
			{{ report.currency.name }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('payment_type')">
			{{ report.payment_type.name }}
		</TableCell>
		<TableCell :highlighted="history.changed.includes('expense')">
			{{ report.expense?.name ? report.expense.name : "---"}}
		</TableCell>
		<TableCell popover clamp :highlighted="history.changed.includes('description')">
			{{ report.description }}
		</TableCell>
		<TableCell clamp popover>
			<div v-if="report.deleted_at">
				{{ responseDateToDisplay(report.deleted_at) }} - report deleted.
			</div>
			<div
				v-for="(message, index) in history.messages"
				:key="`report-${report.id}-message-${index}`"
			>
				{{ message }}
			</div>
			<div v-if="report.reported_at">
				{{ responseDateToDisplay(report.reported_at) }} - report created.
			</div>
		</TableCell>
	</tr>
</template>

<script setup>
import TableCell from "@/views/user/components/TableCell.vue";
import { responseDateToDisplay, capitalize } from "@/helpers/strings";
import useReportHistory from "@/views/user/hooks/useReportHistory";

const props = defineProps({
	report: {
		type: Object,
		required: true,
	},
});


const history = useReportHistory(props.report);
</script>
