import GeneralApiController from '../GeneralApiController';

class PaymentTypesApiController extends GeneralApiController {
  createPaymentType = (id, data) => this.http.post(`accounts/${id}/payment-types`, data);

  updatePaymentType = (id, data) => this.http.patch(`payment-types/${id}`, data);

  deletePaymentType = (id) => this.http.delete(`payment-types/${id}`);
}

const paymentTypesApiController = new PaymentTypesApiController();

export default paymentTypesApiController;
