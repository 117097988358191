import {reactive, toRefs} from 'vue';
import expenseTypesApiController from '../../../../api/accounts/expenseTypesApiController';

const useDeleteExpenseType = () => {
	const state = reactive({
		loading: false,
		error: false,
	});

	const deleteExpenseType = async (id) => {
		state.loading = true;

		try {
			const response = await expenseTypesApiController.deleteExpenseType(id);
			return response
		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	return {
		...toRefs(state),
		deleteExpenseType,
	};
};

export default useDeleteExpenseType;
