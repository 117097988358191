<template>
	<component
		:is="href ? 'a' : 'button'"
		:href="href"
		class="btn"
		:class="btnClass"
	>
		<span v-if="$slots.icon" class="btn__icon">
			<slot name="icon"></slot>
		</span>
		<span class="btn__text">{{ loading ? 'SENDING...' : text }}</span>
	</component>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	text: [String, Object, Array],
	href: {
		type: String,
		default: null,
	},
	outline: {
		type: Boolean,
		default: false,
	},
	medium: {
		type: Boolean,
		default: false,
	},
	small: {
		type: Boolean,
		default: false,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	block: {
		type: Boolean,
		default: false,
	},
	expand: {
		type: Boolean,
		default: false,
	},
	back: {
		type: Boolean,
		default: false,
	},
	square: {
		type: Boolean,
		default: false,
	},
});

const btnClass = computed(() => {
	return [
		props.outline && 'btn--outline',
		props.medium && 'btn--medium',
		props.small && 'btn--small',
		props.loading && 'btn--loading',
		props.block && 'btn--block',
		props.expand && 'btn--expand',
		props.back && 'btn--back',
		props.square && 'btn--square',
	];
});
</script>

<style lang="scss" scoped src="@/assets/scss/components/buttons.scss"></style>
