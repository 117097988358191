import { ref } from "vue"
import reportsApiController from "../../api/reportsApiController"
import useNotifications from "../../store/notifications"
import {ADMIN_ROUTE_NAMES} from "@/constants/route-names";
import {useRouter} from "vue-router";


const useDeleteAllReports = () => {
	const loading = ref(false)
	const router = useRouter()

	const { showNotification } = useNotifications();

	const deleteAllReports = async (password, password_confirmation) => {
		loading.value = true
		const response = await reportsApiController.deleteAllReports(password, password_confirmation)

		if (response.data.success) {
			showNotification({
				message: 'All reports successfully deleted.'
			})
			router.push({name: ADMIN_ROUTE_NAMES.REPORTS_FINISH})
		}

		loading.value = false
		return response
	}

	return {
		loading,
		deleteAllReports,
	}
}

export default useDeleteAllReports;
