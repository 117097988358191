import GeneralApiController from './GeneralApiController';

class HistoryApiController extends GeneralApiController {
	getUserHistory = (query = '') => this.http.get(`reports-history?${query}`);

	getEmployeeHistory = (query = '') => this.http.get(`management/reports-history?${query}`);
}

const historyApiController = new HistoryApiController({
	returnOnlyData: false
});

export default historyApiController;
