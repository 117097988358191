<template>
	<PageLayout :loading="loading">
		<h1>Change password for Superadmin</h1>
		<p>This password will be required for log in</p>
		<h3>For start the process you have to enter password and confirm it </h3>
		<div class="layout">
			<div class="layout__col--1of4">
				<FormGroup
						label="Password"
						stroke
						:error="$v.password.$error"
						:error-message="getErrorMessages($v.password.$errors)"
				>
					<PrimaryInputVue type="password" placeholder="******" v-model="state.password"/>
				</FormGroup>
				<FormGroup
						label="Confirm Password"
						stroke
						:error="$v.password_confirmation.$error"
						:error-message="getErrorMessages($v.password_confirmation.$errors)"
				>
					<PrimaryInputVue
							type="password"
							placeholder="******"
							v-model="state.password_confirmation"
					/>
				</FormGroup>
				<div class="buttons">
					<AppButton :disabled="$v.$error || (state.password.length === 0 && state.password_confirmation.length === 0) ? true: false" expand text="CONFIRM" @click="submitHandler"/>
				</div>
			</div>
		</div>
	</PageLayout>
</template>

<script setup>
import PageLayout from '@/components/layouts/PageLayout.vue';
import AppButton from '@/components/base/AppButton.vue';
import useConfirmation from '@/store/confirmation';
import FormGroup from '@/components/form-components/FormGroup.vue';
import PrimaryInputVue from '@/components/form-components/PrimaryInput';
import useResetPassword from "@/hooks/useResetPassword";
import {minLength, required, sameAs} from "@vuelidate/validators";
import {getErrorMessages} from "@/helpers/strings";
import {toRef, reactive} from "vue";
import useVuelidate from "@vuelidate/core";
import useNotifications from "@/store/notifications"
import {ADMIN_ROUTE_NAMES} from "@/constants/route-names";
import {useRouter} from "vue-router";

const {showNotification} = useNotifications();
const {resetPassword, loading} = useResetPassword();
const confirm = useConfirmation();
const router = useRouter()

const state = reactive({
	password: '',
	password_confirmation: "",
})

const rules = {
	password: {
		required: required,
		minLength: minLength(8),
	},
	password_confirmation: {
		required: required,
		sameAs: sameAs(toRef(state, "password")),
	},
};

const $v = useVuelidate(rules, state);


const submitHandler = async () => {

	$v.value.$touch();
	confirm.showConfirmation({
		title: 'Change password',
		text: 'By press “Yes” button, password for superadmin will be changed. Are you sure?',
		onSubmit: async () => {
			const response = await resetPassword({
				password: state.password,
				password_confirmation: state.password_confirmation
			})
			if (response.success) {
		  loading.value = true;
				showNotification({
					message: 'Password was changed!'
				})
				router.push({name: ADMIN_ROUTE_NAMES.RESET_PASSWORD_SUCCESS})
			} else {
				loading.value = false;
			}
		},
	});
}

</script>


<style scoped>
h1 {
	margin-bottom: 16px;
	font-size: 34px;
	font-weight: 500;
}

p {
	margin-bottom: 54px;
}

h3 {
	margin-bottom: 24px;
}

.buttons {
	margin-top: 34px;
}
</style>
