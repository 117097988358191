import { ref } from 'vue';
import reportsApiController from '../../api/reportsApiController';

const useCreateReport = () => {
	const loading = ref(false);

	const createReport = async (data) => {
		loading.value = true;

		const response = await reportsApiController.createReport(data);

		loading.value = false;

		return response;
	};

  return {
    loading,
    createReport,
  }
};

export default useCreateReport;
