import { ref } from 'vue';
import usersApiController from '../../api/usersApiController';

const useDeleteEmployee = () => {
	const loading = ref(false)

	const deleteEmployee = async (id) => {
		loading.value = true;

		const response = await usersApiController.deleteUser(id);

		loading.value = false;
		return response;
	};

	return {
		loading,
		deleteEmployee,
	};
};

export default useDeleteEmployee;
