import { ref } from "vue";

const metaEl = document.querySelector("#viewport-meta");

export const isForzenViewport = ref(false);

export const freezeViewport = () => {
	metaEl.setAttribute("content", "width=1280");
	document.body.style.width = "1280px";
	isForzenViewport.value = true;
};

export const unfreezeViewport = () => {
	metaEl.setAttribute("content", "width=device-width,initial-scale=1.0,maximum-scale=1");
	document.body.style.width = "";
	isForzenViewport.value = false;
};
