<template>
	<div class="company-form">
		<AppButton
			v-if="companiesData.selected.length === 0"
			class="company-form__col-btn"
			expand
			outline
			text="Add Company"
			@click="addCompanyModalOpen = true"
		>
			<template #icon>
				<PlusIcon />
			</template>
		</AppButton>
		<div
			v-for="(company, companyIndex) in companiesData.selected"
			:key="`company-${company.id}`"
			class="company-form__company-row"
		>
			<div class="company-form__company-col">
				<div v-if="companyIndex === 0" class="company-form__col-title">Companies</div>
				<div class="company-form__col-body">
					<SinglePreview
						:title="company.name"
						show-delete
						@delete="handleCompanyDelete(company.id, company.accounts)"
					/>
				</div>
				<AppButton
					v-if="
						companyIndex === companiesData.selected.length - 1 &&
						companiesData.notSelected.length
					"
					class="company-form__col-btn"
					outline
					expand
					text="Add Company"
					@click="addCompanyModalOpen = true"
				>
					<template #icon>
						<PlusIcon />
					</template>
				</AppButton>
			</div>

			<div class="company-form__account-row">
				<AppButton
					v-if="
						splitSelected(selectedAccounts, company.accounts).selected
							.length === 0
					"
					class="company-form__col-btn"
					outline
					expand
					text="Add Account"
					@click="openAddAccountModal(company)"
					v-bind:style="{ marginTop: companyIndex === 0 ? '35px' : '0px' }"
				>
					<template #icon>
						<PlusIcon />
					</template>
				</AppButton>
				<div
					v-for="(account, accountIndex) in splitSelected(
						selectedAccounts,
						company.accounts
					).selected"
					:key="account.id"
					class="company-form__account"
				>
					<div class="company-form__col">
						<div v-if="companyIndex === 0 && accountIndex === 0" class="company-form__col-title">Accounts</div>
						<div class="company-form__col-body">
							<SinglePreview :title="account.name" show-delete @delete="handleAccountDelete(account.id)" />
						</div>
						<AppButton
							v-if="
								accountIndex ===
								splitSelected(selectedAccounts, company.accounts).selected
									.length -
								1 &&
								splitSelected(selectedAccounts, company.accounts).notSelected
									.length
							"
							class="company-form__col-btn"
							outline
							expand
							text="Add Account"
							@click="openAddAccountModal(company)"
						>
							<template #icon>
								<PlusIcon />
							</template>
						</AppButton>
					</div>

					<div class="comapny-form__col">
						<template v-if="userRole === USER_ROLES.MANAGER">
							<div
								v-if="companyIndex === 0 && accountIndex === 0"
								class="company-form__col-title"
							>Tracked employees</div>
							<div
								v-if="
									splitSelected(selectedEmployees, account.employees).selected
										.length > 0
								"
								class="company-form__col-body"
							>
								<SinglePreview
									v-for="employee in splitSelected(
										selectedEmployees,
										account.employees
									).selected"
									:key="employee.id"
									:title="employee.name"
									show-delete
									@delete="handleEmployeeDelete(employee.id)"
								/>
							</div>
							<AppButton
								v-if="
									account.employees.length > 0 &&
									splitSelected(selectedEmployees, account.employees)
										.notSelected.length !== 0
								"
								class="company-form__col-btn"
								outline
								expand
								text="Add Employee"
								@click="openEmployeeModal(account)"
							>
								<template #icon>
									<PlusIcon />
								</template>
							</AppButton>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
	<AddCompanyModal
		v-if="companiesData.notSelected.length > 0"
		:open="addCompanyModalOpen"
		:companies-list="companiesData.notSelected.sort((a, b) => a.name.localeCompare(b.name))"
		@change="processAddedCompanies"
		@close="addCompanyModalOpen = false"
	/>
	<AddAccountModal
		v-if="companiesData.selected.length > 0"
		:open="addAccountModalOpen"
		:accounts-list="modalAccountsList"
		@change="processAddedAccounts"
		@close="addAccountModalOpen = false"
	/>
	<AddEmployeeModal
		v-if="modalEmployeeList.length > 0"
		:open="addEmployeeModalOpen"
		:employees-list="modalEmployeeList"
		@change="processAddedEmployees"
		@close="addEmployeeModalOpen = false"
	/>
</template>

<script setup>
import { computed, ref } from "vue";
import SinglePreview from "../../../../components/preview/SinglePreview.vue";
import { splitSelected } from "../helpers/splitSelected";
import AppButton from "@/components/base/AppButton.vue";
import AddCompanyModal from "./AddCompanyModal.vue";
import AddAccountModal from "./AddAccountModal.vue";
import AddEmployeeModal from "./AddEmployeeModal.vue";
import { USER_ROLES } from "../../../../constants/userRoles";
import useConfirmation from "../../../../store/confirmation";
import PlusIcon from "@/assets/icons/plus.svg";

const props = defineProps({
	employeeId: {
		type: Number,
		default: null,
	},
	selectedCompanies: {
		type: Array,
		required: true,
	},
	selectedAccounts: {
		type: Array,
		required: true,
	},
	selectedEmployees: {
		type: Array,
		required: true,
	},
	companies: {
		type: Array,
		required: true,
	},
	userRole: {
		type: String,
		required: true,
	},
});

const emit = defineEmits([
	"update:selected-companies",
	"update:selected-accounts",
	"update:selected-employees",
]);

const confirmation = useConfirmation();

const companiesData = computed(() =>
	splitSelected(props.selectedCompanies, props.companies)
);

const addCompanyModalOpen = ref(false);
const processAddedCompanies = (newCompanies) => {
	addCompanyModalOpen.value = false;
	emit("update:selected-companies", [
		...props.selectedCompanies,
		...newCompanies,
	]);
};

const addAccountModalOpen = ref(false);
const modalAccountsList = ref([]);

const openAddAccountModal = (company) => {
	modalAccountsList.value = splitSelected(
		props.selectedAccounts,
		company.accounts
	).notSelected;
	addAccountModalOpen.value = true;
};

const processAddedAccounts = (newAccounts) => {
	addAccountModalOpen.value = false;
	modalAccountsList.value = [];
	emit("update:selected-accounts", [...props.selectedAccounts, ...newAccounts]);
};

const addEmployeeModalOpen = ref(false);
const modalEmployeeList = ref([]);

const openEmployeeModal = (account) => {
	modalEmployeeList.value = splitSelected(
		props.selectedEmployees,
		account.employees
	).notSelected.filter((employee => employee.id !== props.employeeId));
	addEmployeeModalOpen.value = true;
};

const processAddedEmployees = (newEmployees) => {
	addEmployeeModalOpen.value = false;
	modalEmployeeList.value = [];
	emit("update:selected-employees", [
		...props.selectedEmployees,
		...newEmployees,
	]);
};

const handleCompanyDelete = (id, accounts) => {
	const deleteAccounts = accounts.map((account) => account.id);

	confirmation.showConfirmation({
		title: "Delete company?",
		onSubmit: () => {
			emit(
				"update:selected-companies",
				props.selectedCompanies.filter((companyId) => companyId !== id)
			);
			emit(
				"update:selected-accounts",
				props.selectedAccounts.filter((item) => !deleteAccounts.includes(item))
			);
		},
	});
};

const handleAccountDelete = (id) => {
	confirmation.showConfirmation({
		title: "Delete account?",
		onSubmit: () => {
			emit(
				"update:selected-accounts",
				props.selectedAccounts.filter((accountId) => accountId !== id)
			);
		},
	});
};

const handleEmployeeDelete = (id) => {
	confirmation.showConfirmation({
		title: "Delete employee?",
		onSubmit: () => {
			emit(
				"update:selected-employees",
				props.selectedEmployees.filter((employeeId) => employeeId !== id)
			);
		},
	});
};
</script>

<style scoped>
.company-form {
	display: grid;
	grid-template-columns: 1fr;
	gap: 34px;
}

.company-form__company-row {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 32px;
}

.company-form__company-row:not(:last-child) {
	padding-bottom: 34px;
	border-bottom: 1px solid #9b9b9b;
}

.company-form__account-row {
	grid-column: span 2;
}

.company-form__account {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;
}

.company-form__account:not(:last-child) {
	border-bottom: 1px dashed #e0e0e0;
}

.company-form__account:not(:last-child) {
	margin-bottom: 24px;
	padding-bottom: 24px;
}

.company-form__col-body {
	display: grid;
	grid-template-columns: 1fr;
	gap: 24px;
}

.company-form__col-body:not(:last-child) {
	margin-bottom: 24px;
}

.company-form__col-btn {
	justify-self: start;
}

.company-form__col-title {
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}
</style>
