<template>
	<div class="notification-container">
		<Notification
			v-for="item in notifications.active"
			:key="`notf-${item.id}`"
			:type="item.type"
			:message="item.message"
		/>
	</div>
</template>

<script setup>
import useNotifications from '../../store/notifications';
import Notification from './Notification.vue';

const notifications = useNotifications();
</script>

<style lang="scss" scoped>
.notification-container {
	position: fixed;
	top: 24px;
	right: 0;
	z-index: 199;

	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
	max-width: 600px;
	width: 100%;
	padding: 0 16px;
}
</style>
