<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li v-for="(tab, index) in tabs" :key="`tab-${index}`"
          class="tab"
          :class='{ "active": index == selectedIndex } '
          @click="selectedIndex = index">
        {{ tab.name }}
      </li>
    </ul>
    <div class="tabs__content">
      <slot :index="selectedIndex"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTabs",
  data() {
    return {
      selectedIndex: 0,
    }
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>

.tabs__header {
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #E0E0E0;
}
.tabs__header .tab:not(:last-child) {
  margin-right: 54px;
}
.tabs__header .tab {
  color: #2D5BE3;
  padding-bottom: 8px;
  opacity: .7;
  cursor: pointer;
  margin-bottom: 10px;
}
.tabs__header .tab.active {
  border-bottom: 2px solid #2D5BE3;
  opacity: 1;
}


</style>