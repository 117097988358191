import { computed, onMounted, reactive, toRefs, watchEffect } from "vue";
import { useAuth } from "@/store/auth";
import { USER_ROLES } from "@/constants/userRoles";
import { isForzenViewport, freezeViewport, unfreezeViewport } from "@/helpers/viewport"

const state = reactive({
	isListening: false,
	size: {
		width: window.innerWidth,
		height: window.innerHeight,
	},
});

const setDimensions = () => {
	state.size.width = window.innerWidth;
	state.size.height = window.innerHeight;
};

const resizeHandler = () => {
	if (window.requestAnimationFrame) {
		window.requestAnimationFrame(setDimensions);
	} else {
		setDimensions();
	}
};

const useWindowResize = () => {
	const auth = useAuth();

	onMounted(() => {
		if (!state.listener) {
			state.isListening = true;
			window.addEventListener("resize", resizeHandler);
		}
	});

	const isMobile = computed(() => {
		return state.size.width < 1280;
	});

	watchEffect(() => {
		if (auth.userRole !== USER_ROLES.ADMIN) return;

		if (!isForzenViewport.value && state.size.width < 1281) {
			freezeViewport();
		}

		if (isForzenViewport.value && state.size.width >= 1281) {
			unfreezeViewport();
		}
	});

	return {
		...toRefs(state.size),
		isMobile,
	};
};

export default useWindowResize;
