<template>
	<div class="header-mobile">
		<div class="mobile-menu">
			<div class="mobile-menu__title">{{ route.meta.pageTitle }}</div>
			<div class="mobile-menu__burger" @click="open = true">
				<svg
					width="25"
					height="24"
					viewBox="0 0 25 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M22.6321 18.7444C23.2529 18.7444 23.7561 19.2476 23.7561 19.8684C23.7561 20.445 23.3222 20.92 22.7633 20.9849L22.6321 20.9926H2.39891C1.77808 20.9926 1.2749 20.4893 1.2749 19.8684C1.2749 19.292 1.70885 18.8169 2.26773 18.752L2.39889 18.7444L22.6321 18.7444ZM22.6321 10.8761C23.2529 10.8761 23.7561 11.3793 23.7561 12.0001C23.7561 12.5765 23.3222 13.0517 22.7633 13.1166L22.6321 13.1241H2.39891C1.77808 13.1241 1.2749 12.621 1.2749 12.0001C1.2749 11.4237 1.70885 10.9486 2.26773 10.8836L2.39889 10.8761H22.6321ZM22.6321 3.00732C23.2529 3.00732 23.7561 3.5107 23.7561 4.13152C23.7561 4.70793 23.3222 5.18307 22.7633 5.24801L22.6321 5.25554H2.39891C1.77808 5.25554 1.2749 4.75235 1.2749 4.13153C1.2749 3.55493 1.70885 3.07998 2.26773 3.01504L2.39889 3.00733L22.6321 3.00732Z"
						fill="#FBFBFB"
					/>
				</svg>
			</div>
			<div v-if="open" class="mobile-menu__box">
				<div class="mobile-menu-actions">
			<div class="mobile-menu__logout">
				<button class="header-logout header-link" @click="logout">
					<LogoutIcon />
					<span class="header-link__text">LOG OUT</span>
				</button>
			</div>
			<div class="mobile-menu__close" @click="open = false">
				<svg
						width="25"
						height="24"
						viewBox="0 0 25 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_345_100663)">
						<path
								d="M22.1154 0.796728L12.9205 9.99155C12.544 10.3681 11.9478 10.3681 11.6025 9.99155L2.40767 0.796728C2.03116 0.420215 1.4349 0.420215 1.08964 0.796728C0.713132 1.17324 0.713132 1.7695 1.08964 2.11476L10.2845 11.3096C10.661 11.6861 10.661 12.2824 10.2845 12.6276L1.05826 21.8538C0.681751 22.2303 0.681751 22.8266 1.05826 23.1719C1.43478 23.5484 2.03104 23.5484 2.37629 23.1719L11.5711 13.977C11.9476 13.6005 12.5439 13.6005 12.8891 13.977L22.1154 23.2032C22.4919 23.5797 23.0881 23.5797 23.4334 23.2032C23.8099 22.8267 23.8099 22.2305 23.4334 21.8852L14.2699 12.659C13.8934 12.2825 13.8934 11.6862 14.2699 11.341L23.4648 2.14614C23.8413 1.76963 23.8413 1.17336 23.4648 0.828109C23.0883 0.451378 22.492 0.451378 22.1155 0.796588L22.1154 0.796728Z"
								fill="#FBFBFB"
						/>
					</g>
					<defs>
						<clipPath id="clip0_345_100663">
							<rect
									width="24"
									height="24"
									fill="white"
									transform="translate(0.261719)"
							/>
						</clipPath>
					</defs>
				</svg>
			</div>
				</div>
				<nav class="mobile-nav">
					<ul class="mobile-nav__list">
						<li
							v-for="(item, index) in currentNavigationItems"
							:key="`nav-item-${index}`"
							class="mobile-nav__list-item"
						>
							<RouterLink
								custom
								:to="{ name: item.name }"
								v-slot="{ navigate, href, isActive }"
							>
								<a
									:href="href"
									class="header-link"
									:class="{ active: isActive }"
									@click="navigate"
								>
									<component
										v-if="item.icon"
										:is="item.icon"
										class="header-link__icon"
									></component>
									<span class="header-link__text">{{ item.text }}</span>
								</a>
							</RouterLink>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useAuth } from "../../store/auth";
import useNavigationItems from "./useNavigationItems";
import LogoutIcon from "@/assets/icons/logout.svg";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import useConfirmation from "@/store/confirmation";

const auth = useAuth();
const confirm = useConfirmation();
const route = useRoute();
const navigationItems = useNavigationItems();
const open = ref(false);

watch(
	() => route.path,
	() => {
		open.value = false;
	}
);
watch(
	() => open.value,
	() => {
		if (open.value) {
			document.querySelector("body").style.overflowY = "hidden";
		} else {
			document.querySelector("body").style.overflowY = "auto";
		}
	}
);

const currentNavigationItems = computed(() => {
	if (auth.onboardingActive) {
		return navigationItems.value.onboarding;
	}
	return navigationItems.value[auth.userRole];
});

const logout = () => {
	confirm.showConfirmation({
		title: "Log out?",
		text: "Are you sure you want to log out?",
		onSubmit: () => {
			auth.logout();
		},
	});
};
</script>

<style lang="scss" scoped src="@/assets/scss/components/header.scss"></style>
