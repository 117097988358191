<template>
	<div class="layout">
		<div class="layout layout__col">
			<div class="employee-wrapper">
				<div class="employee-box" v-for="account in companyData.accounts" :key="account.id">
					<div class="employee-box__title">{{ account.name }}</div>
					<div class="employee-box__lists">
						<EditCompanyEmployeesList
								:employees-list="processEmployees(account.employees)"
								:account-id="account.id"
								:company-id="companyData.id"
								:employees-full-list="employees"
								@refetch="$emit('refetch')"
						/>
					</div>
				</div>
				<RouterLink custom
										:to="{name: ADMIN_ROUTE_NAMES.COMPANIES_LIST} "
										v-slot="{ href, navigate }">
					<AppButton :href="href" text="CONFIRM" @click="navigate" style="min-width: 200px">
					</AppButton>
				</RouterLink>
			</div>

		</div>
	</div>
</template>

<script setup>

import EditCompanyEmployeesList from "@/views/admin/companies/views/edit/components/EditCompanyEmployeesList"
import AppButton from "@/components/base/AppButton"
import {ADMIN_ROUTE_NAMES} from "@/constants/route-names";

defineProps({
	companyData: {
		type: Object,
		default: null,
	},
	employees: {
		type: Object,
		default: null,
	},
})


defineEmits(['refetch']);
const processEmployees = (employees) => {
	return employees.reduce((acc, employee) => {
				const role = employee.roles[0]
				acc[role].push({id: employee.id, name: employee.name})
				return acc
			},
			{
				accountant: [],
				manager: [],
				employee: [],
			}
	)
}

</script>

<style scoped>
	.layout__col {
			grid-column: span 4;
	}
</style>
