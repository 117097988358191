<template>
	<BaseModal :open="open" @close="$emit('close')">
		<div class="storage-modal">
			<div class="storage-modal__title">{{ title }}</div>
			<FormGroup
				:label="title"
				stroke
				:error="$v.name.$error"
				:error-message="getErrorMessages($v.name.$errors)"
			>
				<PrimaryInput :placeholder="placeholder" v-model="state.name" />
			</FormGroup>
			<AppButton text="CONFIRM" :loading="loading" @click="handleSubmit" />
		</div>
	</BaseModal>
</template>

<script setup>
import BaseModal from "../../../../../components/modals/BaseModal.vue";
import FormGroup from "../../../../../components/form-components/FormGroup.vue";
import PrimaryInput from "../../../../../components/form-components/PrimaryInput.vue";
import AppButton from "@/components/base/AppButton.vue";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { getErrorMessages } from "../../../../../helpers/strings";

const props = defineProps({
	name: {
		type: String,
		default: "",
	},
	open: {
		type: Boolean,
		default: false,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: "",
	},
	title: {
		type: String,
		default: "",
	}
});

const emit = defineEmits(["submit", "close"]);

const state = reactive({
	name: props.name,
});

const $v = useVuelidate(
	{
		name: {
			required,
			minLength: minLength(4),
		},
	},
	state
);

const handleSubmit = () => {
	$v.value.$touch();
	if (!$v.value.$invalid) {
		emit("submit", state);
	}
};
</script>

<style scoped lang="scss">
.storage-modal {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 32px;

	&__title {
		font-weight: 500;
		font-size: 24px;
	}
}
</style>
