<template>
	<BaseModal :open="open" @close="$emit('close')">
		<div class="wrapper">
			<div class="modal__content-title">Add employees</div>
			<div class="modal__content-desc">Select and add employees to this role</div>
			<div class="modal__content-list">
				<VCheckBox
					v-for="employee in employeesList"
					class="modal__content-list__item"
					:key="`checkbox-${employee.id}`"
					:label="employee.name"
					:value="employee.id"
					v-model="selected"
				/>
			</div>
			<div class="modal__buttons">
				<AppButton text="Add" expand @click="submit" />
			</div>
		</div>
	</BaseModal>
</template>

<script setup>
import BaseModal from '../../../../components/modals/BaseModal.vue';
import VCheckBox from '../../../../components/form-components/VCheckBox.vue';
import { ref } from 'vue';
import AppButton from '../../../../components/base/AppButton.vue';

defineProps({
	open: {
		type: Boolean,
		default: false,
	},
	employeesList: {
		type: Array,
		required: true,
	},
});

const emit = defineEmits(['change', 'close']);

const selected = ref([]);

const submit = () => {
	emit('change', selected.value)
};
</script>
