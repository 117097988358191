import {reactive, toRefs} from 'vue';
import companiesApiController from '../../api/companiesApiController';

const useCreateCompany = () => {
  const state = reactive({
    loading: false,
    error: false,
  });

  const createCompany = async (data) => {
    state.loading = true;

    try {
      const response = await companiesApiController.createCompany(data);
	  return response
    } catch (error) {
      state.error = error;
    }

    state.loading = false;
  };


  return {
    ...toRefs(state),
    createCompany,
  };
};

export default useCreateCompany;
