<template>
	<div v-if="isFiltered" class="active-filters">
		<div
			v-if="
				filterState.period && filterState.periodFrom && filterState.periodTo
			"
			class="active-filters__item"
		>
			<button class="active-filters__item-button" @click="handleResetPeriod">
				<CrossIcon />
			</button>
			<div class="active-filters__item-text">
				{{ responseDateToDisplay(filterState.periodFrom) }} -
				{{ responseDateToDisplay(filterState.periodTo) }}
			</div>
		</div>
		<div v-if="filterState.type" class="active-filters__item">
			<button class="active-filters__item-button" @click="handleResetType">
				<CrossIcon />
			</button>
			<div class="active-filters__item-text">
				{{ capitalize(filterState.type) }}
			</div>
		</div>
	</div>
</template>

<script setup>
import CrossIcon from "@/assets/icons/cross.svg";
import { inject } from "vue";
import { FILTER_CONTROLLER_KEY } from "../constants/injectionKeys";
import { capitalize, responseDateToDisplay } from "@/helpers/strings";

const {
	isFiltered,
	filterState,
	resetFilter,
	resetPeriod,
	resetType,
	applyFilter,
	activeFilters,
} = inject(FILTER_CONTROLLER_KEY);

const handleResetType = () => {
	resetType();
	if (
		activeFilters.value.period ||
		activeFilters.value.company ||
		activeFilters.value.accounts ||
		activeFilters.value.paymentTypes ||
		activeFilters.employees
	) {
		applyFilter();
	} else {
		resetFilter();
	}
};

const handleResetPeriod = () => {
	resetPeriod();
	if (
		activeFilters.value.type ||
		activeFilters.value.company ||
		activeFilters.value.accounts ||
		activeFilters.value.paymentTypes ||
		activeFilters.employees
	) {
		applyFilter();
	} else {
		resetFilter();
	}
};
</script>
