<template>
	<div class="checkbox" :class="classNames">
		<label class="checkbox-label">
			<input
				:type="type"
				v-bind="$attrs"
				:value="value"
				v-model="currentValue"
			/>
			<span class="checkbox-title">{{ label }}</span>
			<span class="checkmark"></span>
		</label>
	</div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
	modelValue: {
		type: [String, Number, Array, Boolean],
		default: "",
	},
	label: {
		type: String,
		required: true,
	},
	value: {
		type: [String, Number],
		default: null,
	},
	expand: {
		type: Boolean,
		default: false,
	},
	type: {
		type: String,
		default: "checkbox",
	},
});

const emit = defineEmits(["update:modelValue"]);

const currentValue = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit("update:modelValue", value);
	},
});

const classNames = computed(() => {
	return [props.expand && "checkbox--expand"];
});
</script>

<style
	lang="scss"
	src="@/assets/scss/components/form-components/checkbox.scss"
></style>
