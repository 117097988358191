import { ref } from 'vue';
import reportsApiController from '../../api/reportsApiController';
import useNotifications from '../../store/notifications';

const useEditReport = () => {
	const loading = ref(false);

	const { showNotification } = useNotifications();

	const editReport = async (id, data) => {
		loading.value = true;

		const response = await reportsApiController.updateReport(id, data);

		if (response.data?.success) {
			showNotification({
				message: `Report ${id} successfully edited.`,
			});
		}

		loading.value = false;

		return response;
	};

  return {
    loading,
    editReport,
  }
};

export default useEditReport;
