<template>
	<div class="header-desktop">
		<nav class="header-nav">
			<ul class="header-nav__list">
				<li
					v-for="(item, index) in currentNavigationItems"
					:key="`nav-item-${index}`"
					class="header-nav__list-item"
					:class="{ ['header-onboarding-item']: auth.onboardingActive }"
				>
					<RouterLink
						custom
						:to="{ name: item.name }"
						v-slot="{ navigate, href, isActive }"
					>
						<a
							:href="href"
							class="header-link"
							:class="{ active: isActive || item.name === adminActive }"
							@click="navigate"
						>
							<component
								v-if="item.icon"
								:is="item.icon"
								class="header-link__icon"
							></component>
							<span class="header-link__text">{{ item.text }}</span>
						</a>
					</RouterLink>
				</li>
			</ul>
		</nav>
		<button class="header-logout header-link" @click="logout">
			<LogoutIcon />
			<span class="header-link__text">LOG OUT</span>
		</button>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useAuth } from "../../store/auth";
import useNavigationItems from "./useNavigationItems";
import LogoutIcon from "@/assets/icons/logout.svg";
import useConfirmation from "@/store/confirmation";
import { useRoute } from "vue-router";
import { USER_ROLES } from "../../constants/userRoles";
import { ADMIN_ROUTE_NAMES } from "../../constants/route-names";

const confirm = useConfirmation();
const auth = useAuth();
const route = useRoute();
const navigationItems = useNavigationItems();

const adminActive = computed(() => {
	if (auth.userRole === USER_ROLES.ADMIN) {
		if (
			[
				ADMIN_ROUTE_NAMES.COMPANIES_LIST,
				ADMIN_ROUTE_NAMES.COMPANIES_NEW,
				ADMIN_ROUTE_NAMES.COMPANIES_EDIT,
			].includes(route.name)
		) {
			return ADMIN_ROUTE_NAMES.COMPANIES_LIST;
		}

		if (
			[
				ADMIN_ROUTE_NAMES.EMLOYEES_LIST,
				ADMIN_ROUTE_NAMES.EMLOYEES_EDIT,
				ADMIN_ROUTE_NAMES.EMPLOYEES_NEW,
			].includes(route.name)
		) {
			return ADMIN_ROUTE_NAMES.EMLOYEES_LIST;
		}
	}
	return null;
});

const currentNavigationItems = computed(() => {
	if (auth.onboardingActive) {
		return navigationItems.value.onboarding;
	}
	return navigationItems.value[auth.userRole];
});

const logout = () => {
	confirm.showConfirmation({
		title: "Log out?",
		text: "Are you sure you want to log out?",
		onSubmit: () => {
			auth.logout();
		},
	});
};
</script>

<style lang="scss" scoped src="@/assets/scss/components/header.scss"></style>
