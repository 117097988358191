<template>
  <button @click="toggle" class="switch" :class="classNames" v-if="show">
    <span class="slider"></span>
  </button>
</template>

<script setup>
import {computed,} from 'vue';

const emit = defineEmits(['submit']);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  isChecked: {
    type: [Boolean, Number],
    default: false,
  },
})

const classNames = computed(() => {
  return props.isChecked ? 'switch--on' : 'switch--of';
})

const toggle = () => {
  emit('submit', !props.isChecked);
}
</script>

<style scoped lang="scss" src="@/assets/scss/components/form-components/switcher.scss">

</style>
