import axios from "axios";
import useNotifications from "../store/notifications";
import {useAuth} from "@/store/auth";
import {
	isUnauthenticatedRequest,
	isForbiddenRequest,

} from "@/helpers/request";

class GeneralApiController {
	constructor({returnOnlyData = true} = {}) {
		this.http = axios.create({
			baseURL: process.env.VUE_APP_API_URL,
		});

		this.http.interceptors.request.use(
			(config) => {
				const token = localStorage.getItem("t");
				if (token) {
					config.headers.Authorization = "Bearer " + token;
				}
				return config;
			},
			(error) => {
				if (process.env.VUE_APP_STAGING) {
					console.warn("API REQUEST ERROR", error);
				}
			}
		);

		this.http.interceptors.response.use(
			(response) => {
				if (process.env.VUE_APP_STAGING) {
					console.log(
						`${response.config.method.toUpperCase()} - ${response.config.url} `,
						response.data
					);
				}

				if (response.config.responseType === 'blob') {
					return response.data
				}

				return returnOnlyData
					? {...response.data, success: true}
					: {
						...response,
						data: {
							...response.data,
							success: true,
						},
					};
			},
			(error) => {
				if (process.env.VUE_APP_STAGING) {
					console.warn("API RESPONSE ERROR", error);
				}

				const response = error?.response;

				if (!response) return error;

				const notifications = useNotifications();

				if (isUnauthenticatedRequest(response.status)) {
					notifications.showNotification({
						type: "warning",
						message: "Logged out!",
					});
					const auth = useAuth();
					auth.resetAuthState();
				}

				if (isForbiddenRequest(response.status)) {
					notifications.showNotification({
						type: "warning",
						message: "Not Allowed!",
					});
				}

				const data = response.data;

				if (data.messages) {
					Object.entries(data.messages).forEach(([name, messages]) => {
						console.log(name)
						notifications.showNotification({
							type: "warning",
							message: messages.join("\n"),
						});
					});
				}
				if(data.title && data.messages.length === 0 && !isForbiddenRequest(response.status) && !isUnauthenticatedRequest(response.status)) {
					notifications.showNotification({
						type: "warning",
						message: data.title
					});
				}

				return response.data;
			}
		);
	}
}

export default GeneralApiController;
