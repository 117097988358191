<template>
	<div id="searchBox" class="mobile-form">
		<form class="search-form" id="searchform">
			<span id="noEasy">
				<span class="sb-icon-search"><SearchIcon /></span>
			</span>
			<input placeholder="Search..." type="text" v-model="searchText" />
		</form>
	</div>
</template>

<script setup>
import SearchIcon from "@/assets/icons/search.svg";
import { inject } from "vue";
import { STORAGE_CONTROLLER_KEY } from "../../views/user/constants/injectionKeys";

const { searchText } = inject(STORAGE_CONTROLLER_KEY);
</script>

<style
	scoped
	lang="scss"
	src="@/assets/scss/components/form-components/search.scss"
></style>
