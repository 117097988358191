import {reactive, toRefs} from 'vue';
import accountsApiController from '../../../api/accounts/accountsApiController';

const useCreateAccount = () => {
  const state = reactive({
    loading: false,
    error: false,
  });

  const createAccount = async (id, data) => {
    state.loading = true;

    try {
      const response = await accountsApiController.createAccount(id, data);
      return response
    } catch (error) {
      state.error = error;
    }

    state.loading = false;

  };


  return {
    ...toRefs(state),
    createAccount,
  };
};

export default useCreateAccount;
