<template>
	<div class="preview" :class="{ opened: bodyShow, 'preview--has-accordion': showAccordion }">
		<div class="preview-head">
			<div class="preview-head__box">
				<div class="preview-top">
					<h2 class="preview-title">{{ title }}</h2>
					<div v-if="showDelete || showEdit || showAccordion" class="preview-btns">
						<button v-if="showEdit" class="edit-btn" @click="$emit('edit')">
							<EditIcon />
						</button>
						<button v-if="showDelete" class="edit-btn" @click="$emit('delete')">
							<DeleteIcon />
						</button>
						<button v-if="showAccordion" class="delete-btn" @click="bodyShow = !bodyShow">
							<ArrowDownIcon />
						</button>
					</div>
				</div>
				<div v-if="info" class="preview-info">
					<div v-for="(item, i) in info" :key="`info-${i}`" class="preview-info__title">{{ item }}</div>
				</div>
			</div>
		</div>

		<div v-if="bodyShow || (!showAccordion && $slots.default)" class="preview-body">
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
import ArrowDownIcon from '@/assets/icons/arrow-down.svg';
import EditIcon from '@/assets/icons/edit.svg';
import DeleteIcon from '@/assets/icons/delete.svg';
import { ref } from 'vue';

defineEmits(['edit', 'delete'])

defineProps({
	title: {
		type: String,
		required: true,
	},
	info: {
		type: Array,
		default: null,
	},
	showEdit: {
		type: Boolean,
		default: false,
	},
	showDelete: {
		type: Boolean,
		default: false,
	},
	showAccordion: {
		type: Boolean,
		default: false
	}
});

const bodyShow = ref(false);

</script>

<style lang="scss" src="@/assets/scss/components/preview.scss"></style>
