<template>
  <PageLayout
    title="Companies"
    :count-text="companies && getCompaniesCount(companies.length)"
    :loading="loading"
  >
    <div v-if="companies" class="layout">
      <div class="layout__col">
        <div class="companies-list">
          <Preview
            v-for="item in companies"
            :key="item.id"
            :company="item"
            :title="item.name"
            :info="getCompanyInfo(item)"
            @delete="onCompanyDelete(item.id)"
            @edit="onCompanyEdit(item.id)"
            :showButtons="false"
            showAccordion
          >
            <AppTabs :tabs="item.accounts" v-slot="{ index }">
              <div v-if="item.accounts[index].employees.length === 0">No employees</div>

              <CompaniesEmployeesList
                v-else
                :employees="processEmployees(item.accounts[index].employees)"
              />
            </AppTabs>
          </Preview>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script setup>
import Preview from '@/components/preview/VPreview';
import AppTabs from '@/components/AppTabs.vue';
import PageLayout from '@/components/layouts/PageLayout.vue';
import useCompaniesList from '@/hooks/companies/useCompaniesList';
import CompaniesEmployeesList from './components/CompaniesEmployeesList.vue';
import { getCompaniesCount } from '@/helpers/strings';
import {shallowEqual} from "@/views/admin/companies/helpers/shallowEqual";
const { companies, loading } = useCompaniesList();

const getCompanyInfo = (company) => {

	const employeesCount = company.accounts.reduce((acc, account) => {

		account.employees.forEach(item => {
			if (!acc.some(k => shallowEqual(k, {id: item.id}))) {
				acc.push({id: item.id});
			}
		})

		return acc;
	}, []);

	const employeeText = employeesCount > 1 ? "employees" : "employee";

	const accountsCount = company.accounts.length;
	const accountText = accountsCount > 1 ? "accounts" : "account";

	return [
		employeesCount.length + " " + employeeText,
		accountsCount + " " + accountText,
	];
};


const processEmployees = (employees) => {
  return employees.reduce((acc, employee) => {
    const role = employee.roles[0]
    acc[role].push(employee.name)
    return acc
  },
    {
      accountant: [],
      manager: [],
      employee: [],
    }
  )
}

</script>

<style scoped>
.companies-list .preview {
  margin-bottom: 24px;
}

@media (max-width: 991px) {
  .layout__col {
    grid-column: span 4;
  }
}
</style>
