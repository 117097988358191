import { computed } from "vue";
import { responseDateToDisplay, capitalize } from "@/helpers/strings";

const formatValue = (column, value) => {
	switch (column) {
		case 'reported_at':
			return (() => {
				const [year, month, day] = value.split(' ')[0].split('-');
				return `${day}/${month}/${year}`;
			})();

		case 'amount':
			return parseFloat(value);

		default:
			return value;
	}
}

const useReportHistory = (report) => {
  if (!report) return null;
  console.log(report);
  return computed(() => {
    return report.history.reduce(
      (acc, item) => {
        if (item.column === "updated_at") return acc;

        acc.changed.push(item.column);

        const oldValue = formatValue(item.column, item.old_value);

        const newValue = formatValue(item.column, item.new_value);

        acc.messages.push(
          `${responseDateToDisplay(item.created_at)} the ${capitalize(
            item.column === 'reported_at' ? 'Date' : item.column
          )} was changed from ${oldValue} to ${newValue}.`
        );

        return acc;
      },
      {
        messages: [],
        changed: [],
      }
    );
  });
};

export default useReportHistory;
