import { reactive, toRefs } from 'vue';
import companiesApiController from '../../api/companiesApiController';

const useDeleteCompany = () => {
  const state = reactive({
    loading: false,
    error: false,
  });

  const deleteCompany = async (id) => {
    state.loading = true;

    try {
      await companiesApiController.deleteCompany(id);
    } catch (error) {
      state.error = error;
    }

    state.loading = false;
  };

  return {
    ...toRefs(state),
    deleteCompany,
  };
};

export default useDeleteCompany;
