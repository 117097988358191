<template>
  <div class="accordion">
    <slot name="header" :toggle="toggle"></slot>
    <div v-if="open" class="accordion__body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const open = ref(false);

const toggle = () => open.value = !open.value
</script>
