<template>
	<div class="company-form">
		<div class="company-form__item company-form__item--col1">
			<div class="company-form__title">{{ index !== 0 ? 'Additional account' : 'Main account' }}</div>
			<preview
					showEdit
					:showDelete="index !== 0"
					:title="accountName"
					@edit="showEditInput"
					:show-switcher="showSwitcher && index !== 0"
					@switcher-submit="switchAccount(companyData.id, { name: accountName, is_active: $event })"
					:check-state="companyData.is_active"
					@delete="deleteAccount"
			/>
			<form-group stroke v-if="companyEditInput" label="Add account name">
				<primary-input
						:value="companyEditInputData"
						v-model="companyEditInputData"
						placeholder="Type account name..."
				/>
			</form-group>
			<form-group stroke v-if="companyCreateInput" label="Add new account">
				<primary-input
						:value="companyCreateInputData"
						v-model="companyCreateInputData"
						placeholder="Create new account name..."
				/>
			</form-group>
			<AppButton
					@click="showCreateInput"
					v-if="(accountsLength - 1) === index && !companyEditInput && !companyCreateInput"
					outline
					medium
					text="add account"
			>
				<template v-slot:icon>
					<img src="/img/buttons/plus.svg" alt/>
				</template>
			</AppButton>
			<div class="btns">
				<AppButton v-if="companyEditInput" @click="editAccount" outline medium text="confirm"></AppButton>
				<AppButton v-if="companyCreateInput" @click="addAccount" outline medium text="confirm"></AppButton>
				<AppButton @click="closeAccount" v-if="companyEditInput || companyCreateInput" outline medium text='Cancel' />

			</div>
		</div>
		<div class="company-form__item">
			<div class="company-form__title">Account currencies</div>
			<div class="company-form__box">
				<preview
						v-for="(currency, index) in companyData.currencies"
						show-delete
						:incativeDelete="companyData.currencies.length === 1"
						:show-switcher="showSwitcher && companyData.currencies.length > 1"
						:check-state="!!currency.is_active"
						@switcher-submit="updateCurrency(currency.id, { name: currency.name, is_active: $event })"
						@delete="deleteCurrency(currency.id, companyData.currencies.length)"
						:title="currency.name"
						:key="index"
				></preview>
			</div>
			<form-group stroke v-if="currencyInput" label="Add account currency">
				<primary-input v-model="this.currencyInputData" placeholder="Type account currency..."/>
			</form-group>

			<div class="btns">
				<AppButton
						@click="addCurrency"
						class="btn--outline btn--medium"
						:text="this.buttonCurrencyText"
						:icon="this.buttonCurrencyIcon"
				>
					<template v-if="buttonCurrencyIcon" v-slot:icon>
						<img src="/img/buttons/plus.svg" alt/>
					</template>
				</AppButton>
				<AppButton @click="closeCurrency" v-if="currencyInput" outline medium text='Cancel' />
			</div>
		</div>

		<div class="company-form__item">
			<div class="company-form__title">Types of payment</div>
			<div class="company-form__box">
				<preview
						v-for="(payment_type, index) in companyData.payment_types"
						:key="index"
						show-delete
						:incativeDelete="companyData.payment_types.length === 1"
						:show-switcher="showSwitcher && companyData.payment_types.length > 1"
						:check-state="!!payment_type.is_active"
						@switcher-submit="updatePaymentType(payment_type.id, { name: payment_type.name, is_active: $event })"
						@delete="deletePaymentType(payment_type.id, companyData.payment_types.length)"
						:title="payment_type.name"
				></preview>
			</div>
			<form-group stroke v-if="paymentInput" label="Add type of payment">
				<primary-input v-model="paymentInputData" placeholder="Type of payment..."></primary-input>
			</form-group>
			<div class="btns">
				<AppButton @click="addPaymentType" outline medium :text="buttonPaymentText">
				<template v-if="buttonPaymentIcon" v-slot:icon>
					<icon-plus></icon-plus>
				</template>
			</AppButton>
			<AppButton @click="closePaymentType" v-if="paymentInput" outline medium text='Cancel' />
			</div>			
		</div>
		
		<div class="company-form__item">
			<div class="company-form__title">Expenses type</div>
			<div class="company-form__box">
				<preview
						v-for="(expense_type, index) in companyData?.expenses"
						:key="index"
						show-delete
						:show-switcher="showSwitcher"
						:check-state="!!expense_type.is_active"
						@switcher-submit="updateExpenseType(expense_type.id, { name: expense_type.name, is_active: $event })"
						@delete="deleteExpenseType(expense_type.id, companyData.expenses.length)"
						:title="expense_type.name"
				></preview>
			</div>
			<form-group stroke v-if="expenseInput" label="Add expenses type">
				<primary-input v-model="expenseInputData" placeholder="Expenses type..."></primary-input>
			</form-group>
			<div class="btns">
				<AppButton @click="addExpenseType" outline medium :text="buttonExpenseText">
				<template v-if="buttonExpenseIcon" v-slot:icon>
					<icon-plus></icon-plus>
				</template>
			</AppButton>
			<AppButton @click="closeExpenseType" v-if="expenseInput" outline medium text='Cancel' />
			</div>			
		</div>
	</div>
</template>

<script>

import AppButton from "@/components/base/AppButton";
import preview from "@/components/preview/SinglePreview";
import primaryInput from "@/components/form-components/PrimaryInput";
import FormGroup from "@/components/form-components/FormGroup";
import iconPlus from "/public/img/buttons/plus.svg"
import useNotifications from "@/store/notifications";


export default {
	emits: ['addAccount', 'editAccount', 'deleteAccount', 'switchAccount',
		'addCurrency', 'updateCurrency', 'deleteCurrency', 'addPaymentType',
		'updatePaymentType', 'deletePaymentType', 'addExpenseType', 'updateExpenseType', 'deleteExpenseType', 'submit'],
	name: "Company-form",
	components: {
	  AppButton, preview,
		primaryInput, FormGroup, iconPlus,
	},
	data() {
		return {
			currencyInput: false,
			paymentInput: false,
			expenseInput: false,
			buttonCurrencyText: "add currency",
			buttonPaymentText: "add type of payment",
			buttonExpenseText: "add expense type",
			buttonPaymentIcon: true,
			buttonCurrencyIcon: true,
			buttonExpenseIcon: true,
			currencyInputData: '',
			paymentInputData: '',
			expenseInputData: '',
			companyEditInputData: '',
			companyEditInput: false,
			companyCreateInputData: '',
			companyCreateInput: false,
		}
	},

	props: {
		index: Number,
		companyData: {
			type: Object,
			required: true,
		},
		accountsLength: Number,
		accountName: String,
		showSwitcher: Boolean,
	},
	methods: {
		showEditInput() {
			this.companyEditInput = true;
			this.companyCreateInput = false;
		},
		showCreateInput() {
			this.companyCreateInput = true;
			this.companyEditInput = false;
		},
		addAccount() {
			const notifications = useNotifications();
			if (!this.companyCreateInputData.trim()) {
				notifications.showNotification({
					type: "warning",
					message: "Account name field is required",
				});
			} else {
				this.$emit('addAccount', this.companyCreateInputData);
				this.companyCreateInput = false;
				this.companyCreateInputData = '';
			}

		},
		closeAccount() {
			if (this.companyCreateInput || this.companyEditInput) {
				this.companyEditInput = false;
				this.companyCreateInput = false;
				this.companyCreateInputData = '';
			}
		},
		editAccount(id) {
			const notifications = useNotifications();
			if (!this.companyEditInputData.trim()) {
				notifications.showNotification({
					type: "warning",
					message: "Account name field is required",
				});
			} else {
				this.$emit('editAccount', {
					accountId: id,
					name: this.companyEditInputData,
					is_active: true
				});
				this.companyEditInput = false;
			}
		},

		deleteAccount(id) {
			this.$emit('deleteAccount', id);
		},

		addCurrency(id) {
			const notifications = useNotifications();
			if (!this.currencyInput) {
				this.changeTypeButtonCurrency(true, 'confirm', false)
			} else {

				if (!this.currencyInputData.trim()) {
					notifications.showNotification({
						type: "warning",
						message: "Account currency field is required",
					});
				} else {
					this.$emit('addCurrency', {id: id, name: this.currencyInputData});
					this.changeTypeButtonCurrency(false, 'add currency', true)
					this.currencyInputData = '';
				}
			}

		},
		closeCurrency() {
			if (this.currencyInput) {
				this.changeTypeButtonCurrency(false, 'add currency', true)
				this.currencyInputData = '';
			}
		},
		addPaymentType(id) {
			const notifications = useNotifications();
			if (!this.paymentInput) {
				this.changeTypeButtonPayment(true, 'confirm', false)

			} else {
				if (!this.paymentInputData.trim()) {
					notifications.showNotification({
						type: "warning",
						message: "Type of payment field is required",
					});
				} else {
					this.$emit('addPaymentType', {
						id: id,
						name: this.paymentInputData
					});
					this.changeTypeButtonPayment(false, 'add type of payment', true)
					this.paymentInputData = '';

				}
			}
		},
		closePaymentType() {
			if (this.paymentInput) {
				this.changeTypeButtonPayment(false, 'add type of payment', true)
				this.paymentInputData = '';
			}
		},
		addExpenseType(id) {
			const notifications = useNotifications();
			if (!this.expenseInput) {
				this.changeTypeButtonExpense(true, 'confirm', false)
			} else {
				if (!this.expenseInputData.trim()) {
					notifications.showNotification({
						type: "warning",
						message: "Expense type field is required",
					});
				} else {
					this.$emit('addExpenseType', {
						id: id,
						name: this.expenseInputData
					});
					this.changeTypeButtonExpense(false, 'add expense type', true)
					this.expenseInputData = '';
				}
			}
		},
		closeExpenseType() {
			if (this.expenseInput) {
				this.changeTypeButtonExpense(false, 'add expense type', true)
				this.expenseInputData = '';
			}
		},
		deleteCurrency(id, length) {
			if (length > 1) {
				this.$emit('deleteCurrency', {
					accountId: this.index,
					currencyId: id,
				})
			}
		},
		deletePaymentType(id, length) {
			if (length > 1) {
				this.$emit('deletePaymentType', {
					accountId: this.index,
					paymentId: id,
				})
			}
		},
		deleteExpenseType(id, length) {
			if (length) {
				this.$emit('deleteExpenseType', {
					accountId: this.index,
					expenseId: id,
				})
			}
		},
		updateCurrency(id, {name: name, is_active: is_active}) {
			const notifications = useNotifications();
			const currencies = this.companyData.currencies;
			const activeCurrencies = [];
			currencies.forEach((currency) => {
				if (currency.is_active > 0) {
					activeCurrencies.push(currency);
				}
			})
			if (activeCurrencies.length === 1 && !is_active) {
				notifications.showNotification({
					type: "warning",
					message: "Account must have at least one currency",
				});
			} else {
				this.$emit('updateCurrency', id, {name: name, is_active: is_active})
			}
		},
		updatePaymentType(id, {name: name, is_active: is_active}) {
			const notifications = useNotifications();
			const payment_types = this.companyData.payment_types;
			const activePayments = [];
			payment_types.forEach((payment_type) => {
				if (payment_type.is_active > 0) {
					activePayments.push(payment_type);
				}
			})
			if (activePayments.length === 1 && !is_active) {
				notifications.showNotification({
					type: "warning",
					message: "Account must have at least one payment type",
				});
			} else {
				this.$emit('updatePaymentType', id, {name: name, is_active: is_active})
			}
		},
		updateExpenseType(id, {name: name, is_active: is_active}) {
			const expenses = this.companyData.expenses;
			const activeExpenses = [];
			expenses.forEach((expense_type) => {
				if (expense_type.is_active > 0) {
					activeExpenses.push(expense_type);
				}
			})			
			this.$emit('updateExpenseType', id, {name: name, is_active: is_active})
		},
		switchAccount(id, {name: name, is_active: isActive}) {
			this.$emit('editAccount', {
				accountId: id,
				name: name,
				is_active: isActive
			});
		},
		changeTypeButtonCurrency(input, text, icon) {
			this.currencyInput = input;
			this.buttonCurrencyText = text;
			this.buttonCurrencyIcon = icon;
		},
		changeTypeButtonPayment(input, text, icon) {
			this.paymentInput = input;
			this.buttonPaymentText = text;
			this.buttonPaymentIcon = icon;
		},
		changeTypeButtonExpense(input, text, icon) {
			this.expenseInput = input;
			this.buttonExpenseText = text;
			this.buttonExpenseIcon = icon;
		}
	},
}


</script>

<style lang="scss" src="@/assets/scss/components/company-form.scss">
</style>
