import {ref} from 'vue';
import currenciesApiController from '../../../../api/accounts/currenciesApiController';

const useUpdateCurrency = () => {
  const loading = ref(false);

  const updateCurrency = async (id, data) => {
    loading.value = true;

    const response = await currenciesApiController.updateCurrency(id, data);

    loading.value = false;
    return response;
  };

  return {
    loading,
    updateCurrency,
  };
};

export default useUpdateCurrency;
