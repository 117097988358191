import { onMounted, onUnmounted, watch } from "vue";

const useBodyScroll = (el, refVal) => {
	onMounted(() => {
		watch(
			() => refVal.value,
			() => {
				if (!el.value) return;

				if (refVal.value) {
					document.querySelector("body").style.overflowY = "hidden";
				}
			},
			{
				immediate: true,
				flush: "post",
			}
		);

		watch(
			() => refVal.value,
			() => {
				if (!el.value) return;

				if (!refVal.value) {
					document.querySelector("body").style.overflowY = "auto";
				}
			}
		);
	});

	onUnmounted(() => {
		document.querySelector("body").style.overflowY = "auto";
	})
};

export default useBodyScroll;
