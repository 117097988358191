<template>
  <td class="table-body__item" :class="classNames" @mouseenter="handleHover" @mouseleave="open = false">
    <div ref="content" class="item-content">
      <div ref="box" class="box" >
        <slot></slot>      
      </div>   
      <span v-if="isMobile && popover" @click="open = true" class="tooltip-btn">Read more...</span>

    </div>
    <div class="tooltip" v-if="popover && open">
      <div v-if="isMobile" @click="open = false" class="tooltip-close">
        <CloseIcon />
      </div>
        <slot></slot>
        
    </div>
  </td>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import useWindowResize from '@/hooks/useWindowResize.js';
import CloseIcon from "@/assets/icons/close.svg"


const props = defineProps({
  clamp: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
  popover: {
    type: Boolean,
    default: false
  }
})

const open = ref(false)
const box = ref(null);
const content = ref(null);
const isOverflowing = ref(false);

onMounted(()=> {

  if(box.value && content.value) {
    const boxHeight = box.value.offsetHeight;
    const contentHeight = content.value.offsetHeight;

    if(boxHeight > contentHeight) {
      isOverflowing.value = true;
    }
  }

}) 

const { width } = useWindowResize();

const isMobile = computed(() => width.value < 1280)



const classNames = computed(() => {
  return [
    props.clamp && 'table-body__item--clamp',
    props.error && 'table-body__item--error',
    props.highlighted && 'highlighted',
    props.clamp && props.popover && "table-body__item--overflow"
  ]
})

const handleHover = () => {
  if (!isMobile.value) {
    open.value = true
  }
}

</script>
