import {onMounted, reactive, toRefs} from "vue";
import companiesApiController from "../../api/companiesApiController";
import {activateOnboarding, noCompanies} from "@/router/helpers";
import {useAuth} from "../../store/auth";
import {USER_ROLES} from "@/constants/userRoles"

const useCompaniesList = () => {
	const state = reactive({
		loading: false,
		companies: null,
		error: false,
	});

	const authStore = useAuth();

	const fetchCompanies = async () => {
			state.loading = true;
			try {
				const response = await companiesApiController.getCompanies();

				if (!authStore.onboardingActive) {
					if (response.data.length === 0 && authStore.userRole === USER_ROLES.ADMIN) {
						authStore.onboardingActive = true;
						activateOnboarding()
					}
				}

				if (response.data.length === 0 && authStore.userRole !== USER_ROLES.ADMIN) {
					noCompanies();
				}

				state.companies = response.data;
			} catch
				(error) {
				state.error = error;
			}
			state.loading = false;
		}
	;

	const refetch = async () => {
		state.companies = null;
		fetchCompanies();
	};

	onMounted(() => {
		fetchCompanies();
	});

	return {
		...toRefs(state),
		refetch,
	};
};

export default useCompaniesList;
