<template>
	<template v-if="report">
		<ReportFormRow v-if="isEditing" :report="report" @submit="editReport" />
		<tr v-else>
			<TableCell>{{ report.id }}</TableCell>
			<TableCell>{{ responseDateToDisplay(report.reported_at) }}</TableCell>
			<TableCell>{{ capitalize(report.type) }}</TableCell>
			<TableCell>{{ report.company.name }}</TableCell>
			<TableCell>{{ report.account.name }}</TableCell>
			<TableCell>{{ report.amount }}</TableCell>
			<TableCell>{{ report.currency.name }}</TableCell>
			<TableCell>{{ report.payment_type.name }}</TableCell>
			<TableCell>{{ report.expense?.name ? report.expense.name : "---" }}</TableCell>
			<TableCell clamp popover>{{ report.description }}</TableCell>
			<EditTableCell @edit="editHandler" @delete="deleteHandler" />
		</tr>
	</template>
</template>

<script setup>
import { capitalize, responseDateToDisplay } from '@/helpers/strings';
import TableCell from '../../../components/TableCell.vue';
import EditTableCell from '../../../components/EditTableCell.vue';
import useConfirmation from '../../../../../store/confirmation';
import { ref } from 'vue';
import ReportFormRow from './ReportFormRow.vue';
import { useRouter } from 'vue-router';
import useWindowResize from '../../../../../hooks/useWindowResize';
import { USER_ROUTE_NAMES } from '../../../../../constants/route-names';
import useCurrentReport from '../../../hooks/useCurrentReport';

const { isMobile } = useWindowResize();
const router = useRouter();
const { setCurrentReport } = useCurrentReport();

const isEditing = ref(false);

const props = defineProps({
	report: {
		type: Object,
		required: true,
	},
});

const emit = defineEmits(['edit', 'delete']);

const confirm = useConfirmation();

const editHandler = () => {
	if (isMobile.value) {
		setCurrentReport(props.report)
		router.push({ name: USER_ROUTE_NAMES.EDIT_REPORT })
	} else {
		isEditing.value = true;
	}
};

const deleteHandler = () => {
	confirm.showConfirmation({
		title: 'Delete report?',
		onSubmit: () => {
			emit('delete', props.report.id);
		},
	});
};

const editReport = (data) => {
	emit('edit', {
		id: props.report.id,
		data,
	});
	isEditing.value = false;
};
</script>
