<template>
	<template v-if="currentFolder">
		<StorageNameModal
			title="Folder Name"
			placeholder="Type folder name..."
			:name="currentFolder.name"
			:open="editFolderNameModalOpen"
			:loading="isUpdatingFolderName"
			@close="closeEditFolderNameModal"
			@submit="updateFolderName"
		/>

		<StorageNameModal
			v-if="editingFile"
			title="File Name"
			placeholder="Type file name..."
			:name="editingFileName"
			:open="editingFileNameModalOpen"
			:loading="isFileEditing"
			@close="closeEditFileNameModal"
			@submit="updateFile"
		/>

		<RouterLink
			custom
			:to="{ name: USER_ROUTE_NAMES.CLOUD_STORAGE }"
			v-slot="{ href, navigate }"
		>
			<AppButton
				back
				text="BACK TO FOLDERS LIST"
				class="go-back"
				:href="href"
				@click="navigate"
			>
				<template v-slot:icon>
					<BackIcon />
				</template>
			</AppButton>
		</RouterLink>

		<div class="content-header">
			<h2>{{ currentFolder.name }}</h2>
			<button class="edit-btn" @click="editFolderNameModalOpen = true">
				<EditIcon />
			</button>
			<input ref="fileInputEl" type="file" @change="uploadFile" />
			<AppButton
				text="IMPORT FILE"
				outline
				medium
				:loading="isUploading"
				@click="handleUploadClick"
			>
				<template #icon>
					<PlusIcon />
				</template>
			</AppButton>
		</div>

		<div class="sort">
			Sort by:
			<select v-model="currentFilesSort">
				<option value="fn">From newest</option>
				<option value="fo">From oldest</option>
			</select>
		</div>

		<div class="files-container">
			<template v-if="processedFiles.length > 0">
				<VPreview
					v-for="file in processedFiles"
					:key="`file-${file.id}`"
					:title="file.name"
					class="preview--storage"
					show-delete
					show-edit
					@delete="handleFileDelete(file)"
					@edit="editFileName(file)"
				>
					<div class="box">
						<AppButton
							outline
							text="Download"
							:loading="downloadingFileId === file.id"
							@click="downloadFile(file)"
						>
							<template #icon>
								<ExportIcon />
							</template>
						</AppButton>
					</div>
				</VPreview>
			</template>
			<h2 v-if="processedFiles.length === 0 && searchText">No file found...</h2>
			<h2 v-if="processedFiles.length === 0 && !searchText">
				You didn't upload any files.
			</h2>
		</div>
	</template>
</template>

<script setup>
import VPreview from "@/components/preview/VPreview.vue";
import AppButton from "@/components/base/AppButton.vue";
import PlusIcon from "@/assets/icons/plus.svg";
import BackIcon from "@/assets/icons/back.svg";
import ExportIcon from "@/assets/icons/export.svg";
import EditIcon from "@/assets/icons/edit.svg";
import { useRoute } from "vue-router";
import { inject, ref } from "vue";
import { STORAGE_CONTROLLER_KEY } from "../../constants/injectionKeys";
import StorageNameModal from "./components/StorageNameModal.vue";
import useConfirmation from "../../../../store/confirmation";
import { USER_ROUTE_NAMES } from "../../../../constants/route-names";

const route = useRoute();
const { showConfirmation } = useConfirmation();

const folderId = route.params.id;

const {
	setCurrentFolder,
	currentFolder,
	editFolderNameModalOpen,
	closeEditFolderNameModal,
	updateFolderName,
	isUpdatingFolderName,
	isUploading,
	uploadFile,
	deleteFile,
	downloadFile,
	downloadingFileId,
	processedFiles,
	searchText,
	currentFilesSort,
	isFileEditing,
	editingFile,
	editingFileName,
	editingFileNameModalOpen,
	updateFile,
	closeEditFileNameModal,
	editFileName,
} = inject(STORAGE_CONTROLLER_KEY);

setCurrentFolder(folderId);

const fileInputEl = ref(null);

const handleUploadClick = () => {
	if (fileInputEl.value) {
		fileInputEl.value.click();
	}
};

const handleFileDelete = (file) => {
	showConfirmation({
		title: `Delete ${file.name}?`,
		onSubmit: () => deleteFile(file),
	});
};
</script>

<style scoped lang="scss">
.content-header {
	display: flex;
	align-items: center;
	margin-bottom: 34px;
	& > :not(:last-child) {
		margin-right: 16px;
	}
}
.sort {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #0c0c0c;
	margin-bottom: 34px;

	select {
		border: none;
		background: transparent;
		outline: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		padding-right: 24px;
		background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_131_32762)'%3E%3Cpath d='M15.6371 5.05275C15.5862 4.93202 15.4679 4.85352 15.3367 4.85352L0.982542 4.85366C0.851468 4.85366 0.733063 4.93216 0.681944 5.0529C0.63097 5.17363 0.657185 5.3133 0.748647 5.40738L7.92572 12.7891C7.98703 12.8522 8.0715 12.8879 8.15961 12.8879C8.24772 12.8879 8.33219 12.8522 8.3935 12.7891L15.5706 5.40701C15.662 5.31293 15.6881 5.1734 15.6371 5.05267V5.05275Z' fill='%232D5BE3'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_131_32762'%3E%3Crect width='16' height='16' fill='white' transform='translate(0.195312 0.871094)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
		background-position: right center;
		background-repeat: no-repeat;
		color: #0c0c0c;
		cursor: pointer;
		&::-ms-expand {
			display: none;
		}
	}
}
.go-back {
	margin-bottom: 34px;
}
.box {
	display: flex;
	align-items: center;

	& > :not(:last-child) {
		margin-right: 8px;
	}
	.count {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #0c0c0c;
	}
}

.files-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
	gap: 32px;
}

@media (max-width: 1200px) {
	.layout {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 600px) {
	.content-header {
		flex-wrap: wrap;
		row-gap: 20px;
	}

	.files-container {
		display: grid;
		grid-template-columns: 100%;
		gap: 32px;
	}
}
</style>
