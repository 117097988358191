<template>
	<PageLayout
		title="Employees"
		:count-text="employees && getEmployeesCount(employees.length)"
		:loading="companiesLoading"
	>
		<RouterLink custom :to="{ name: ADMIN_ROUTE_NAMES.EMLOYEES_LIST }" v-slot="{ href, navigate }">
			<Button
				back
				text="BACK TO EMLOYEES LIST"
				:href="href"
				@click="navigate"
				style="height:auto;margin-bottom: 34px;"
			>
				<template v-slot:icon>
					<BackIcon />
				</template>
			</Button>
		</RouterLink>
		<h2>Add a new employee</h2>

		<EmployeeSettingsForm
			v-if="companies"
			:companies="companies"
			:loading="isSaving"
			@submit="submitHandler"
		/>
	</PageLayout>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Button from "@/components/base/AppButton";
import BackIcon from "@/assets/icons/back.svg";
import PageLayout from '@/components/layouts/PageLayout.vue';
import { ADMIN_ROUTE_NAMES } from '@/constants/route-names';
import useCompaniesList from '@/hooks/companies/useCompaniesList';
import useCreateEmployee from '@/hooks/employees/useCreateEmployee';
import useNotifications from '@/store/notifications';
import EmployeeSettingsForm from '@/views/admin/employees/components/EmployeeSettingsForm.vue';
import useEmployeesList from "@/hooks/employees/useEmployeesList";
import { getEmployeesCount } from '@/helpers/strings';

const { employees } = useEmployeesList();

const { companies, loading: companiesLoading } = useCompaniesList();
const { loading: isSaving, createEmployee } = useCreateEmployee();
const notifications = useNotifications();
const router = useRouter();

const submitHandler = async (data) => {
	const response = await createEmployee(data);
	if (response.success) {
		notifications.showNotification({
			message: 'Emloyee created!',
		});
		await router.push({ name: ADMIN_ROUTE_NAMES.EMLOYEES_LIST });
	}
};
</script>
