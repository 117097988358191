import { onMounted, reactive, toRefs } from 'vue';
import usersApiController from '../../api/usersApiController';

const useEmployeesList = () => {
	const state = reactive({
		loading: false,
		employees: null,
		error: false,
	});

	const fetchEmployees = async () => {
		state.loading = true;
		try {
			const response = await usersApiController.getUsers();
			state.employees = response.data;
		} catch (error) {
			state.error = error;
		}
		state.loading = false;
	};

	const refetch = async () => {
		state.employees = null;
		fetchEmployees();
	};

	onMounted(() => {
		fetchEmployees();
	});

	return {
		...toRefs(state),
		refetch,
	};
};

export default useEmployeesList;
