/**
 * @param {Number[]} idsArray
 * @param {{id: Number}[]} data
 */
export const splitSelected = (idsArray, data) => {

	return data.reduce(
		(acc, item) => {
			if (idsArray.includes(item.id)) {
				acc.selected.push(item);
			} else {
				acc.notSelected.push(item);
			}
			return acc;
		},
		{ selected: [], notSelected: [] }
	);
};
