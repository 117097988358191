import {reactive, toRefs} from 'vue';
import currenciesApiController from '../../../../api/accounts/currenciesApiController';

const useDeleteCurrency = () => {
	const state = reactive({
		loading: false,
		error: false,
	});

	const deleteCurrency = async (id) => {
		state.loading = true;

		try {
			const response = await currenciesApiController.deleteCurrency(id);
			return response

		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	return {
		...toRefs(state),
		deleteCurrency,
	};
};

export default useDeleteCurrency;
