import { computed, ref } from "vue";
import useGetUserEmployees from "../../../hooks/employees/useGetUserEmployees";

const sortByName = (a, b) => {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
};

const useEmployeeFormSelect = (employeesActive) => {
	const { employees, loading: employeesLoading } = useGetUserEmployees({
		immidiate: employeesActive,
	});

	const searchTerm = ref("");

	const currentEmployees = computed(() => {
		if (employees.value) {
			const term = searchTerm.value.toLowerCase();

			const res = employees.value
				.filter((employee) => employee.name.toLowerCase().includes(term))
				.sort(sortByName);
			console.log(res);
			return res;
		}
		return null;
	});

	return {
		searchTerm,
		employees: currentEmployees,
		employeesLoading,
	};
};

export default useEmployeeFormSelect;
