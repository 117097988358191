<template>
	<PageLayout>
		<h1>Delete reports</h1>
		<p>In case if you want to delete reports in the system all employees, accounts and companies will be saved.</p>
		<h4>Do you want to delete all reports in the system or for a certain period of time?</h4>		
		<div  class="make-choose">
			<VCheckBox 
				type="radio"
				label="Delete all reports"
				name="choose"
				checked
				expand
				@click="router.push('/delete-reports');"
			/>
			<VCheckBox 
				type="radio"
				label="Delete reports for a time period"
				name="choose"
				expand
				@click="router.push('/delete-reports/period');"
			/>
		</div>
		<h3>Are you sure you want to delete all reports in the system?</h3>
		<div class="buttons">
			<RouterLink custom :to="{ name: ADMIN_ROUTE_NAMES.COMPANIES_LIST }" v-slot="{ href, navigate }">
				<Button small text="NO" :href="href" @click="navigate" />
			</RouterLink>
			<RouterLink custom :to="{ name: ADMIN_ROUTE_NAMES.REPORTS_DELETEPASSWORDS }" v-slot="{ href, navigate }">
				<Button small outline text="YES" :href="href" @click="navigate" />
			</RouterLink>
				
		</div>
	</PageLayout>
</template>

<script setup>
import PageLayout from '@/components/layouts/PageLayout.vue';
import Button from "@/components/base/AppButton";
import { ADMIN_ROUTE_NAMES } from '@/constants/route-names';
import VCheckBox from '@/components/form-components/VCheckBox.vue';
import { useRouter } from 'vue-router'


const router = useRouter()

</script>


<style scoped>
	h1 {
		margin-bottom: 16px;
	  font-size: 34px;
	  font-weight: 500;
	}
	p {
		margin-bottom: 54px;
		max-width: 470px;
	}
	h3 {
		margin-bottom: 24px;
	}
	h4 {
		margin-bottom: 24px;
		max-width: 320px;
	}
	.buttons > :not(:last-child) {
		margin-right: 30px;
	}
	.make-choose {
		max-width: 250px;
		margin-bottom: 54px;
	}
	.make-choose .checkbox {
		margin-bottom: 34px;
	}
</style>
