import { defineStore } from 'pinia';

const useNotifications = defineStore('notifications', {
	state: () => ({
		id: 0,
		active: [],
	}),
	actions: {
		showNotification(notification) {
			if (!notification) return;
			const currentId = this.id;
			this.active.push({ id: currentId, ...notification });

			setTimeout(() => this.removeNotification(currentId), 4000);

			this.id += 1;
		},
		removeNotification(id) {
			this.active = this.active.filter((notf) => notf.id !== id);
		},
	},
});

export default useNotifications;
