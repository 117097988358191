import { ref } from 'vue';
import usersApiController from '../../api/usersApiController';

const useUpdateEmployee = () => {
	const loading = ref(false);

	const updateEmployee = async (id, data) => {
		loading.value = true;

		const response = await usersApiController.updateUser(id, data);

		loading.value = false;
		return response;
	};

	return {
		loading,
		updateEmployee,
	};
};

export default useUpdateEmployee;
