<template>
	<StorageNameModal
		v-if="createFolderModalOpen"
		title="Folder Name"
		placeholder="Type folder name..."
		:open="createFolderModalOpen"
		:loading="isCreating"
		@close="hanldeCreateFolderModalClose"
		@submit="createFolder"
	/>
	<div class="content-header">
		<h2>My folders</h2>
		<AppButton
			text="ADD FOLDER"
			outline
			medium
			@click="createFolderModalOpen = true"
		>
			<template #icon>
				<PlusIcon />
			</template>
		</AppButton>
	</div>
	<div class="folders-container" v-if="processedFolders">
		<template v-if="processedFolders.length > 0">
			<VPreview
				v-for="folder in processedFolders"
				:key="`folder-${folder.id}`"
				:title="folder.name"
				class="preview--storage"
				show-delete
				@delete="handleDelete(folder)"
			>
				<div class="box">
					<RouterLink
						custom
						:to="{
							name: USER_ROUTE_NAMES.CLOUD_STORAGE_FOLDER,
							params: { id: folder.id },
						}"
						v-slot="{ href, navigate }"
					>
						<AppButton
							outline
							text="OPEN FOLDER"
							:href="href"
							@click="navigate"
						/>
					</RouterLink>
					<div class="count">{{ folder.files.length }} files</div>
				</div>
			</VPreview>
		</template>
		<h2 v-if="processedFolders.length === 0 && searchText">
			No folder found...
		</h2>
		<h2 v-if="processedFolders.length === 0 && !searchText">
			You didn't add any folder
		</h2>
	</div>
</template>

<script setup>
import VPreview from "@/components/preview/VPreview.vue";
import AppButton from "@/components/base/AppButton.vue";
import PlusIcon from "@/assets/icons/plus.svg";
import { inject } from "vue";
import { STORAGE_CONTROLLER_KEY } from "../../constants/injectionKeys";
import StorageNameModal from "./components/StorageNameModal.vue";
import useConfirmation from "../../../../store/confirmation";
import { USER_ROUTE_NAMES } from "../../../../constants/route-names";

const { showConfirmation } = useConfirmation();

const {
	searchText,
	processedFolders,
	deleteFolder,
	createFolderModalOpen,
	isCreating,
	hanldeCreateFolderModalClose,
	createFolder,
} = inject(STORAGE_CONTROLLER_KEY);

const handleDelete = (folder) => {
	showConfirmation({
		title: `Delete ${folder.name} folder?`,
		onSubmit: () => deleteFolder(folder.id),
	});
};
</script>

<style scoped lang="scss">
.content-header {
	display: flex;
	align-items: center;
	margin-bottom: 34px;
	& > :not(:last-child) {
		margin-right: 16px;
	}
}
.box {
	display: flex;
	align-items: center;

	& > :not(:last-child) {
		margin-right: 8px;
	}
	.count {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #0c0c0c;
	}
}

.folders-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
	gap: 32px;
}

@media (max-width: 600px) {
	.folders-container {
		display: grid;
		grid-template-columns: 100%;
		gap: 32px;
	}
}
</style>
