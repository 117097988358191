import companiesApiController from '../../api/companiesApiController';
import {onMounted, reactive, toRefs} from 'vue';

const useGetCompanyData = (companyId) => {

	const state = reactive({
		loading: false,
		companyData: null,
		error: null,
	});

	const fetchCompany = async (id = companyId) => {
		state.loading = true;

		try {
			const response = await companiesApiController.getCompany(id);
			state.companyData = response.data;
		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	const refetch = (id = companyId) => {
		state.companyData = null;
		fetchCompany(id);
	};

	onMounted(() => {
		if (companyId) {
			fetchCompany();
		}
	});

	return {
		...toRefs(state),
		refetch,
		fetchCompany,
	};
};

export default useGetCompanyData;
