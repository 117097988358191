import { defineStore } from "pinia";
import authApiController from "../api/authApiController";
import { unfreezeViewport } from "../helpers/viewport";
import router from "../router";
import { changeHomeRoute, deactivateOnboarding } from "../router/helpers";

export const useAuth = defineStore("auth", {
	state: () => ({
		isAuthenticated: false,
		isAuthenticating: false,

		token: localStorage.getItem("t") || null,

		profile: {},
		isProfileLoading: false,
		userRole: null,

		onboardingActive: false,
	}),

	actions: {
		async login(data) {
			this.isAuthenticating = true;
			const response = await authApiController.login(data);
			// console.log("data - ", data);
			// console.log("response - ", response);
			if (response.success) {
				this.token = response.data.access_token;
				localStorage.setItem("t", this.token);

				await this.getProfile();

				router.push({ name: "Home" });
			}

			this.isAuthenticating = false;
		},

		async getProfile() {
			this.isProfileLoading = true;
			const response = await authApiController.getUserProfile();

			if (response.data) {
				this.profile = response.data;
				this.userRole = response.data.roles[0];
				changeHomeRoute(this.userRole);

				this.isAuthenticated = true;
			}

			this.isProfileLoading = false;
		},

		async logout() {
			await authApiController.logout();
			if (this.onboardingActive) {
				deactivateOnboarding();
			}
			unfreezeViewport();
			this.resetAuthState();
		},

		resetAuthState() {
			this.isAuthenticated = false;
			this.token = null;
			localStorage.removeItem("t");
			this.profile = {};
			this.userRole = null;
			this.onboardingActive = false;

			changeHomeRoute(null);

			this.redirectToHome();
		},

		redirectToHome() {
			router.push({ name: "Home" });
		},
	},
});
