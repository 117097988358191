<template>
	<div v-if="auth.isAuthenticated" class="header">
		<div class="header__inner">

			<DesktopHeader v-if="!isMobile" />

			<MobileHeader v-if="isMobile" />

		</div>		
	</div>
</template>

<script setup>
import { useAuth } from '../../store/auth';
import DesktopHeader from './DesktopHeader.vue';
import MobileHeader from './MobileHeader.vue';
import useWindowResize from '@/hooks/useWindowResize.js';
import { computed } from 'vue';

// import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

const auth = useAuth();


defineEmits(['close','open']);


const { width } = useWindowResize();

const isMobile = computed(() => width.value < 1280)



</script>

<style lang="scss" scoped src="@/assets/scss/components/header.scss"></style>
