import { USER_ROLES } from "../constants/userRoles";
import router from "./index";
import step1Routes from "@/router/admin/onboarding/step1-routes";
import step2Routes from "@/router/admin/onboarding/step2-routes";

export const changeHomeRoute = (role) => {
	if (!role) {
		router.addRoute({ path: "/", name: "Home", redirect: "/login" });
	}

	if (role === USER_ROLES.ADMIN) {
		router.addRoute({ path: "/", name: "Home", redirect: "/companies" });
	}

	if (
		role === USER_ROLES.ACCOUNTANT ||
		role === USER_ROLES.MANAGER ||
		role === USER_ROLES.EMPLOYEE
	) {
		router.addRoute({ path: "/", name: "Home", redirect: "/my-reports" });
	}
};

export const activateOnboarding = () => {
	[...step1Routes, ...step2Routes].forEach((route) => {
		router.addRoute(route);
	});

	router.addRoute({
		path: "/",
		name: "Home",
		redirect: "/onboarding/step1/companies/new",
	});

	router.push("/");
};

export const deactivateOnboarding = (redirect = false) => {
	[...step1Routes, ...step2Routes].forEach((route) => {
		router.removeRoute(route.name);
	});

	router.addRoute({ path: "/", name: "Home", redirect: "/companies" });

	if (redirect) {
		router.push("/");
	}
};

export const noCompanies = () => {
	router.push("/no-companies");
};
