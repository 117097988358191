import { defineStore } from 'pinia';

const useConfirmation = defineStore('confirmation', {
	state: () => ({
		isActive: false,
		options: {},
	}),
	actions: {
		showConfirmation({
			title = '',
			text = '',
			hint = '',
			onSubmit = () => {},
			onReject = () => {},
		} = {}) {
			this.options = {
				title,
				text,
				hint,
				onSubmit,
				onReject,
			};

			this.isActive = true;
		},
		closeConfirmation() {
			this.isActive = false;
			this.options = {};
		},
	},
});

export default useConfirmation;
