/**
 *
 * @param { Number } status
 * @returns { Boolean }
 */
export const isUnauthenticatedRequest = (status) => {
	return status === 401;
};

export const isForbiddenRequest = (status) => {
	return status === 403;
};

export const isToManyRequests = (status) => {
	return status === 429;
};
