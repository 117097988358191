import {reactive, toRefs} from 'vue';
import accountsApiController from '../../../../api/accounts/accountsApiController';

const useDeleteEmployeeFromAccount = () => {
	const state = reactive({
		loading: false,
		error: false,
	});

	const deleteEmployeeFromAccount = async (id, user_id) => {
		state.loading = true;

		try {
			await accountsApiController.deleteUserFromAccount(id, user_id);
		} catch (error) {
			state.error = error;
		}

		state.loading = false;
	};

	return {
		...toRefs(state),
		deleteEmployeeFromAccount,
	};
};

export default useDeleteEmployeeFromAccount;
