<template>
	<PageLayout transparent-preloader :loading="isLoading">
		<RouterLink
			custom
			:to="{ name: USER_ROUTE_NAMES.USER_REPORTS }"
			v-slot="{ href, navigate }"
		>
			<AppButton
				back
				text="BACK TO REPORTS LIST"
				class="go-back"
				:href="href"
				@click="navigate"
			>
				<template v-slot:icon>
					<BackIcon />
				</template>
			</AppButton>
		</RouterLink>

		<h2 v-if="initialReport" class="report-title">
			Financial report № {{ initialReport.id }}
		</h2>

		<div class="add-report">
			<FormGroup stroke label="Date">
				<input type="date" v-model="formState.reported_at" />
			</FormGroup>

			<FormGroup stroke label="Expense/Income*">
				<select v-model="formState.type" class="form-group__select">
					<option value="expense" selected>Expense</option>
					<option value="income">Income</option>
					<option value="movement">Movement</option>
				</select>
			</FormGroup>

			<FormGroup stroke label="Company name*" :error="$v.company_id.$error">
				<template v-if="activeCompanies">
					<select
						class="form-group__select"
						v-if="activeCompanies.length > 1"
						v-model="formState.company_id"
					>
						<option :value="''" disabled>Select company</option>
						<option
							v-for="company in activeCompanies"
							:key="`company-${company.id}`"
							:value="company.id"
						>
							{{ company.name }}
						</option>
					</select>
					<div v-else>{{ activeCompanies[0].name }}</div>
				</template>
			</FormGroup>

			<FormGroup stroke label="Account" :error="$v.account_id.$error">
				<div v-if="!selectedCompany">Choose account</div>
				<select
					class="form-group__select"
					v-else-if="selectedCompany && activeAccounts.length > 1"
					v-model="formState.account_id"
				>
					<option :value="''" disabled>Select account</option>
					<option
						v-for="account in activeAccounts"
						:key="`account-${account.id}`"
						:value="account.id"
					>
						{{ account.name }}
					</option>
				</select>
				<div v-else>{{ activeAccounts[0].name }}</div>
			</FormGroup>

			<FormGroup stroke label="Amount*" :error="$v.amount.$error">
				<input type="number" placeholder="0" v-model="formState.amount" />
			</FormGroup>

			<FormGroup stroke label="Currency*" :error="$v.currency_id.$error">
				<div v-if="!selectedCompany">Choose company</div>
				<div v-else-if="!selectedAccount">Choose account</div>
				<select
					class="form-group__select"
					v-else-if="selectedAccount && activeCurrencies.length > 1"
					v-model="formState.currency_id"
				>
					<option :value="''" disabled>Select currency</option>
					<option
						v-for="currency in activeCurrencies"
						:key="`currency-${selectedAccount.id}-${currency.id}`"
						:value="currency.id"
					>
						{{ currency.name }}
					</option>
				</select>
				<div v-else>{{ activeCurrencies[0].name }}</div>
			</FormGroup>

			<FormGroup
				stroke
				label="Type of payment"
				:error="$v.payment_type_id.$error"
			>
				<div v-if="!selectedCompany">Choose company</div>
				<div v-else-if="!selectedAccount">Choose account</div>
				<select
					class="form-group__select"
					v-else-if="selectedAccount && activePaymentTypes.length > 1"
					v-model="formState.payment_type_id"
				>
					<option :value="''" disabled>Select type</option>
					<option
						v-for="paymentType in activePaymentTypes"
						:key="`currency-${selectedAccount.id}-${paymentType.id}`"
						:value="paymentType.id"
					>
						{{ paymentType.name }}
					</option>
				</select>
				<div v-else>{{ activePaymentTypes[0].name }}</div>
			</FormGroup>

			<FormGroup
				stroke
				label="Expenses type"
				:error="$v.expense_id.$error"
			>
				<div v-if="formState.type !== 'expense'">---</div>
				<div v-else-if="!selectedCompany">Choose company</div>
				<div v-else-if="!selectedAccount">Choose account</div>
				<select
					class="form-group__select"
					v-else-if="selectedAccount && activeExpenseTypes.length > 1"
					v-model="formState.expense_id"
				>
					<option :value="''">No type</option>
					<option
						v-for="expenseType in activeExpenseTypes"
						:key="`currency-${selectedAccount.id}-${expenseType.id}`"
						:value="expenseType.id"
					>
						{{ expenseType?.name }}
					</option>
				</select>
				<div v-else-if="activeExpenseTypes.length === 0">---</div>
				<div v-else>{{ activeExpenseTypes[0].name }}</div>
			</FormGroup>


			<FormGroup
				stroke
				label="Description"
				clamp
				:error="$v.description.$error"
				class="add-report__box"
			>
				<textarea
					v-model="formState.description"
					placeholder="Type your text here..."
					rows="2"
				></textarea>
			</FormGroup>

			<p class="add-report__prompt">*These fields are required</p>

			<FormGroup>
				<AppButton expand text="Add" @click="submitHandler" />
			</FormGroup>
		</div>
	</PageLayout>
</template>

<script setup>
import PageLayout from "../../../components/layouts/PageLayout.vue";
import useReportFormRow from "@/views/user/hooks/useReportForm";
import AppButton from "@/components/base/AppButton.vue";
import { USER_ROUTE_NAMES } from "@/constants/route-names";
import { useRouter } from "vue-router";
import useNotifications from "@/store/notifications";
import useCreateReport from "@/hooks/reports/useCreateReport";
import FormGroup from "@/components/form-components/FormGroup.vue";
import { computed, onUnmounted } from "vue";
import useCurrentReport from "../hooks/useCurrentReport";
import useEditReport from "../../../hooks/reports/useEditReport";
import BackIcon from "@/assets/icons/back.svg";

const { showNotification } = useNotifications();
const { createReport, loading: reportCreating } = useCreateReport();
const { editReport, loading: reportEditing } = useEditReport();
const { report: initialReport, removeCurrentReport } = useCurrentReport();
const router = useRouter();

const {
	companiesLoading,
	activeCompanies,
	activeAccounts,
	activeCurrencies,
	activePaymentTypes,
	activeExpenseTypes,
	formState,
	selectedCompany,
	selectedAccount,
	$v,
	getFormattedValues,
} = useReportFormRow(initialReport.value);

const isLoading = computed(() => {
	return companiesLoading.value || reportCreating.value || reportEditing.value;
});

const submitHandler = async () => {
	$v.value.$touch();
	if (!$v.value.$invalid) {
		const report = getFormattedValues();

		if (initialReport.value) {
			const response = await editReport(
				initialReport.value.id,
				getFormattedValues()
			);
			if (response.data.success) {
				router.push({ name: USER_ROUTE_NAMES.USER_REPORTS });
			}
		} else {
			const response = await createReport(report);

			if (response.data.success) {
				showNotification({
					message: "New financial report successfully added!",
				});
				router.push({ name: USER_ROUTE_NAMES.USER_REPORTS });
			}
		}
	}
};

onUnmounted(() => {
	removeCurrentReport();
});
</script>

<style scoped>
.go-back {
	margin-bottom: 32px;
}
.report-title {
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 22px;
}
.add-report {
	max-width: 600px;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}
.add-report__box {
	display: flex;
	flex-direction: column;
}
.add-report__box:not(:last-child) {
	padding-bottom: 14px;
}

.add-report__box textarea {
	background: transparent;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	border: none;
}

.add-report__prompt {
	margin-top: -16px;
	margin-bottom: 34px;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #f93b3b;
}
</style>
